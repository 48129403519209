<div class="sq-content">
  <h1>Fahrer importieren</h1>
  <mat-vertical-stepper>
    <mat-step label="Datei auswählen">
      Wählen Sie hier eine XLSX-Datei aus, um Fahrer zu importieren. Bitte stellen Sie sicher, dass die erste Zeile
      der Arbeitsmappe die Zeilenüberschriften enthält und dass pro Fahrer eine E-Mail-Adresse eingetragen ist.<br/><br/>
      <input type="file"
             id="file"
             #file
             (change)="onFilePicked($event)"
      />
      <br/><br/>
      <button mat-button color="primary" matStepperNext>Weiter</button>
    </mat-step>
    <mat-step label="Arbeitsmappe auswählen">
      <mat-select [(ngModel)]="selectedSheetName">
        <mat-option *ngFor="let sheetName of sheetNames" [value]="sheetName">
          {{sheetName}}
        </mat-option>
      </mat-select>
      <br/><br/>
      <button mat-button color="primary" matStepperNext>Weiter</button>
    </mat-step>
    <mat-step label="Zuordnung auswählen">
      <div class="table">
        <div class="row50percent">
          <div class="col th">
            Datenfeld in SquareTrip Fleet
          </div>
          <div class="col th">
            Spalte in Arbeitsmappe
          </div>
        </div>
        <div class="row50percent" *ngFor="let mappingConfig of mappingConfigs">
          <div class="col">{{mappingConfig.label | translate}}</div>
          <div class="col">
            <mat-select [(ngModel)]="mapping[mappingConfig.key]" placeholder="{{mappingConfig.label | translate}}">
              <mat-option *ngFor="let headlineCell of headlineCells" [value]="headlineCell.reference">
                {{headlineCell.label}}
              </mat-option>
            </mat-select>
          </div>
        </div>

      </div>

      <br/><br/>
      <button mat-button color="primary" (click)="generateUsers()" matStepperNext>Vorschau</button>
    </mat-step>
    <mat-step label="Vorschau">
      <div class="table">
        <div class="row50percent">
          <div class="col th">
            {{'driver.email_or_username' | translate}} / {{'driver.username' | translate}}
          </div>
          <div class="col th">
            {{'driver.first_name' | translate}}
          </div>
          <div class="col th">
            {{'driver.last_name' | translate}}
          </div>
          <div class="col th">
            {{'driver.role' | translate}}
          </div>
          <div class="col th">
            {{'driver.drivers_license_id' | translate}}
          </div>
          <div class="col th">
            {{'driver.password' | translate}}
          </div>
        </div>
        <div class="row50percent" *ngFor="let driver of users">
          <div class="col">
            {{driver.username}}
          </div>
          <div class="col">
            {{driver.firstName}}
          </div>
          <div class="col">
            {{driver.lastName}}
          </div>
          <div class="col">
            {{driver.clientRole}}
          </div>
          <div class="col">
            {{driver.driversLicenseNumber}}
          </div>
          <div class="col">
            **********
          </div>
        </div>
      </div>
      <button mat-button color="primary" (click)="saveUsers()" matStepperNext>Nutzer anlegen</button>
    </mat-step>
    <mat-step label="Fertig">
      <mat-spinner *ngIf="saving"></mat-spinner>
      <ng-container *ngIf="saved">
        Die Nutzer wurden erfolgreich angelegt.
      </ng-container>
    </mat-step>


  </mat-vertical-stepper>



</div>
