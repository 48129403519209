import {AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {DateAdapter, MatDialog, MatIconRegistry, MatSidenav} from '@angular/material';
import {LoginService, LoginState} from './services/login.service';
import {TranslateService} from '@ngx-translate/core';
import {ClientService} from './services/client.service';
import {WhiteLabelProperties} from './classes/WhiteLabelProperties';
import {Client, User} from 'squaretrip-ts-model';
import {OnboardingDialogComponent} from './components/onboarding/onboarding-dialog/onboarding-dialog.component';
import {IconHelper} from './helpers/icon-helper';
import {DomSanitizer} from '@angular/platform-browser';
import {SpinnerService} from './services/spinner.service';
import {ScrollService} from './services/scroll.service';
import {Observable} from 'rxjs/Observable';
import {switchMap, combineLatest} from 'rxjs/operators';
import 'rxjs-compat/add/observable/interval';
import 'rxjs-compat/add/operator/scan';
import 'rxjs-compat/add/operator/do';
import 'rxjs-compat/add/operator/takeWhile';
import {ConfigService, SquareTripProductType} from './services/config.service';

declare var _paq: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('content') content: ElementRef;
  loginState: LoginState = LoginState.NOT_LOGGED_IN;
  clientProperties: WhiteLabelProperties = null;
  client: Client = null;
  showSpinner = false;
  remainingTimeInTrialMonth = 0;
  isInTrialMonth = false;
  user: User = null;
  productType: SquareTripProductType = 'fleet';

  constructor(private loginService: LoginService,
              private router: Router,
              private translateService: TranslateService,
              private clientService: ClientService,
              private dialog: MatDialog,
              private iconRegistry: MatIconRegistry,
              private sanitizer: DomSanitizer,
              private spinnerService: SpinnerService,
              private dateAdapter: DateAdapter<Date>,
              private scrollService: ScrollService,
              private elementRef: ElementRef,
              private config: ConfigService,
              private userService: LoginService) {
    this.productType = this.config.getProductType();
    this.loginService.loginStateNoPersistence$.subscribe(state => this.loginState = state);
    this.translateService.setDefaultLang('de');
    this.translateService.use('de');
    this.dateAdapter.setLocale('de');
    IconHelper.registerIcons(iconRegistry, sanitizer);
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (window as any).gtag('event', 'page_view', {
          page: event.urlAfterRedirects,
          title: event['title']
        });
        _paq.push(['setCustomUrl', event.urlAfterRedirects]);
        _paq.push(['trackPageView']);
      }
    });
    this.spinnerService.showSpinner$.subscribe(() => {
      this.showSpinner = true;
    });
    this.spinnerService.hideSpinner$.subscribe(() => {
      this.showSpinner = false;
    });

    // smooth scrolling to top
    this.scrollService.scrollToTop$
      .pipe(
        switchMap(() => {
          const startPosition = this.content.nativeElement.scrollTop - this.content.nativeElement.offsetTop;
          return Observable.interval(10)
            .scan((acc, curr) => acc - 150, startPosition)
            .do(p => {
              this.content.nativeElement.scrollTo(0, p);
            })
            .takeWhile(currPos => currPos > 0);
        })
      ).subscribe();

    this.loginService.loginStateNoPersistence$.subscribe(loginState => {
      if (loginState === LoginState.LOGGED_IN) {
        setTimeout(() => this.handleOnboarding(), 0);
        this.userService.loadUser().subscribe(user => {
          this.user = user;
          if (user) {
            _paq.push(['setUserId', user.username]);
          }
        });
        this.clientService.getRemainingTimeInTrialMonth()
          .pipe(combineLatest(this.clientService.isInTrialMonth()))
          .subscribe((result) => {
            [this.remainingTimeInTrialMonth, this.isInTrialMonth] = result;
          });
        this.clientService.getClient().subscribe(client => {
          this.client = client;
          if (client && client.whiteLabelProperties) {
            this.clientProperties = client.whiteLabelProperties as WhiteLabelProperties;
            window.setTimeout(() => this.generateClientCss(), 0);
          }
        });
      } else if (loginState === LoginState.NOT_AUTHORIZED) {
        this.router.navigate(['/']);
      }
    });

  }

  private handleOnboarding() {
    if (!localStorage.getItem('has_seen_onboarding')) {
      this.dialog.open(OnboardingDialogComponent, {
        width: '1050px',
        height: '650px'
      });
    }
  }


  private generateClientCss() {
    const css = this.elementRef.nativeElement.querySelector('#stylediv').innerHTML;
    let style = this.elementRef.nativeElement.querySelector('#clientStyle');
    let newStyle = false;
    if (style == null) {
      style = document.createElement('style');
      newStyle = true;
    }
    style.id = 'clientStyle';
    style.innerHTML = css;
    if (newStyle) {
      this.elementRef.nativeElement.appendChild(style);
    }
  }

  showOnboarding() {
    this.dialog.open(OnboardingDialogComponent, {
      width: '1050px',
      height: '650px'
    });
  }

  logout() {
    this.loginService.logout().subscribe(() => this.router.navigate(['/']));
    this.sidenav.close();
  }

  toggleSidenav() {
    this.sidenav.toggle();
  }

  getRemainingTimeInTrialMonth(): Observable<number> {
    return this.clientService.getRemainingTimeInTrialMonth();
  }
}
