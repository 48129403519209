<div class="header mat-elevation-z2">
  <app-client-logo></app-client-logo>
  <div class="header-content">

    <div class="title">
      <h1>{{'drivers.title' | translate}}</h1>
      <p>{{'drivers.intro' | translate}}</p>
    </div>
    <div class="filters">
      <app-filter-bubble [icon]="'add'" [text]="'drivers.cta_new' | translate"
                         (click)="doShowNewDriverForm()"></app-filter-bubble>
      <app-filter-bubble [icon]="'cloud_upload'" [text]="'drivers.cta_import' | translate"
                         [routerLink]="['/drivers/import']"></app-filter-bubble>

    </div>
  </div>
</div>
<div class="subheader mat-elevation-z1" [@showEditForm]="showNewDriverForm ||showEditDriverForm">
  <h2>{{(showNewDriverForm ? 'drivers.cta_new' : '&nbsp;') | translate}}{{(showEditDriverForm ? 'drivers.cta_edit' : '')
    | translate}}</h2>
  <form (submit)="saveDriver()" #form="ngForm">

    <mat-form-field>
      <input
        #fn="ngModel"
        required
        matInput
        placeholder="{{'driver.first_name' | translate}}"
        name="first_name"
        [(ngModel)]="driver.firstName"/>
      <mat-error *ngIf="(fn.touched || fn.dirty) && fn.errors?.required">{{'drivers.form.errors.firstName' |
        translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <input
        #ln="ngModel"
        required
        matInput
        placeholder="{{'driver.last_name' | translate}}"
        name="last_name"
        [(ngModel)]="driver.lastName"/>
      <mat-error *ngIf="(ln.touched || ln.dirty) && ln.errors?.required">{{'drivers.form.errors.lastName' |
        translate}}
      </mat-error>
    </mat-form-field>

    <ng-container *ngIf="showNewDriverForm">
      <mat-form-field style="min-width: 400px">
        <mat-select placeholder="Wie möchten Sie den Fahrer anlegen?"
                    name="registrationMethod"
                    [(ngModel)]="registrationMethod">
          <mat-option value="email">
            Einladung per E-Mail versenden
          </mat-option>
          <mat-option [value]="'username-password'">
            Nutzernamen und Passwort für Fahrer festlegen
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container
      *ngIf="shouldShowEmailField()">
      <mat-form-field>
        <input
          #em="ngModel"
          required
          matInput
          placeholder="{{'driver.email' | translate}}"
          name="email"
          type="email"
          email
          appNoDuplicateEmail
          [disabled]="showEditDriverForm"
          [existingUsers]="allDrivers"
          [(ngModel)]="driver.email"/>
        <mat-error *ngIf="(em.touched || em.dirty) && em.errors?.required">{{'drivers.form.errors.email_required' |
          translate}}
        </mat-error>
        <mat-error *ngIf="(em.touched || em.dirty) && em.errors?.email">{{'drivers.form.errors.email' | translate}}
        </mat-error>
        <mat-error
          *ngIf="(em.touched || em.dirty) && em.errors?.noDuplicateEmail">{{'drivers.form.errors.duplicate_email'
          | translate}}
        </mat-error>

      </mat-form-field>
    </ng-container>

    <ng-container
      *ngIf="shouldShowUsernamePasswordFields()">
      <mat-form-field>
        <input
          #em="ngModel"
          required
          matInput
          placeholder="{{'driver.username' | translate}}"
          name="username"
          type="text"
          appNoDuplicateEmail
          [disabled]="showEditDriverForm"
          [existingUsers]="allDrivers"
          [(ngModel)]="driver.username"/>
        <mat-error *ngIf="(em.touched || em.dirty) && em.errors?.required">{{'drivers.form.errors.username_required' |
          translate}}
        </mat-error>
        <mat-error *ngIf="(em.touched || em.dirty) && em.errors?.email">{{'drivers.form.errors.username' | translate}}
        </mat-error>
        <mat-error
          *ngIf="(em.touched || em.dirty) && em.errors?.noDuplicateEmail">{{'drivers.form.errors.duplicate_email'
          | translate}}
        </mat-error>

      </mat-form-field>

      <mat-form-field *ngIf="showNewDriverForm">
        <input
          #em="ngModel"
          required
          matInput
          placeholder="{{'driver.password' | translate}}"
          name="password"
          type="password"
          [disabled]="showEditDriverForm"
          [(ngModel)]="driver.password"/>
        <mat-error *ngIf="(em.touched || em.dirty) && em.errors?.required">{{'drivers.form.errors.username_required' |
          translate}}
        </mat-error>

      </mat-form-field>
    </ng-container>

    <mat-form-field>
      <mat-select
        #role="ngModel"
        required
        placeholder="{{'driver.role' | translate}}" name="role" [(ngModel)]="driver.clientRole">
        <mat-option value="user">
          {{'driver.roles.driver' | translate}}
        </mat-option>
        <mat-option value="admin">
          {{'driver.roles.manager' | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="driver.clientRole === 'user'">
      <input matInput placeholder="{{'driver.drivers_license_id' | translate}}" name="driversLicenseNumber"
             [(ngModel)]="driver.driversLicenseNumber"/>
    </mat-form-field>
    <mat-form-field
      *ngIf="driver.clientRole === 'user' && whiteLabelProperties.enableShowDrivingLicense">
      <input matInput
             placeholder="{{'driver.next_due_date_show_drivers_license' | translate}}"
             name="showDrivingLicense" [(ngModel)]="nextShowDrivingLicense" [matDatepicker]="datePickerShow"/>
      <mat-datepicker-toggle matSuffix [for]="datePickerShow"></mat-datepicker-toggle>
      <mat-datepicker matSuffix #datePickerShow></mat-datepicker>
    </mat-form-field>
    <mat-form-field
      *ngIf="driver.clientRole === 'user' && whiteLabelProperties.enableConfirmDrivingLicense">
      <input matInput placeholder="{{'driver.next_due_date_confirm_drivers_license' | translate}}"
             name="confirmDrivingLicense" [(ngModel)]="nextConfirmDrivingLicense" [matDatepicker]="datePickerConfirm"/>
      <mat-datepicker-toggle matSuffix [for]="datePickerConfirm"></mat-datepicker-toggle>
      <mat-datepicker matSuffix #datePickerConfirm></mat-datepicker>
    </mat-form-field>
    <button mat-button type="submit" [disabled]="form.invalid">{{'action.save' | translate}}</button>
  </form>
</div>
<div class="dashboard-content sq-content">
  <mat-form-field class="search-box">
    <input type="text" matInput placeholder="Suche" [(ngModel)]="filterSearch"/>
  </mat-form-field>
  <mat-card *ngFor="let driver of filteredDrivers" [@justEdited]="driver?.username===driverJustEdited?.username">
    <mat-card-title>{{driver.firstName}} {{driver.lastName}}</mat-card-title>
    <mat-card-content>
      <div class="table">
        <div class="row50percent">
          <div class="col th">
            {{'driver.role' | translate}}
          </div>
          <div class="col right">
            {{driver.clientRole === 'admin' ? 'Manager' : 'Fahrer'}}
          </div>
        </div>
        <div class="row50percent" *ngIf="driver.driversLicenseNumber">
          <div class="col th">
            {{'driver.drivers_license_id' | translate}}

          </div>
          <div class="col right">
            {{driver.driversLicenseNumber}}
          </div>
        </div>
        <div class="row50percent" *ngIf="driver.clientRole === 'user' && getNextDueDateShowDriversLicense(driver) > 0">
          <div class="col th">
            {{'driver.next_due_date_show_drivers_license' | translate}}
          </div>
          <div class="col right">
            {{(getNextDueDateShowDriversLicense(driver)) | date:'shortDate'}}
          </div>
        </div>
        <div class="row50percent"
             *ngIf="driver.clientRole === 'user' && getNextDueDateConfirmDriversLicense(driver) > 0">
          <div class="col th">
            {{'driver.next_due_date_confirm_drivers_license' | translate}}
          </div>
          <div class="col right">
            {{(getNextDueDateConfirmDriversLicense(driver)) | date:'shortDate'}}

          </div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-icon-button (click)="deleteDriver(driver)">
        <mat-icon>delete</mat-icon>
      </button>
      <button mat-icon-button (click)="editDriver(driver)">
        <mat-icon>edit</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>
  <div class="empty-message" *ngIf="allDrivers.length <= 1">
    {{'drivers.empty-message' | translate}}
  </div>
</div>
