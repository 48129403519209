import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
  selector: '[minNumber]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MinNumberDirective,
      multi: true
    }
  ]
})
export class MinNumberDirective implements Validator {

  @Input() minNumber;

  validate(c: AbstractControl): ValidationErrors | null {
    if (+c.value <= this.minNumber) {
      return {
        'custom': true
      };
    }
  }

}
