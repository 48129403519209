import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';


import {AppComponent} from './app.component';
import {RouterModule} from '@angular/router';
import {routes} from './routes';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MatButtonModule,
  MatCardModule,
  MatCheckbox,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatStepperModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CarCardComponent} from './car-card/car-card.component';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { CostsOverviewFilterComponent } from './components/costs/costs-overview-filter/costs-overview-filter.component';
import { CostsPieChartByTypeComponent } from './components/costs/costs-pie-chart-by-type/costs-pie-chart-by-type.component';
import {DateFilterDialogComponent, DateFilterDialogService} from './components/date-filter/date-filter-dialog/date-filter-dialog.component';
import { PickerHandleDirective } from './directives/picker-handle.directive';
import { PickerDialogComponent } from './components/picker-dialog/picker-dialog.component';
import { CostsLineChartByTypeComponent } from './components/costs/costs-line-chart-by-type/costs-line-chart-by-type.component';
import {LoginService} from './services/login.service';
import {DriverService} from './services/driver.service';
import {AuthenticatedHttpService} from './services/authenticated-http.service';
import {CarService} from './services/car.service';
import {RegistrationService} from './services/registration.service';
import {CostsService} from './services/costs.service';
import {TripService} from './services/trip.service';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {CostsComponent} from './components/costs/overview/costs.component';
import {DriversComponent} from './components/drivers/overview/drivers.component';
import {AllCarsMapComponent} from './components/cars/all-cars-map/all-cars-map.component';
import {RegistrationComponent} from './components/authentication/registration/registration.component';
import {LoginComponent} from './components/authentication/login/login.component';
import {MapComponent} from './components/cars/map/map.component';
import {FilterBubbleComponent} from './components/filter-bubble/filter-bubble.component';
import { CarStatusIconComponent } from './components/cars/car-status-icon/car-status-icon.component';
import { DrivingLogOverviewComponent } from './components/driving-log/driving-log-overview/driving-log-overview.component';
import {ConfigService} from './services/config.service';
import { DrivingLogListComponent } from './components/driving-log/driving-log-list/driving-log-list.component';
import { DrivingLogDetailComponent } from './components/driving-log/driving-log-detail/driving-log-detail.component';
import {CarsComponent} from './components/cars/cars/cars.component';
import {OnlyNumberDirective} from './directives/only-number.directive';
import { FilterBarComponent } from './components/filter-bar/filter-bar.component';
import { EventOverviewComponent } from './components/events/event-overview/event-overview.component';
import {EventService} from './services/event.service';
import { EventListComponent } from './components/events/event-list/event-list.component';
import { CarEventConversationComponent } from './components/events/car-event-conversation/car-event-conversation.component';
import { ReportComponentComponent } from './components/report/report-component/report-component.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ReportService} from './services/report.service';
import {SnackBarService} from './services/snack-bar.service';
import { CostListComponent } from './components/costs/cost-list/cost-list.component';
import { CostDetailComponent } from './components/costs/cost-detail/cost-detail.component';
import { CarsMapsOverviewComponent } from './components/cars/cars-maps-overview/cars-maps-overview.component';
import { CarDetailedListComponent } from './components/cars/car-detailed-list/car-detailed-list.component';
import { NumberOfCarsInUseComponent } from './components/cars/number-of-cars-in-use/number-of-cars-in-use.component';
import { CostSumWidgetComponent } from './components/costs/cost-sum-widget/cost-sum-widget.component';
import { CarListWidgetComponent } from './components/cars/car-list-widget/car-list-widget.component';
import { CostPricePerLiterWidgetComponent } from './components/costs/cost-price-per-liter-widget/cost-price-per-liter-widget.component';
import { TripsInTimeWidgetComponent } from './components/driving-log/trips-in-time-widget/trips-in-time-widget.component';
import {ClientService} from './services/client.service';
import { ClientLogoComponent } from './client/client-logo/client-logo.component';
import { EditClientPropertiesComponent } from './components/client/edit-client-properties/edit-client-properties.component';
import {ColorPickerModule} from 'ngx-color-picker';
import { InitialPasswordComponent } from './components/user/initial-password/initial-password.component';
import { CarImportComponent } from './components/cars/car-import/car-import.component';
import {RequestPasswordResetComponent} from './components/authentication/request-password-reset/request-password-reset.component';
import {ResetPasswordComponent} from './components/authentication/reset-password/reset-password.component';
import { CarsWithEventsListComponent } from './components/events/cars-with-events-list/cars-with-events-list.component';
import { CarCommentsOverviewComponent } from './components/events/car-comments-overview/car-comments-overview';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { OnboardingComponent } from './components/onboarding/onboarding/onboarding.component';
import { OnboardingDialogComponent } from './components/onboarding/onboarding-dialog/onboarding-dialog.component';
import {SpinnerService} from './services/spinner.service';
import {ScrollService} from './services/scroll.service';
import { DriverImportComponent } from './components/drivers/driver-import/driver-import.component';
import { NoDuplicateEmailDirective } from './directives/drivers/no-duplicate-email.directive';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { InfoComponent } from './info/info.component';
import { PricesPackagesComponent } from './components/purchasing/prices-packages/prices-packages.component';
import {TrialMonthGuard} from './guards/trial-month.guard';
import {LoginGuard} from './guards/login.guard';
import {NoLoginGuard} from './guards/no-login.guard';
import { BuyComponent } from './components/purchasing/buy/buy.component';
import { HandbookComponent } from './components/handbook/handbook.component';
import { PickDriverDialogComponent } from './components/drivers/pick-driver-dialog/pick-driver-dialog.component';
import {PickCarDialogComponent} from './components/cars/pick-car-dialog/pick-car-dialog.component';
import { GenericDialogComponent } from './components/dialog/generic-dialog/generic-dialog.component';
import {DialogService} from './services/dialog.service';
import {TripHelper} from './helpers/trip/trip-helper';
import { BusinessPurposePickerDialogComponent } from './components/purpose/business-purpose-picker-dialog/business-purpose-picker-dialog.component';
import { ConfirmDialogComponent } from './components/dialog/confirm-dialog/confirm-dialog.component';
import {AddressPickerComponent} from './components/driving-log/address-picker/address-picker.component';
import { EditTripDialogComponent } from './components/driving-log/edit-trip-dialog/edit-trip-dialog.component';
import {ManualTripDialogComponent} from './components/driving-log/manual-trip-dialog/manual-trip-dialog.component';
import {MinNumberDirective} from './directives/min-number.directive';
import {EditCostDialogComponent} from './components/costs/edit-cost-dialog/edit-cost-dialog.component';
import { ConfirmAccountComponent } from './components/authentication/confirm-account/confirm-account.component';
import { DebugTripComponent } from './components/debug/trip/debug-trip/debug-trip.component';
import { MultiDebugComponent } from './components/debug/multi-debug/multi-debug.component';
import { PurposePickerComponent } from './components/driving-log/purpose-picker/purpose-picker.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localeDe, 'de');

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    FilterBubbleComponent,
    LoginComponent,
    DriversComponent,
    CarsComponent,
    RegistrationComponent,
    MapComponent,
    CarCardComponent,
    AllCarsMapComponent,
    CostsComponent,
    CostsOverviewFilterComponent,
    CostsPieChartByTypeComponent,
    DateFilterDialogComponent,
    PickerHandleDirective,
    PickerDialogComponent,
    CostsLineChartByTypeComponent,
    CarStatusIconComponent,
    DrivingLogOverviewComponent,
    DrivingLogListComponent,
    DrivingLogDetailComponent,
    OnlyNumberDirective,
    FilterBarComponent,
    EventOverviewComponent,
    EventListComponent,
    CarEventConversationComponent,
    ReportComponentComponent,
    CostListComponent,
    CostDetailComponent,
    CarsMapsOverviewComponent,
    CarDetailedListComponent,
    NumberOfCarsInUseComponent,
    CostSumWidgetComponent,
    CarListWidgetComponent,
    CostPricePerLiterWidgetComponent,
    TripsInTimeWidgetComponent,
    ClientLogoComponent,
    EditClientPropertiesComponent,
    InitialPasswordComponent,
    CarImportComponent,
    RequestPasswordResetComponent,
    ResetPasswordComponent,
    CarsWithEventsListComponent,
    CarCommentsOverviewComponent,
    SafeHtmlPipe,
    OnboardingComponent,
    OnboardingDialogComponent,
    DriverImportComponent,
    NoDuplicateEmailDirective,
    InfoComponent,
    PricesPackagesComponent,
    BuyComponent,
    HandbookComponent,
    PickDriverDialogComponent,
    PickCarDialogComponent,
    GenericDialogComponent,
    BusinessPurposePickerDialogComponent,
    ConfirmDialogComponent,
    AddressPickerComponent,
    EditTripDialogComponent,
    ManualTripDialogComponent,
    EditCostDialogComponent,
    MinNumberDirective,
    ConfirmAccountComponent,
    DebugTripComponent,
    MultiDebugComponent,
    PurposePickerComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    HttpClientModule,
    FormsModule,
    MatInputModule,
    MatSidenavModule,
    MatListModule,
    MatSelectModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSnackBarModule,
    MatDialogModule,
    MatMenuModule,
    MatMomentDateModule,
    MatTooltipModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ColorPickerModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatStepperModule
  ],
  providers: [LoginService,
    DriverService,
    AuthenticatedHttpService,
    CarService,
    RegistrationService,
    CostsService,
    {provide: LOCALE_ID, useValue: 'de'},
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    DateFilterDialogService,
    TripService,
    ConfigService,
    EventService,
    ReportService,
    SnackBarService,
    SpinnerService,
    ScrollService,
    DialogService,
    TrialMonthGuard,
    LoginGuard,
    NoLoginGuard,
    TripHelper,
    ClientService],
  bootstrap: [AppComponent],
  entryComponents: [
    DateFilterDialogComponent,
    CarEventConversationComponent,
    OnboardingDialogComponent,
    PickDriverDialogComponent,
    PickCarDialogComponent,
    GenericDialogComponent,
    ConfirmDialogComponent,
    BusinessPurposePickerDialogComponent,
    EditTripDialogComponent,
    ManualTripDialogComponent,
    EditCostDialogComponent,
  ]
})
export class AppModule {
}
