<h2 mat-dialog-title>{{'cost.add.title' | translate}}</h2>
<form #form="ngForm">
  <mat-dialog-content>
    <mat-form-field>
      <mat-icon matPrefix>directions_car</mat-icon>
      <mat-select [placeholder]="'car.title' | translate"
                  [(ngModel)]="cost.profile"
                  name="profile"
                  #profileSelect="ngModel"
                  [compareWith]="compareProfiles"
                  required>
        <mat-option *ngFor="let car of cars" [value]="car">
          {{ car.licensePlate }} ({{car.name}})
        </mat-option>
      </mat-select>
      <mat-error *ngIf="shouldShowErrorForField(profileSelect)">{{ 'errors.required' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-icon matPrefix>calendar_today</mat-icon>
      <input matInput [matDatepicker]="datePicker"
             [placeholder]="'cost.label_date' | translate"
             [(ngModel)]="date"
             required
             #dateField="ngModel"
             name="date"/>
      <mat-datepicker #datePicker></mat-datepicker>
      <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
      <mat-error *ngIf="shouldShowErrorForField(dateField)">{{ 'errors.required' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-icon matPrefix>category</mat-icon>
      <mat-select [placeholder]="'cost.add.label_cost_type' | translate"
                  [(ngModel)]="cost.type"
                  #costTypeField="ngModel"
                  required
                  name="costType">
        <mat-option *ngFor="let costType of costTypes" [value]="costType">
          {{ 'cost.type.' + costType | translate }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="shouldShowErrorForField(costTypeField)">{{ 'errors.required' | translate }}</mat-error>
    </mat-form-field>

    <ng-container *ngIf="cost.type === CostType.GAS">
      <div class="row50percent">
        <div>
          <mat-form-field>
            <mat-icon matPrefix>local_gas_station</mat-icon>
            <mat-select [placeholder]="'cost.add.label_gas_type' | translate"
                        [(ngModel)]="cost.gasType"
                        name="gasType">
              <mat-option *ngFor="let gasType of gasTypes" [value]="gasType">
                {{ 'cost.gasType.' + gasType | translate }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="shouldShowErrorForField(costTypeField)">{{ 'errors.required' | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-icon matPrefix>invert_colors</mat-icon>
            <input type="number" matInput
                   [(ngModel)]="cost.gasLiters"
                   [placeholder]="'cost.liter' | translate"
                   name="liters"/>
          </mat-form-field>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="cost.type === CostType.REPAIR">
      <mat-form-field>
        <mat-icon matPrefix>build</mat-icon>
        <mat-select [placeholder]="'cost.add.label_repair_type' | translate"
                    [(ngModel)]="cost.repairType"
                    name="repairType">
          <mat-option *ngFor="let repairType of repairTypes" [value]="repairType">
            {{ 'cost.repairType.' + repairType | translate }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="shouldShowErrorForField(costTypeField)">{{ 'errors.required' | translate }}</mat-error>
      </mat-form-field>
    </ng-container>


    <div class="row50percent">
      <div>
        <mat-form-field>
          <mat-icon matPrefix>store</mat-icon>
          <input matInput type="text"
                 required
                 #providerNameField="ngModel"
                 [(ngModel)]="cost.providerName"
                 [placeholder]="'cost.provider' | translate"
                 name="providerName"/>
          <mat-error *ngIf="shouldShowErrorForField(providerNameField)">{{ 'errors.required' | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <mat-icon matPrefix>location_on</mat-icon>
          <input matInput type="text"
                 [(ngModel)]="cost.providerAddress"
                 name="providerAddress"
                 #providerAddressField="ngModel"
                 [placeholder]="'cost.providerAddress' | translate"/>
          <mat-error *ngIf="shouldShowErrorForField(providerAddressField)">{{ 'errors.required' | translate }}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row50percent">
      <div>
        <mat-form-field>
          <mat-icon matPrefix>euro_symbol</mat-icon>
          <input matInput type="number"
                 minNumber="0.01"
                 [(ngModel)]="cost.sumGross"
                 name="sumGross"
                 required
                 #sumGrossField="ngModel"
                 [placeholder]="'cost.invoice_sum_gross' | translate"/>
          <mat-error *ngIf="shouldShowErrorForField(sumGrossField)">{{ 'errors.required' | translate }}</mat-error>
        </mat-form-field>

      </div>
      <div>
        <mat-form-field>
          <mat-icon matPrefix>euro_symbol</mat-icon>
          <input matInput type="number"
                 [(ngModel)]="cost.vatRate"
                 required
                 name="vatRate"
                 #vatRateField="ngModel"
                 [placeholder]="'cost.label_vat' | translate"/>
          <mat-error *ngIf="shouldShowErrorForField(vatRateField)">{{ 'errors.required' | translate }}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <mat-form-field>
      <mat-icon matPrefix>comment</mat-icon>
      <input matInput
             name="comment"
             [(ngModel)]="cost.comment" type="text"
             [placeholder]="'cost.label_comment' | translate"/>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button (click)="save()">{{ 'action.save' | translate}}</button>
  </mat-dialog-actions>
</form>
