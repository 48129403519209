import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {CostsService} from '../../../services/costs.service';
import {CarService} from '../../../services/car.service';
import {DriverService} from '../../../services/driver.service';
import {Cost, CostType, Profile, User} from 'squaretrip-ts-model';
import {TripService} from '../../../services/trip.service';
import {SpinnerService} from '../../../services/spinner.service';
import {map} from 'rxjs/operators';
import {EditCostDialogComponent} from '../edit-cost-dialog/edit-cost-dialog.component';
import {ConfigService} from '../../../services/config.service';
import {LoginService} from '../../../services/login.service';

@Component({
  selector: 'app-costs',
  templateUrl: './costs.component.html',
  styleUrls: ['./costs.component.scss']
})
export class CostsComponent implements OnInit {
  allCosts: Cost[] = [];
  costs: Cost[] = [];
  costTypesSelected: CostType[] = [];
  sum = 0;
  costPerLiter = 0;
  costPer100Km = 0;
  activeChart = 'pie';
  selectedCostForDetailView: Cost | null = null;

  private initialLoad = true;

  availableCars: Profile[] = [];
  availableDrivers: User[] = [];
  filterCars: Profile[] = [];
  filterDrivers: User[] = [];
  filterDateStart: Date = new Date(+new Date() - 31 * 24 * 60 * 60 * 1000);
  filterDateEnd: Date = new Date();
  productType: string;

  constructor(private costsService: CostsService,
              private configService: ConfigService,
              private carService: CarService,
              private driverService: DriverService,
              private spinnerService: SpinnerService,
              private dialog: MatDialog,
              private tripService: TripService,
              private loginService: LoginService) {
    this.productType = this.configService.getProductType();
    this.carService.getCars().subscribe(cars => this.availableCars = cars);
    this.driverService.getDrivers().subscribe(drivers => this.availableDrivers = drivers);
  }

  ngOnInit() {
    this.spinnerService.showSpinner();

    this.loadCosts();
  }

  loadCosts() {
    this.costsService.getCosts(true)
      .pipe(
        map(costs => {
          costs.sort((c1, c2) => c1.time > c2.time ? -1 : 1);
          return costs;
        }),
      ).subscribe(costs => {
      this.allCosts = costs;
      if (this.initialLoad) {
        this.selectAlCostTypesOf(costs);
        if (this.allCosts.length > 0) {
          this.initialLoad = false;
        }
      }
      this.filter();
      this.spinnerService.hideSpinner();

    });
  }

  onCostsTypesSelected(costTypes: CostType[]) {
    this.costTypesSelected = costTypes;
    this.calculateSums();
  }

  calculateSums() {
    this.sum = this.costs.filter(c => this.costTypesSelected.indexOf(c.type) > -1).reduce((pv, cv) => pv + cv.sumGross, 0);
    const costEntriesForGas = this.costs.filter(c => c.type === CostType.GAS);
    const liters = costEntriesForGas.reduce((pv, cv) => pv + cv.gasLiters, 0);
    const costForGas = costEntriesForGas.reduce((pv, cv) => pv + cv.sumGross, 0);
    this.costPerLiter = liters > 0 ? Math.round(costForGas / liters * 100) / 100 : -1;
    this.tripService.loadTrips(+this.filterDateStart, +this.filterDateEnd).subscribe(trips => {
      const distance = trips
        .filter(trip => trip.profile != null && trip.profile.id != null)
        .filter(trip => this.filterCars.findIndex(c => c.id === trip.profile.id) > -1)
        .filter(trip => this.filterDrivers.findIndex(d => d.username === trip.username) != null)
        .reduce((pv, cv) => pv + cv.distance, 0);
      this.costPer100Km = distance > 0 ? Math.round(this.sum / (distance / 1000 / 100) * 100) / 100 : -1;
    });
  }

  private selectAlCostTypesOf(costs: Cost[]) {
    const newCostTypesSelected = [];
    costs.forEach(cost => {
      if (newCostTypesSelected.indexOf(cost.type) === -1) {
        newCostTypesSelected.push(cost.type);
      }
    });
    this.costTypesSelected = newCostTypesSelected;
  }


  switchChart(chartType: string) {
    this.activeChart = chartType;
  }

  private filter() {

    this.costs = this.allCosts.filter(cost => {
      return cost.time > +this.filterDateStart && cost.time < (+this.filterDateEnd + 24 * 60 * 60 * 1000);
    });


    if (this.filterCars.length > 0) {
      this.costs = this.costs.filter(cost => {
        if (cost.profile) {
          return this.filterCars.findIndex(filter => cost.profile && filter.id === cost.profile.id) > -1;
        } else {
          return true;
        }
      });
    }

    this.costs = this.costs.filter(cost => {
      return this.filterDrivers.findIndex(filter => filter.username === cost.username) > -1;
    });


    this.selectAlCostTypesOf(this.costs);
    this.calculateSums();
  }

  onCarsPickedForFilter(cars: Profile[]) {
    this.filterCars = cars;
    this.filter();
  }

  onDriversPickedForFilter(drivers: User[]) {
    this.filterDrivers = drivers;
    this.filter();
  }

  onStartDatePickedForFilter(startDate: Date) {
    this.filterDateStart = startDate;
    this.filter();
  }

  onEndDatePickedForFilter(endDate: Date) {
    this.filterDateEnd = endDate;
    this.filter();
  }

  onCostSelectedForDetailView(cost: Cost) {
    this.selectedCostForDetailView = cost;
  }

  openAddCostDialog() {
    this.dialog.open(EditCostDialogComponent).afterClosed().subscribe(() => this.loadCosts());
  }

  openReport() {
    const fromUrl = encodeURIComponent(`https://api.squaretrip.net/cost?token=${this.loginService.token}`);
    location.href = `https://generic-report-generator.api.squaretrip.net/?fromUrl=${fromUrl}&&format=xlsx&propertyToColumnMapping={".profile.name":"Fahrzeug", ".sumGross":"Brutto-Betrag", "__number(.vatRate)": "USt in Prozent", ".providerName":"Anbieter", ".providerAddress": "Adresse", ".type": "Kostenart", "__date(.time)": "Datum"}`;
  }

}
