import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {justEditedAnimation, showEditFormAnimation} from '../../../animations/just-edited';
import {DriverService} from '../../../services/driver.service';
import {LoginService} from '../../../services/login.service';

import {EventService} from '../../../services/event.service';
import {EventAction, EventCategory, EventStatus, EventType, User, Event} from 'squaretrip-ts-model';
import {ClientService} from '../../../services/client.service';
import {Subscription} from 'rxjs/Subscription';
import {WhiteLabelProperties} from '../../../classes/WhiteLabelProperties';

import {ScrollService} from '../../../services/scroll.service';
import {NgForm} from '@angular/forms';
import {SnackBarService} from '../../../services/snack-bar.service';
import {ActivatedRoute, Params} from '@angular/router';
import {first, map} from 'rxjs/operators';


@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.scss'],
  animations: [
    justEditedAnimation,
    showEditFormAnimation
  ]
})
export class DriversComponent implements OnInit, OnDestroy {
  @ViewChild('form') form: NgForm;
  driverJustEdited = null;
  allDrivers: User[];
  filteredDrivers: User[];
  showNewDriverForm = false;
  showEditDriverForm = false;
  driver = new User();
  password = '';
  _filterSearch = '';
  nextShowDrivingLicense = new Date();
  nextConfirmDrivingLicense = new Date();
  subscriptions = new Subscription();
  whiteLabelProperties: WhiteLabelProperties = null;
  /**
   * Cache to store due dates to show car's license
   * @type {Map<string, number>}
   */
  dueDatesShow = new Map<string, number>();
  /**
   * Cache to store due dates to confirm car's license
   * @type {Map<string, number>}
   */
  dueDatesConfirm = new Map<string, number>();
  registrationMethod: 'email' | 'username-password' = 'email';

  set filterSearch(value) {
    this._filterSearch = value;
    this.filter();
  }

  get filterSearch() {
    return this._filterSearch;
  }

  constructor(private driverService: DriverService,
              private loginService: LoginService,
              private eventService: EventService,
              private clientService: ClientService,
              private snackbarService: SnackBarService,
              private route: ActivatedRoute,
              private scrollService: ScrollService) {
  }

  ngOnInit() {
    this.loadDrivers();
    this.subscriptions.add(this.clientService
      .getClient()
      .subscribe(client => {
        if (client == null) {
          return;
        }
        this.whiteLabelProperties = client.whiteLabelProperties as WhiteLabelProperties;
      })
    );
    this.subscriptions.add(this.route.queryParamMap.subscribe(params => {
      if (params.has('filterByDriver')) {
        this.filterSearch = params.get('filterByDriver');
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  doShowNewDriverForm() {
    if (this.showNewDriverForm || this.showEditDriverForm) {
      this.showNewDriverForm = false;
      this.showEditDriverForm = false;
      this.driver = new User();
    } else {
      this.showNewDriverForm = true;
      // reset validation when new form is opened so error messages from previous form are not displayed
      this.form.form.markAsPristine();
      this.form.form.markAsUntouched();
      this.form.form.updateValueAndValidity();
    }
  }

  loadDrivers() {
    this.subscriptions.add(this.driverService.getDrivers(true).subscribe(drivers => {
      this.allDrivers = drivers;
      this.filter();
    }));
  }

  private saveNewShowDrivingLicenseEvent(username) {
    const showDrivingLicenseEvent = new Event();
    showDrivingLicenseEvent.fromUsername = '';
    showDrivingLicenseEvent.toUsername = username;
    showDrivingLicenseEvent.lastChanged = +new Date();
    showDrivingLicenseEvent.dueTime = +new Date(this.nextShowDrivingLicense);
    showDrivingLicenseEvent.action = EventAction.ShowDrivingLicense;
    showDrivingLicenseEvent.status = EventStatus.Open;
    showDrivingLicenseEvent.category = EventCategory.Driver;
    showDrivingLicenseEvent.type = EventType.Task;
    showDrivingLicenseEvent.id = null;
    showDrivingLicenseEvent.title = 'Führerscheinprüfung (persönliche Vorlage)';
    return this.eventService.saveEvent(showDrivingLicenseEvent);
  }

  private saveNewConfirmDrivingLicenseEvent(username) {
    const confirmDrivingLicenseEvent = new Event();
    confirmDrivingLicenseEvent.fromUsername = '';
    confirmDrivingLicenseEvent.toUsername = username;
    confirmDrivingLicenseEvent.lastChanged = +new Date();
    confirmDrivingLicenseEvent.dueTime = +new Date(this.nextConfirmDrivingLicense);
    confirmDrivingLicenseEvent.action = EventAction.ConfirmDrivingLicense;
    confirmDrivingLicenseEvent.status = EventStatus.Open;
    confirmDrivingLicenseEvent.category = EventCategory.Driver;
    confirmDrivingLicenseEvent.type = EventType.Task;
    confirmDrivingLicenseEvent.id = null;
    confirmDrivingLicenseEvent.title = 'Führerscheinprüfung (in App)';
    return this.eventService.saveEvent(confirmDrivingLicenseEvent);
  }

  saveDriver() {
    const newDriverUsername = this.driver.email;
    const driver = {...this.driver};
    if (this.registrationMethod === 'email') {
      driver.username = this.driver.email;
    }
    if (this.showNewDriverForm) {
      if (this.registrationMethod === 'email') {
        delete driver.password;
      }
      this.driverService.addDriver(driver).subscribe(() => {

        // we use a promise here to make sure that the saveEvent calls are sent one after the other
        new Promise((resolve, reject) => {
          if (this.whiteLabelProperties.enableConfirmDrivingLicense) {
            this.saveNewConfirmDrivingLicenseEvent(newDriverUsername).subscribe(() => resolve());
          } else {
            resolve();
          }
        }).then(() => {
          if (this.whiteLabelProperties.enableShowDrivingLicense) {
            this.saveNewShowDrivingLicenseEvent(newDriverUsername).subscribe();
          }
        });

        this.loadDrivers();
        this.snackbarService.showMessage('drivers.saved');
      }, (err) => {
        if (err.status === 409) {
          this.snackbarService.showMessage('drivers.errorConflict');
        }
      });
    } else {
      new Promise((resolve, reject) => {
        if (this.whiteLabelProperties.enableShowDrivingLicense) {

          // get current event to show driving license and adapt it
          this.eventService.getEventsAssignedToUserWithAction(driver, EventAction.ShowDrivingLicense)
            .pipe(first())
            .subscribe(events => {
              if (events.length > 0) {
                const event = events[0];
                event.dueTime = +new Date(this.nextShowDrivingLicense);
                event.lastChanged = +new Date();
                this.eventService.saveEvent(event).subscribe(() => resolve());
              } else {
                this.saveNewShowDrivingLicenseEvent(newDriverUsername).subscribe(() => resolve());
              }
            });
        } else {
          resolve();
        }
      }).then(() => {
        if (this.whiteLabelProperties.enableConfirmDrivingLicense) {
          // get current event to confirm driving license and adapt it
          this.eventService.getEventsAssignedToUserWithAction(driver, EventAction.ConfirmDrivingLicense)
            .pipe(first())
            .subscribe(events => {
              if (events.length > 0) {
                const event = events[0];
                event.dueTime = +new Date(this.nextConfirmDrivingLicense);
                event.lastChanged = +new Date();
                this.eventService.saveEvent(event).subscribe(() => this.loadDrivers());
              } else {
                this.saveNewConfirmDrivingLicenseEvent(newDriverUsername).subscribe(() => this.loadDrivers());
              }
            });
        } else {
          this.loadDrivers();
        }
      });

      delete this.driver.password;
      this.driverService.putDriver(this.driver).subscribe(() => this.loadDrivers());

      this.driverJustEdited = this.driver;
      setTimeout(() => this.driverJustEdited = null, 500);
      this.driver = new User();
      this.doShowNewDriverForm();
    }
  }

  editDriver(driver: User) {
    this.showEditDriverForm = true;
    this.showNewDriverForm = false;
    this.nextShowDrivingLicense =
      new Date(this.getNextDueDateShowDriversLicense(driver) > -1 ? this.getNextDueDateShowDriversLicense(driver) : +new Date());
    this.nextConfirmDrivingLicense =
      new Date(this.getNextDueDateConfirmDriversLicense(driver) > -1 ? this.getNextDueDateConfirmDriversLicense(driver) : +new Date()
      );
    this.driver = driver;
    this.scrollService.scrollToTop();
  }

  shouldShowEmailField() {
    if (this.showNewDriverForm) {
      return this.registrationMethod === 'email';
    }
    return this.driver.email !== '' && this.driver.email != null;
  }


  shouldShowUsernamePasswordFields() {
    return !this.shouldShowEmailField();
  }

  deleteDriver(driver: User) {
    if (confirm(`Möchten Sie den Fahrer ${driver.firstName} ${driver.lastName} sicher löschen?`)) {
      this.driverService.deleteDriver(driver).subscribe(() => this.loadDrivers());
    }
  }

  filter() {
    if (this.filterSearch == null || this.filterSearch === '') {
      this.filteredDrivers = this.allDrivers;
    }

    this.filteredDrivers = this.allDrivers.filter(driver => (driver.firstName.toLowerCase() + ' '
      + driver.lastName.toLowerCase()
      + driver.username.toLowerCase()).indexOf(this.filterSearch.toLowerCase()) > -1
    );
  }

  /**
   * Tries to find the next due date to show a cars license for a user
   * @param {User} driver
   */
  getNextDueDateShowDriversLicense(driver: User) {
    if (this.dueDatesShow.has(driver.username)) {
      return this.dueDatesShow.get(driver.username);
    }

    this.eventService.getEventsAssignedToUserWithAction(driver, EventAction.ShowDrivingLicense)
      .pipe(map(events => events.length > 0 ? events[0].dueTime : -1))
      .subscribe(d =>
        this.dueDatesShow.set(driver.username, d));
    return this.dueDatesShow.get(driver.username);
  }

  /**
   * Tries to find the next due date to show a cars license for a user
   * @param {User} driver
   */
  getNextDueDateConfirmDriversLicense(driver: User) {
    if (this.dueDatesConfirm.has(driver.username)) {
      return this.dueDatesConfirm.get(driver.username);
    }

    this.eventService.getEventsAssignedToUserWithAction(driver, EventAction.ConfirmDrivingLicense)
      .pipe(map(events => events.length > 0 ? events[0].dueTime : -1))
      .subscribe(d => this.dueDatesConfirm.set(driver.username, d));

    return this.dueDatesConfirm.get(driver.username);
  }


}
