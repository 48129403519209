import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MapComponent} from '../components/cars/map/map.component';
import {MatDialog} from '@angular/material';
import {
  CarEventConversationComponent,
  ICarEventConversationData
} from '../components/events/car-event-conversation/car-event-conversation.component';
import {Profile, FleetProfileStatus} from 'squaretrip-ts-model';
import {DriverService} from '../services/driver.service';
import {ConfigService, SquareTripProductType} from '../services/config.service';

@Component({
  selector: 'app-car-card',
  templateUrl: './car-card.component.html',
  styleUrls: ['./car-card.component.scss']
})
export class CarCardComponent implements OnInit {

  _car: Profile;
  FleetProfileStatus = FleetProfileStatus;
  private lastLocation = null;
  productType: SquareTripProductType;

  @Input()
  set car(car: Profile) {
    this._car = car;
    this.drawMap();
  }

  @Output()
  delete = new EventEmitter<Profile>();

  @Output()
  edit = new EventEmitter<Profile>();

  _map: MapComponent;
  @ViewChild(MapComponent)
  set map(value) {
    this._map = value;
    this.drawMap();
  }
  get map() {
    return this._map;
  }

  constructor(private dialog: MatDialog,
              private configService: ConfigService,
              private driverService: DriverService) {
    this.productType = this.configService.getProductType();
  }

  ngOnInit() {
  }

  onMapReady() {
    this.drawMap();
  }

  private drawMap() {
    if (this._car.location && this.map) {
        if (this.lastLocation !== null && this.lastLocation.longitude !== this._car.location.longitude && this.lastLocation.latitude !== this._car.location.latitude) {
          this.map.clearMap();
        }
        this.map.drawTripMarker(this._car.location, 'assets/icons/flag_start.png', true);
        this.lastLocation = this._car.location;
      }

  }

  openChat() {
    this.dialog.open(CarEventConversationComponent, {
      width: '50%',
      height: '80%',
      data: {profileId: this._car.id} as ICarEventConversationData
    });
  }

  getDriverNameForCar(car: Profile) {
    const driver = this.driverService.getDriver(car.checkedOutBy);
    return driver ? `${driver.firstName} ${driver.lastName}` : '';
  }

  explainLocked() {
    alert("Das Profil ist gesperrt")
  }
}
