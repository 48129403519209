<div id="scroll-container" #scrollContainer>
  <ng-container *ngFor="let car of filteredCars; trackBy: trackByFunction">

    <div class="car-entry" (click)="carSelected.emit(car)"
         [attr.data-carId]="car.id"
         [ngClass]="{'highlighted': selectedCar && selectedCar.id === car.id}">
      <div class="icon-container">
        <div class="top-row">
          <img alt="car icon" src="/assets/icons/car_white.svg"/>
          {{car.name}}
        </div>
      </div>
      <div class="body-container">
        <div class="title">
          {{car.licensePlate}}
        </div>
        <div class="address">
          {{car.address}}
        </div>
      </div>
      <div class="driver-container">
        <app-car-status-icon [car]="car"></app-car-status-icon>
        <ng-container *ngIf="car?.status === FleetProfileStatus.IN_USE">
          {{getDriverNameForProfile(car) | async}}
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
