import {EventEmitter, Injectable} from '@angular/core';

/**
 * Service that lets a component access scroll functions for the overall app content.
 * It will normally be consumed by AppComponent, which will take care of the actual scrolling.
 */
@Injectable()
export class ScrollService {

  scrollToTop$ = new EventEmitter<any>();

  constructor() { }

  scrollToTop() {
    this.scrollToTop$.emit();
  }

}
