<div class="header mat-elevation-z2">
  <app-client-logo></app-client-logo>
  <div class="header-content">
    <div class="title">
      <h1>{{'settings.title' | translate}}</h1>
      <p>{{'settings.intro' | translate}}</p>
    </div>
  </div>
</div>
<div class="sq-content">

  <mat-accordion>
    <mat-expansion-panel *ngIf="user">
      <mat-expansion-panel-header>
        {{'settings.user.title' | translate}}
      </mat-expansion-panel-header>
      {{'settings.user.intro' | translate}}
      <br/><br/>

      <mat-form-field>
        <input matInput placeholder="{{'user.firstName' | translate}}" [(ngModel)]="user.firstName"/>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="{{'user.lastName' | translate}}" [(ngModel)]="user.lastName"/>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="{{'user.email' | translate}}" [(ngModel)]="user.email"/>
      </mat-form-field>
      <mat-form-field *ngIf="productType === 'fleet'">
        <input matInput readonly placeholder="{{'driver.role' | translate}}"
               [value]="(user.clientRole === 'user' ? 'driver.roles.user' : 'driver.roles.manager') | translate"/>
      </mat-form-field>

      <div class="buttons">
        <button mat-button color="primary" (click)="saveUser()">{{'action.save' | translate}}</button>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="client && user?.clientRole === 'admin'">
      <mat-expansion-panel-header>
        {{'settings.appearance.title' | translate}}
      </mat-expansion-panel-header>
      {{'settings.appearance.intro' | translate}}
      <br/><br/>
      <mat-form-field>
        <input matInput type="text"
               placeholder="{{'client.properties.name' | translate}}"
               [(ngModel)]="client.whiteLabelProperties.name"
        />
      </mat-form-field>

      <mat-form-field>
        <input matInput type="text"
               placeholder="{{'client.properties.logoUrl' | translate}}"
               [(ngModel)]="client.whiteLabelProperties.logoUrl"
        />
      </mat-form-field>

      <mat-form-field>
        <input matInput type="text"
               [colorPicker]="client.whiteLabelProperties.highlightColor"
               (colorPickerChange)="client.whiteLabelProperties.highlightColor=$event"
               [ngModel]="client.whiteLabelProperties.highlightColor"
               readonly
               placeholder="{{'client.properties.highlightColor' | translate}}"

        />
      </mat-form-field>

      <div class="buttons">
        <button mat-button color="primary" (click)="saveClient()">{{'action.save' | translate}}</button>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="client && user?.clientRole === 'admin'">
      <mat-expansion-panel-header>
        {{'settings.client.title' | translate}}
      </mat-expansion-panel-header>
      <mat-form-field>
        <input matInput placeholder="{{'client.name' | translate}}" [(ngModel)]="client.name"/>
      </mat-form-field>
      <mat-form-field>
        <textarea matInput placeholder="{{'client.address' | translate}}" [(ngModel)]="client.address"></textarea>
      </mat-form-field>
      <div class="buttons">
        <button mat-button color="primary" (click)="saveClient()">{{'action.save' | translate}}</button>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="client && user?.clientRole === 'admin'">
      <mat-expansion-panel-header>
        {{'settings.customization.title' | translate}}
      </mat-expansion-panel-header>
      {{'settings.customization.intro' | translate}}
      <br/><br/>
      <mat-checkbox [(ngModel)]="whiteLabelProperties.enableUvv">
        {{'settings.customization.uvv' | translate}}
      </mat-checkbox>
      <mat-checkbox [(ngModel)]="whiteLabelProperties.enableHu">
        {{'settings.customization.hu' | translate}}
      </mat-checkbox>
      <mat-checkbox [(ngModel)]="whiteLabelProperties.enableConfirmDrivingLicense">
        {{'settings.customization.confirmDrivingLicense' | translate}}
      </mat-checkbox>
      <mat-checkbox [(ngModel)]="whiteLabelProperties.enableShowDrivingLicense">
        {{'settings.customization.showDrivingLicense' | translate}}
      </mat-checkbox>
      <div class="buttons">
        <button mat-button color="primary" (click)="saveClient()">{{'action.save' | translate}}</button>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="client && user?.clientRole === 'admin'">
      <mat-expansion-panel-header>
        {{'settings.defaultPurpose.title' | translate}}
      </mat-expansion-panel-header>
      {{'settings.defaultPurpose.intro' | translate}}<br/><br/>
      <mat-checkbox [checked]="isDefaultPurposeEnabled()" (change)="toggleDefaultPurpose()">
        {{ 'settings.defaultPurpose.enable' | translate}}
      </mat-checkbox>
      <br/><br/>
      <ng-container *ngIf="isDefaultPurposeEnabled()">
        <app-purpose-picker
          [purpose]="client.defaultPurpose"
          (changed)="client.defaultPurpose = $event"
          [showDriver]="false" [showNote]="false"></app-purpose-picker>
      </ng-container>
      <div class="buttons">
        <button mat-button color="primary" (click)="saveClient()">{{'action.save' | translate}}</button>
      </div>
    </mat-expansion-panel>
    <!--<mat-expansion-panel *ngIf="client && user?.clientRole === 'admin'">-->
    <!--<mat-expansion-panel-header>-->
    <!--{{'settings.payment.title' | translate}}-->
    <!--</mat-expansion-panel-header>-->
    <!--<ng-container *ngIf="client.paymentDataSubmitted">-->
    <!--{{'settings.payment.reset.intro' | translate}}<br/><br/>-->
    <!--<button mat-button color="primary" [routerLink]="['/buy']">{{'settings.payment.reset.cta' | translate}}</button>-->
    <!--</ng-container>-->
    <!--<ng-container *ngIf="!client.paymentDataSubmitted">-->
    <!--{{'settings.payment.intro' | translate}}<br/><br/>-->
    <!--<button mat-button color="primary" [routerLink]="['/buy']">{{'settings.payment.cta' | translate}}</button>-->
    <!--</ng-container>-->
    <!--</mat-expansion-panel>-->
  </mat-accordion>


</div>
