import {Injectable} from '@angular/core';
import {AuthenticatedHttpService} from './authenticated-http.service';
import {ConfigService} from './config.service';
import {Subject} from 'rxjs/Subject';
import {Profile} from 'squaretrip-ts-model';


@Injectable()
export class ReportService {

  constructor(private http: AuthenticatedHttpService,
              private config: ConfigService) {
  }

  generateReport(format: string,
                 profile: Profile,
                 year: number,
                 month: number = null,
                 excludeRevisions= false,
                 showPrivateAddresses = false): Subject<boolean> {
    const requestResult = new Subject<boolean>();
    this.http
      .post(`${this.config.getEnvironment().server_report}/send-report-via-email`
        + `?format=${format}`
        + `&profileId=${profile.id}`
        + `&year=${year}`
        + `${(month ? '&month=' + month : '')}`
        + `&excludeRevisions=${excludeRevisions}`
        + `&showPrivateAddresses=${showPrivateAddresses}`,
        {})
      .subscribe(() => {
        requestResult.next(true);
        requestResult.complete();
      }, () => {
        requestResult.next(false);
        requestResult.complete();
      });
    return requestResult;
  }

}
