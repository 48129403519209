import { Injectable } from '@angular/core';
import {AuthenticatedHttpService} from './authenticated-http.service';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';
import {DriverService} from './driver.service';
import {Cost, CostType} from 'squaretrip-ts-model';
import {ConfigService} from './config.service';
import {of} from 'rxjs';
import {flatMap, map} from 'rxjs/operators';

@Injectable()
export class CostsService {

  constructor(private http: AuthenticatedHttpService,
              private driverService: DriverService,
              private configService: ConfigService) { }

  private costs$: BehaviorSubject<Cost[]> = new BehaviorSubject([]);

  public getCosts(forceReload = false): BehaviorSubject<Cost[]> {
    if (this.costs$.getValue().length === 0 || forceReload) {
      this.http.get(`${this.configService.getEnvironment().server_backend}/cost`).subscribe(costs => this.costs$.next(costs));
    }
    return this.costs$;
  }

  getDriverNameForCost(cost: Cost): Observable<string> {
    if (!cost) {
      return of('');
    }
    return this.driverService.getDrivers()
      .pipe(map(drivers => {
        const driver = drivers.filter(checkDriver => checkDriver != null).find(checkDriver => checkDriver.username === cost.username);
        return driver ? `${driver.firstName} ${driver.lastName}` : '';
      }));
  }

  deleteCost(cost: Cost): Observable<any> {
    return this.http
      .delete(`${this.configService.getEnvironment().server_backend}/cost/${cost.id}`)
      .pipe(flatMap(() => this.getCosts(true)));
  }

  saveCost(cost: Cost): Observable<any> {
    cost.lastChanged = +new Date();
    return this.http
      .post(`${this.configService.getEnvironment().server_backend}/cost`, cost)
      .pipe(map(result => {
        this.getCosts(true);
        return result;
      }));
  }

  costTypeToString(costType: string): string {
    return `cost.type.${costType}`;
  }
}
