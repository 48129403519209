import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

export interface GenericDialogData {
  text: string;
  title: string;
  cta: string;
}

@Component({
  selector: 'app-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.css']
})
export class GenericDialogComponent implements OnInit {

  title = '';
  cta = '';
  text = '';

  constructor(private dialogRef: MatDialogRef<GenericDialogComponent>,
              @Inject(MAT_DIALOG_DATA) dialogData: GenericDialogData) {
    this.title = dialogData.title;
    this.cta = dialogData.cta;
    this.text = dialogData.text;
  }

  ngOnInit() {
  }

}
