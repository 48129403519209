import {Component, OnInit} from '@angular/core';
import {Client, IPaymentData} from 'squaretrip-ts-model';
import {ClientService} from '../../../services/client.service';
import {take} from 'rxjs/operators';


@Component({
  selector: 'app-buy',
  templateUrl: './buy.component.html',
  styleUrls: ['./buy.component.scss']
})
export class BuyComponent implements OnInit {

  authorized = false;
  success = false;
  alreadySubmitted = false;
  overrideAlreadySubmitted = false;
  price = '9,99';
  paymentData: IPaymentData = {
    place: '',
    accountHolder: {
      address: '',
      name: ''
    },
    bankName: '',
    bic: '',
    date: +new Date(),
    iban: ''
  };
  private client: Client;

  constructor(private clientService: ClientService) {
    // prefill payment data with client data
    this.clientService.getClient().subscribe(client => {
        this.paymentData.accountHolder.name = client.name;
        this.paymentData.accountHolder.address = client.address;
        this.alreadySubmitted = client.paymentDataSubmitted;
        this.client = client;
        if (client && client.whiteLabelProperties && client.whiteLabelProperties.price) {
          this.price = client.whiteLabelProperties.price;
        }
      }
    );
  }

  ngOnInit() {

  }

  submit() {
    this.client.whiteLabelProperties.price = this.price;
    this.clientService.saveClient(this.client).subscribe();
    this.clientService.submitPaymentData(this.paymentData).subscribe(() => {
      this.success = true;
    }, () => {

    });
  }
}
