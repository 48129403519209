import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

export interface SquareTripEnvironment {
  server_backend: string;
  server_report: string;
  server_events: string;
  server_authentication: string;
  protocol: string;
}

export type SquareTripProductType = 'fleet' | 'driving-log';

@Injectable()
export class ConfigService {
  environments = {
    production: {
      server_backend: 'https://api.squaretrip.net',
      server_report: 'https://report-generator.api.squaretrip.net',
      server_authentication: 'https://authentication.api.squaretrip.net',
      server_events: 'https://events.api.squaretrip.net',
      protocol: 'https'
    }, local: {
      server_backend: 'http://localhost:3001',
      server_report: 'http://localhost:3003',
      server_authentication: 'http://localhost:3004',
      protocol: 'http'
    },
    preprod: {
      server_backend: 'https://api.preprod.squaretrip.net',
      server_report: 'https://report-generator.api.preprod.squaretrip.net',
      server_authentication: 'https://authentication.api.preprod.squaretrip.net',
      server_events: 'https://events.api.preprod.squaretrip.net',

      protocol: 'https'
    },
    development: {
      server_backend: 'https://api.preprod.squaretrip.net',
      server_authentication: 'https://authentication.api.preprod.squaretrip.net',
      server_report: 'https://report-generator.api.preprod.squaretrip.net',
      server_events: 'https://events.api.preprod.squaretrip.net',
      protocol: 'https'
    }
  };

  public token = '';

  constructor() {
  }

  getEnvironment(): SquareTripEnvironment {
    return this.environments[environment.envName || 'production'];
  }

  getProductType(): SquareTripProductType {
    return environment.productType as SquareTripProductType;
  }



}
