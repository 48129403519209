import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Profile} from 'squaretrip-ts-model';
import {CarDetailedListComponent} from '../car-detailed-list/car-detailed-list.component';
import {AllCarsMapComponent} from '../all-cars-map/all-cars-map.component';
import {CarService} from '../../../services/car.service';
import {Subscription} from 'rxjs/Subscription';
import {SpinnerService} from '../../../services/spinner.service';
import {ScrollService} from '../../../services/scroll.service';

@Component({
  selector: 'app-cars-maps-overview',
  templateUrl: './cars-maps-overview.component.html',
  styleUrls: ['./cars-maps-overview.component.scss']
})
export class CarsMapsOverviewComponent implements OnInit, OnDestroy {

  subscriptions = new Subscription();
  cars: Profile[] = [];

  @ViewChild('list') listComponent: CarDetailedListComponent;
  @ViewChild('map') mapComponent: AllCarsMapComponent;
  selectedCar: Profile | null = null;
  _filterSearch = '';
  set filterSearch(value) {
    this._filterSearch = value;
    this.listComponent.setFilterSearch(value);
    this.mapComponent.setFilterSearch(value);
  }

  constructor(private carService: CarService,
              private spinnerService: SpinnerService,
              private scrollService: ScrollService) { }

  ngOnInit() {
    this.spinnerService.showSpinner();
    this.subscriptions.add(this.carService.getCars().subscribe(cars => {
      this.cars = cars;
      this.spinnerService.hideSpinner();
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onCarSelected(car: Profile) {
    this.selectedCar = car;
    this.listComponent.selectedCar = car;
    this.mapComponent.zoomToCar(car);
  }

}
