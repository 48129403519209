import {Component, OnInit, ViewChild} from '@angular/core';
import {EventService} from '../../../services/event.service';
import {DriverService} from '../../../services/driver.service';
import {Observable} from 'rxjs/Observable';

import {CarService} from '../../../services/car.service';
import {MatCheckboxChange} from '@angular/material';
import {User, Event, Profile, EventStatus, EventCategory, EventAction, EventType} from 'squaretrip-ts-model';
import {showEditFormAnimation} from '../../../animations/just-edited';
import {LoginService} from '../../../services/login.service';
import {Subscription} from 'rxjs/Subscription';
import {SnackBarService} from '../../../services/snack-bar.service';
import {ActivatedRoute} from '@angular/router';
import {FilterBarComponent} from '../../filter-bar/filter-bar.component';
import {SpinnerService} from '../../../services/spinner.service';
import {NgForm} from '@angular/forms';
import {combineLatest} from 'rxjs';

@Component({
  selector: 'app-event-overview',
  templateUrl: './event-overview.component.html',
  styleUrls: ['./event-overview.component.scss'],
  animations: [
    showEditFormAnimation
  ]
})
export class EventOverviewComponent implements OnInit {
  @ViewChild('filterBar') filterBar: FilterBarComponent;
  @ViewChild('form') form: NgForm;
  EventCategory = EventCategory;
  EventAction = EventAction;
  EventType = EventType;
  drivers: User[];
  allEvents: Event[] = [];
  filteredEvents: Event[] = [];
  cars: Profile[] = [];
  carsWithChatEvents: Profile[] = [];
  driverEvents: Event[] = [];
  carEvents: Event[] = [];
  private currentUser: User;

  _eventToEditDueTime: Date = new Date();

  set eventToEditDueTime(value) {
    this._eventToEditDueTime = value;
    this.eventToEdit.dueTime = +new Date(value);
  }

  get eventToEditDueTime() {
    return this._eventToEditDueTime;
  }

  /**
   * Event that is currently being edited
   * @type {null}
   */
  eventToEdit: Event = new Event();

  /**
   * Show new event form?
   * @type {boolean}
   */
  showNewEventForm = false;

  filterShowClosedEvents = false;
  filterDrivers: User[] = [];
  filterCars: Profile[] = [];

  private subscriptions = new Subscription();

  constructor(private eventService: EventService,
              private driverService: DriverService,
              private carService: CarService,
              private loginService: LoginService,
              private spinnerService: SpinnerService,
              private snackbarService: SnackBarService,
              private route: ActivatedRoute) {


    this.spinnerService.showSpinner();

    this.subscriptions.add(combineLatest(this.eventService.getEvents(), this.driverService.getDrivers(), this.carService.getCars())
      .subscribe(results => {
        this.allEvents = results[0].sort((e1, e2) => e1.dueTime > e2.dueTime ? 1 : -1);
        this.drivers = results[1];
        this.cars = results[2].sort((a, b) => a.licensePlate > b.licensePlate ? 1 : -1);
        this.filter();
        this.spinnerService.hideSpinner();
      }));
  }

  ngOnInit() {
    // handle query paraemter to pre-fitler by car
    this.route.queryParamMap.subscribe(params => {
      if (params.has('filterByCar')) {
        this.carService.getCar(+params.get('filterByCar')).subscribe(car => {
          this.onFilterCarsPicked([car]);
          this.filterCars = [car];
          this.filterBar.setFilteredCars([car]);
        });
      }
    });


    this.subscriptions.add(this.loginService.loadUser().subscribe(user => {
      if (user != null) {
        this.currentUser = user;
        this.resetEventToEdit();
      }
    }));
  }

  onChangeFilterShowClosedEvents($event: MatCheckboxChange) {
    this.filterShowClosedEvents = $event.checked;
    this.filter();
  }

  onFilterDriversPicked(drivers: User[]) {
    this.filterDrivers = drivers;
    this.filter();
  }

  onFilterCarsPicked(cars: Profile[]) {
    this.filterCars = cars;
    this.filter();
  }

  private filter() {

    this.filteredEvents = this.allEvents.filter(event => this.filterShowClosedEvents ? true : event.status === EventStatus.Open);

    this.driverEvents = this.filteredEvents.filter(event => event.category === EventCategory.Driver &&
      this.drivers.findIndex(driver => driver.username === event.toUsername) > -1)
      .filter(event => this.filterDrivers.findIndex(checkDriver => checkDriver.username === event.toUsername) > -1);

    this.carEvents = this.filteredEvents.filter(event => event.category === EventCategory.Car &&
      this.cars.findIndex(car => car && event.forProfile && car.id === event.forProfile.id) > -1)
      .filter(event => event.type === EventType.Task && this.filterCars.findIndex(checkCar => checkCar.id === event.forProfile.id) > -1);

    this.carsWithChatEvents = EventService.getCarsWithChatEventsFromEvents(this.filteredEvents);
  }

  saveEvent() {
    this.eventService.saveEvent(this.eventToEdit).subscribe(() => {
      this.showNewEventForm = false;
      this.resetEventToEdit();
      this.snackbarService.showMessage('event_overview.form.success');
    });
  }

  onCreateEventFromExistingEventClicked(event: Event) {
    this.eventToEdit = Object.assign({}, event);
    this.eventToEdit.id = -1;
    this.eventToEdit.username = this.currentUser.username;
    this.eventToEdit.fromUsername = this.currentUser.username;
    this.eventToEdit.type = EventType.Task;
    this.eventToEdit.lastChanged = +new Date();
    this.eventToEdit.status = EventStatus.Open;
    this.eventToEdit.dueTime = +new Date();
    this.showNewEventForm = true;
  }

  /**
   * Sets the current event to be edited to an empty event that has the relevant fields pre-filled
   */
  private resetEventToEdit() {
    // reset validation when new form is opened so error messages from previous form are not displayed
    this.form.form.markAsPristine();
    this.form.form.markAsUntouched();
    this.form.form.updateValueAndValidity();

    this.eventToEdit = new Event();
    this.eventToEdit.id = -1;
    this.eventToEdit.username = this.currentUser.username;
    this.eventToEdit.fromUsername = this.currentUser.username;
    this.eventToEdit.lastChanged = +new Date();
    this.eventToEdit.status = EventStatus.Open;
    this.eventToEdit.dueTime = +new Date();
  }

  /**
   * Called when button to display event form is clicked
   */
  doShowNewEventForm() {
    if (!this.showNewEventForm) {
      this.resetEventToEdit();
      this.showNewEventForm = true;

    } else {
      this.showNewEventForm = false;
    }
  }

  /**
   * Used to compare to users for the mat-select dropdown
   * @param {User} d1
   * @param {User} d2
   * @return {boolean}
   */
  compareDrivers(d1: User, d2: User): boolean {
    return d1 != null && d2 != null && d1.username === d2.username;
  }

  compareCars(c1: Profile, c2: Profile) {
    return c1 != null && c2 != null && c1.id === c2.id;
  }
}
