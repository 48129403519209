<div class="login-page-style wrapper">
  <div class="box">
    <div *ngIf="!done">
      {{'reset_password.intro' | translate}}
      <br/><br/>
      <mat-form-field>
        <input type="password" matInput placeholder="{{'login.password' | translate}}" [(ngModel)]="password"/>
      </mat-form-field>
      <br/>
      <button mat-button color="primary" (click)="resetPassword()">{{'reset_password.cta' | translate}}</button>
    </div>
    <div *ngIf="done">
      {{'reset_password.reset_successful' | translate}}<br/><br/>
      <button mat-raised-button color="primary" [routerLink]="'/'" *ngIf="forAdmin || productType === 'driving-log'">{{'login.cta' | translate}}</button>
    </div>
  </div>
</div>
