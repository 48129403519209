import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {AuthenticatedHttpService} from './authenticated-http.service';
import {ConfigService} from './config.service';
import {LoginService, LoginState} from './login.service';
import {Observable} from 'rxjs/Observable';
import {Trip} from 'squaretrip-ts-model';
import {Subject} from 'rxjs/Subject';
import {map} from 'rxjs/operators';
import {Purpose} from 'squaretrip-ts-model/dist/classes/purpose';

@Injectable()
export class TripService {
  public trips = new BehaviorSubject<Trip[]>([]);

  public tripsChanged$ = new Subject();

  private startedLoading = false;
  private tripsLoaded = false;

  constructor(private http: AuthenticatedHttpService, private config: ConfigService, private loginService: LoginService) {}

  loadTrips(startingAfter = null, endingBefore = null): Observable<Trip[]> {
    return this.http
      .get(this.config.getEnvironment().server_backend + `/trips?excludeTripTypes=walking&startingAfter=${startingAfter}&endingBefore=${endingBefore}&onlyMajorLocations=true&noLocations=true`);
  }

  loadTripWithLocations(trip: Trip): Observable<Trip> {
    return this.http
      .get(this.config.getEnvironment().server_backend + `/trips/${trip.id}?onlyMajorLocations=true`);
  }

  fillReadableMileage(trip: Trip) {
      trip._startMileage = Math.round(trip.startMileage / 1000);
      trip._endMileage = Math.round(trip.endMileage / 1000);
      return trip;
  }

  saveTrip(trip, recalculateMileage = false) {

    if (trip.purpose) {
      trip.purpose = Purpose.fromPojo(trip.purpose);
      trip.purpose.updatePrimaryKeyAndName();
    }
    const tripToSave = Object.assign({}, trip);
    tripToSave.lastChanged = +new Date();
    delete tripToSave._startMileage;
    delete tripToSave._endMileage;
    if (tripToSave.profile) {
      delete tripToSave.profile._currentMileage;
      delete tripToSave.profile._new_currentMileage;
    }
    const ret = this.http.post(`${this.config.getEnvironment().server_backend}/trips?recalculateMileage=${recalculateMileage}`, tripToSave)
      .pipe(map(newTrip => {
        // replace trip in trip list
        const trips = this.trips.getValue();
        const index = trips.findIndex(checkTrip => checkTrip.id === trip.id);
        newTrip = this.fillReadableMileage(newTrip);
        // preserve locations as they are not sent back by the backend
        newTrip.locations = trip.locations;

        trips.splice(index, 1, newTrip);
        this.trips.next(trips);
        this.loadTrips();

        this.tripsChanged$.next();
        return newTrip;
      }));
    return ret;
  }

  deleteTrip(trip) {
    this.http.delete(this.config.getEnvironment().server_backend + '/trips/' + trip.id)
      .subscribe(result => {
        this.tripsChanged$.next();
      }, error => {
      });
  }

}
