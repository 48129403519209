<div class="sq-content">
  <h2>{{'initial_password.title' | translate}}</h2>
  <ng-container *ngIf="!progress && !done && !error">
    <mat-form-field>
      <input matInput placeholder="{{'register.email' | translate}}" [value]="username" readonly />
    </mat-form-field>
    <br/>
    <mat-form-field>
      <input type="password" matInput placeholder="{{'register.password' | translate}}" [(ngModel)]="password" minlength="6"/>
    </mat-form-field>
    <br/>
    <br/>
    <br/>
    <mat-checkbox [(ngModel)]="acceptedTermsAndConditions">
      <small [innerHTML]="'initial_password.intro' | translate | safeHtml"></small>
    </mat-checkbox>
    <br/>
    <br/>
    <br/>
    <button
      [disabled]="!acceptedTermsAndConditions"
      color="primary" mat-raised-button (click)="setPassword()">{{'initial_password.cta' | translate}}</button>
  </ng-container>
  <ng-container *ngIf="done">
    <span *ngIf="forAdmin" [innerHTML]="'initial_password.success_admin' | translate | safeHtml"></span>
    <span *ngIf="!forAdmin" [innerHTML]="'initial_password.success_user' | translate | safeHtml"></span><br/><br/><br/>
  </ng-container>
  <ng-container *ngIf="progress">
    <mat-spinner></mat-spinner>
  </ng-container>
  <ng-container *ngIf="error">
    {{'initial_password.error' | translate}}
  </ng-container>
</div>
