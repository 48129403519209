import {Component, OnDestroy, OnInit} from '@angular/core';
import {CostsService} from '../../services/costs.service';
import {Cost, CostType, Profile, Trip} from 'squaretrip-ts-model';
import {Subscription} from 'rxjs/Subscription';
import {TripService} from '../../services/trip.service';
import {Subject} from 'rxjs/Subject';
import {combineLatest} from 'rxjs/observable/combineLatest';

import {CarService} from '../../services/car.service';
import {MatDialog} from '@angular/material';
import {startWith} from 'rxjs/operators';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  costs: Cost[] = [];
  allCostTypes: CostType[] = [];
  trips: Trip[] = [];
  cars: Profile[] = [];
  subscriptions = new Subscription();

  startDatePicked = new Subject();
  endDatePicked = new Subject();

  startDate = 0;
  endDate = +new Date();


  constructor(public tripService: TripService,
              private costService: CostsService,
              private carService: CarService,
              private dialog: MatDialog) {
    // set start date to beginning of day one week ago
    const startDate = new Date(+new Date() - 31 * 24 * 60 * 60 * 1000);
    startDate.setHours(0, 0, 0, 0);
    this.startDate = +startDate;
  }

  ngOnInit() {
    this.subscriptions
      .add(
        combineLatest(this.startDatePicked.pipe(startWith(this.startDate)),
          this.endDatePicked.pipe(startWith(this.endDate))
        ).subscribe(([startDate, endDate]) => {
          this.startDate = +startDate;
          this.endDate = +endDate;
          this.loadTrips();
          this.loadCosts();
        }));
    this.subscriptions.add(this.carService.getCars().subscribe(cars => this.cars = cars));
  }

  hasLocations() {
    return this.cars.findIndex(car => car.location != null) > -1;
  }


  private loadTrips() {
    this.subscriptions.add(this.tripService.loadTrips(this.startDate, this.endDate).subscribe(trips => this.trips = trips));
  }

  private loadCosts() {
    this.subscriptions.add(this.costService.getCosts().subscribe(costs => {
      this.costs = costs.filter(cost => cost.time >= this.startDate && cost.time <= this.endDate);
      this.allCostTypes = [];
      this.costs.forEach(cost => {
        if (this.allCostTypes.indexOf(cost.type) === -1) {
          this.allCostTypes.push(cost.type);
        }
      });
    }));

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onStartDatePicker($event: Date) {
    this.startDatePicked.next(+$event);
  }

  onEndDatePicker($event: Date) {
    this.endDatePicked.next(+$event);
  }

}
