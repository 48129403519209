import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {Router} from '@angular/router';
import {LoginService, LoginState} from '../../../services/login.service';
import {ConfigService, SquareTripProductType} from '../../../services/config.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  email = '';
  password = '';
  enabled = true;
  error = false;
  errorLabel = null;
  productType: SquareTripProductType = 'fleet';
  private subscription: Subscription;

  constructor(private loginService: LoginService,
              private configService: ConfigService,
              private router: Router) {
    this.productType = this.configService.getProductType();
  }

  ngOnInit() {
    this.subscription = this.loginService.loginState$.subscribe(state => {
      if (state === LoginState.LOGGED_IN) {
        this.sendToPostLoginPage();
      }
    });
  }

  private sendToPostLoginPage() {
    if (this.productType === 'fleet') {
      this.router.navigate(['/dashboard']);
    } else if (this.productType === 'driving-log') {
      this.router.navigate(['/driving-log']);
    }

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  login($event) {
    this.error = false;
    this.errorLabel = null;
    $event.preventDefault();
    this.enabled = false;
    this.loginService.login(this.email, this.password)
      .subscribe(state => {
        if (state === LoginState.LOGGED_IN) {
          this.sendToPostLoginPage();
        } else {
          if (state === LoginState.NOT_AUTHORIZED) {
            this.errorLabel = 'login.errors.no_admin';
          }
          this.error = true;
          this.enabled = true;
        }
      });
  }

}
