import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatCheckboxChange, MatInput} from '@angular/material';
import {Profile, User} from 'squaretrip-ts-model';

declare type CarFilterType = 'model' | 'licensePlate';

@Component({
  selector: 'app-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss']
})
export class FilterBarComponent implements OnInit {

  _allAvailableCars: Profile[] = [];
  @Input() set allAvailableCars(cars: Profile[]) {
    this._allAvailableCars = cars;
    // if no car was selected, automatically select all allCars
    if (!this.filterCar['model'] || this.filterCar['model'].length === 0) {
      this.filterCar['licensePlate'] = Object.assign([], cars);
      this.preFilteredCars['licensePlate'] = Object.assign([], this.filterCar['licensePlate']);
      this.availableCars['model'] = this.allAvailableCars.reduce((pv, cv) => {
        if (pv.findIndex(p => p.name === cv.name) === -1) {
          pv.push(cv);
        }
        return pv;
      }, []);
      this.availableCars['licensePlate'] = Object.assign([], this.filterCar['licensePlate']);
      this.preFilteredCars['model'] = Object.assign([], this.availableCars['model']);
      this.filterCar['model'] = Object.assign([], this.availableCars['model']);

      this.carsPicked.emit(this.filterCar['licensePlate']);
    }
  }

  get allAvailableCars(): Profile[] {
    return this._allAvailableCars;
  }

  availableCars: { model: Profile[], licensePlate: Profile[] } = {
    model: [],
    licensePlate: []
  };

  /**
   * Cars pre-filtered by carPreFilter. Only these are displayed to a user.
   * @type {any[]}
   */
  preFilteredCars: { model: Profile[], licensePlate: Profile[] } = {
    model: [],
    licensePlate: []
  };

  _availableDrivers: User[] = [];
  @Input() set availableDrivers(drivers: User[]) {
    this._availableDrivers = drivers;
    if (!this.filterDriver || this.filterDriver.length === 0) {
      this.filterDriver = Object.assign([], drivers);
      this.preFilteredDrivers = Object.assign([], this.filterDriver);
      this.driversPicked.emit(this.filterDriver);
    }
  }

  get availableDrivers(): User[] {
    return this._availableDrivers;
  }

  /**
   * Drivers pre-filtered by driverPreFitler. Only these are displayed to a user.
   * @type {any[]}
   */
  preFilteredDrivers: User[] = [];
  /**
   * Used to pre-filter the available car options using an input field
   * @type {any[]}
   */
  driverPreFilter = '';

  @Input() showCars = true;
  @Input() showDrivers = true;
  @Input() showDates = true;


  @Output() carsPicked = new EventEmitter<Profile[]>();
  @Output() driversPicked = new EventEmitter<User[]>();
  @Output() startDatePicked = new EventEmitter<Date>();
  @Output() endDatePicked = new EventEmitter<Date>();

  filterCar: { model: Profile[], licensePlate: Profile[] } = {
    licensePlate: [],
    model: []
  };
  filterDriver: User[] = [];


  _filterDateStart = new Date(+new Date() - 7 * 24 * 60 * 60 * 1000);
  @Input('startDate')
  set filterDateStart(value) {
    this._filterDateStart = value;
    this.startDatePicked.emit(value);
  }

  get filterDateStart() {
    return this._filterDateStart;
  }

  _filterDateEnd = new Date();
  @Input('endDate')
  set filterDateEnd(value) {
    this._filterDateEnd = value;
    this.endDatePicked.emit(value);
  }

  get filterDateEnd() {
    return this._filterDateEnd;
  }

  @ViewChild('searchCarsByModel') searchFieldCarsByModel: ElementRef;
  @ViewChild('searchCarsByLicensePlate') searchFieldCarsByLicensePlate: ElementRef;
  @ViewChild('searchDrivers') searchFieldDrivers: ElementRef;

  constructor() {
  }

  ngOnInit() {
  }

  /**
   * Sets the list of filtered cars
   * @param {Profile[]} cars
   */
  setFilteredCars(cars: Profile[]) {
    this.filterCar['model'] = cars;
    this.filterCar['licensePlate'] = cars;
  }

  getFilteredDriverNames() {
    const text = this.filterDriver.reduce((pv, cv) => pv + cv.lastName + ', ', '');
    if (text.length > 30) {
      return text.substr(0, 27) + '…';
    } else {
      return text.substr(0, text.length - 2);
    }
  }

  isCarPartOfFilter(type: CarFilterType, car: Profile) {
    return this.filterCar[type].findIndex(c => c.id === car.id) > -1;
  }

  getFilteredCarNames(type: CarFilterType) {
    const text = this.filterCar[type].reduce((pv, cv) => pv + (type === 'model' ? cv.name : cv.licensePlate) + ', ', '');
    if (text.length > 30) {
      return text.substr(0, 27) + '…';
    } else {
      return text.substr(0, text.length - 2);
    }
  }

  isDriverPartOfFilter(driver: Profile) {
    return this.filterDriver.findIndex(c => c.username === driver.username) > -1;
  }


  carFilterSelectionChanged(type: CarFilterType, car: Profile, $event: MatCheckboxChange) {
    if ($event.checked) {
      this.filterCar[type].push(car);
    } else {
      this.filterCar[type].splice(this.filterCar[type].indexOf(car), 1);
    }
    // when model is selected, pre-filter the cars displayed under license plate
    if (type === 'model') {
      this.enforceDependencyBetweenModelAndLicensePlate();
    }
    this.emitFilteredCars();
  }

  /**
   * User has selected the option to display all allCars
   * @param {MatCheckboxChange} $event
   */
  allCarsSelectionSwitched(type: CarFilterType, $event: MatCheckboxChange) {
    if ($event.checked) {
      this.filterCar[type] = Object.assign([], this.preFilteredCars[type]);
    } else {
      this.filterCar[type] = [];
    }
    // when model is selected, pre-filter the cars displayed under license plate
    if (type === 'model') {
      this.enforceDependencyBetweenModelAndLicensePlate();
    }
    this.emitFilteredCars();
  }

  private enforceDependencyBetweenModelAndLicensePlate() {
    this.availableCars['licensePlate'] = Object.assign([], this.allAvailableCars.filter(checkCar => {
      return this.filterCar['model'].findIndex(modelCar => checkCar.name.indexOf(modelCar.name) > -1) > -1;
    }));
    this.preFilteredCars['licensePlate'] = Object.assign([], this.availableCars['licensePlate']);

  }

  private emitFilteredCars() {
    this.carsPicked.emit(this.filterCar['licensePlate'].filter(car => {
      return this.filterCar['model'].findIndex(modelCar => car.name.indexOf(modelCar.name) > -1) > -1;
    }));
  }


  driverFilterSelectionChanged(driver: User, $event: MatCheckboxChange) {
    if ($event.checked) {
      this.filterDriver.push(driver);
    } else {
      this.filterDriver.splice(this.filterDriver.indexOf(driver), 1);
    }
    this.driversPicked.emit(this.filterDriver);
  }

  /**
   * User has selected the option to display all cars
   * @param {MatCheckboxChange} $event
   */
  allDriversSelectionSwitched($event: MatCheckboxChange) {
    if ($event.checked) {
      this.filterDriver = Object.assign([], this.availableDrivers);
    } else {
      this.filterDriver = [];
    }
    this.driversPicked.emit(this.filterDriver);
  }

  onCarPreFilterEntered(type: CarFilterType, $event) {
    const text = $event.target.value;
    this.preFilteredCars[type] = this.availableCars[type]
      .filter(car => type === 'model' ? car.name.toLowerCase().indexOf(text.toLowerCase()) > -1 :
        car.licensePlate.toLowerCase().indexOf(text.toLowerCase()) > -1);
    this.filterCar[type] = Object.assign([], this.preFilteredCars[type]);
    this.carsPicked.emit(this.filterCar[type]);
    if (type === 'model') {
      this.enforceDependencyBetweenModelAndLicensePlate();
    }
  }

  onDriverPreFilterEntered($event) {
    const text = $event.target.value;
    this.driverPreFilter = text;
    this.preFilteredDrivers = this.availableDrivers.filter(driver => (driver.firstName.toLowerCase() + " " +
      driver.lastName.toLowerCase()).indexOf(text.toLowerCase()) > -1);
    this.filterDriver = Object.assign([], this.preFilteredDrivers);
    this.driversPicked.emit(this.filterDriver);
  }


  focusSearchCars(type: CarFilterType) {
    const field = type === 'licensePlate' ? this.searchFieldCarsByLicensePlate : this.searchFieldCarsByModel;
    setTimeout(() => {
      if (field != null) {
        field.nativeElement.focus();
      }
    }, 0);
  }

  focusSearchDrivers() {
    setTimeout(() => {
      this.searchFieldDrivers.nativeElement.focus();
    }, 0);
  }
}
