import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {CostsService} from '../../../services/costs.service';
import {Cost, CostType} from 'squaretrip-ts-model';
import {TranslateService} from '@ngx-translate/core';

declare var Chart: any;

@Component({
  selector: 'app-costs-pie-chart-by-type',
  templateUrl: './costs-pie-chart-by-type.component.html',
  styleUrls: ['./costs-pie-chart-by-type.component.scss']
})
export class CostsPieChartByTypeComponent implements OnInit {

  @ViewChild('canvas') canvas;
  _costs: Cost[] = [];
  _costTypesSelected: CostType[] = [];
  private drawnBefore = false;
  private chart: any = null;

  get costs() {
    return this._costs;
  }

  get costTypesSelected() {
    return this._costTypesSelected;
  }

  @Input() set costs(value) {
    this._costs = value;
    this.renderCosts();
  }

  @Input() set costTypesSelected(value) {
    this._costTypesSelected = value;
    this.renderCosts();
  }

  constructor(private costService: CostsService,
              private translateService: TranslateService) {
  }

  ngOnInit() {
  }

  private getSumByCostType(costType: CostType): number {
    return this.costs
      .filter(c => c.type === costType)
      .reduce((pvc, cvc) => pvc + cvc.sumGross, 0);
  }

  private emptyChart() {
    this.chart.data.labels = [];
    this.chart.data.datasets[0].data = [];
    this.chart.update();
  }

  private renderCosts() {


    this.initChart();
    this.emptyChart();
    this.translateService.get('cost.type').subscribe(costTypeStrings => {
      this.costTypesSelected.forEach(type => {
        const sum = this.getSumByCostType(type);
        this.chart.data.labels.push(costTypeStrings[type]);
        this.chart.data.datasets[0].data.push(sum);
        this.chart.update();
      });
    });


  }

  private initChart() {
    if (this.chart == null) {
      const canvas = this.canvas.nativeElement;
      this.chart = new Chart(canvas, {
        type: 'doughnut',
        data: {
          labels: [],
          datasets: [{
            data: [],
            label: '',
            backgroundColor: [
              'rgba(66, 165, 245, 0.9)',
              'rgba(38, 198, 218, 0.9)',
              'rgba(92, 107, 192, 0.9)',
              'rgba(38, 166, 154, 0.9)',
              'rgba(126, 87, 194, 0.9)',
              'rgba(171, 70, 188, 0.9)',
              'rgba(236, 64, 122, 0.9)',
              'rgba(239, 83, 80, 0.9)',


            ],
            borderColor: [
              'rgba(66, 165, 245, 0.4)',
              'rgba(38, 198, 218, 0.4)',
              'rgba(92, 107, 192, 0.4)',
              'rgba(38, 166, 154, 0.4)',
              'rgba(126, 87, 194, 0.4)',
              'rgba(171, 70, 188, 0.4)',
              'rgba(236, 64, 122, 0.4)',
              'rgba(239, 83, 80, 0.4)',


            ],
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
            position: 'left'
          }
        }
      });
    }
  }


}
