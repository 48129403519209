import {Component, OnDestroy, OnInit} from '@angular/core';
import {CarService} from '../../../services/car.service';
import {Subscription} from 'rxjs/Subscription';
import {MatRadioChange} from '@angular/material';
import {ReportService} from '../../../services/report.service';
import {SnackBarService} from '../../../services/snack-bar.service';
import {Profile} from 'squaretrip-ts-model';
import {Observable} from 'rxjs/Observable';
import {from} from 'rxjs/internal/observable/from';
import {concatMap} from 'rxjs/operators';


@Component({
  selector: 'app-report-component',
  templateUrl: './report-component.component.html',
  styleUrls: ['./report-component.component.scss']
})
export class ReportComponentComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();
  selectedCars: Profile[] = [];
  cars: Profile[] = [];
  selectedCar: Profile | null = null;
  year: number;
  month = 'null';
  availableMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  years = [];
  format = 'pdf';
  excludeRevisions = false;
  done = false;
  error = false;
  progress = false;
  showPrivateAddresses = false;

  constructor(private carService: CarService,
              private reportService: ReportService,
              private snackbar: SnackBarService) {
  }

  ngOnInit() {
    this.subscriptions.add(this.carService.getCars().subscribe(cars => {
      if (this.selectedCar == null) {
        this.selectedCar = cars[0];
      }
      this.cars = cars;
    }));
    for (let year = new Date().getFullYear(); year >= 2017; year--) {
      this.years.push(year);
    }
    this.year = this.years[0];
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onSelectedCarChanged(car: Profile, $event: MatRadioChange) {
    if ($event.source.checked) {
      this.selectedCars.push(car);
    } else {
      this.selectedCars.splice(this.selectedCars.findIndex(checkCar => checkCar.id === car.id), 1);
    }
  }

  isCarSelected(car: Profile) {
    return this.selectedCars.findIndex(checkCar => checkCar.id === car.id) > -1;
  }

  reset($event) {
    $event.preventDefault();
    this.done = false;
  }

  generateReport() {
    this.progress = true;
    this.error = false;
    from(this.selectedCars)
      .pipe(
        concatMap(c => this.reportService.generateReport(this.format,
          c,
          this.year,
          this.month === 'null' ? null : +this.month,
          this.excludeRevisions,
          this.showPrivateAddresses
        ))
      )
      .subscribe(() => {
        this.done = true;
        this.snackbar.showMessage('report.success');
      });

  }

  toggleAll() {
    if (this.areAllSelected()) {
      this.selectedCars = [];
    } else {
      this.selectedCars = this.cars;
    }
  }

  areAllSelected() {
    return this.selectedCars.length === this.cars.length;
  }
}
