<div class="sq-content">
  <h1 *ngIf="!client?.isPro || isInTrialMonth">Werden Sie jetzt SquareTrip Fleet-Kunde</h1>
  <h1 *ngIf="client?.isPro && !isInTrialMonth">Herzlichen Glückwunsch, Sie sind SquareTrip Fleet-Kunde</h1>
  <h2>Optimieren Sie Ihren Fuhrpark rein Smartphone-basiert</h2>
  <div class="pricing-box">
    <div class="title">
      Square Trip Fleet
    </div>
    <div class="content">
      <ng-container *ngIf="client && !client?.isPro || isInTrialMonth">
        Gefällt Ihnen SquareTrip Fleet? <strong>Dann stellen Sie jetzt Ihre Preisanfrage.</strong>
        <br/>

      </ng-container>
      <ng-container *ngIf="client && (!client?.isPro || isInTrialMonth)">
        <div class="contact-table">
          <div>
            <mat-icon>phone</mat-icon>
            <div>
              +49 (0)89 461 311 20
            </div>
          </div>
          <div>
            <mat-icon style="position: relative; top: -3px">email</mat-icon>
            <div>
              <a href="mailto: contact@squaretrip.net">contact@squaretrip.net</a>
            </div>
          </div>
        </div>
      </ng-container>

      <button *ngIf="!client" class="cta" [routerLink]="['/register']">Jetzt kostenlos testen</button>
      <div class="success" *ngIf="client?.isPro && !isInTrialMonth">
        <mat-icon>check</mat-icon> Sie sind bereits Kunde
      </div>

    </div>
  </div>
  <div class="pricing-table">
    <div class="tbody">
      <div class="tr">
        <div class="th">Portal für Fuhrparkmanager</div>
        <div class="td">
          <mat-icon>check</mat-icon>
        </div>
      </div>
      <div class="tr">
        <div class="td">Vollautomatisches und finanzamtkonformes Fahrtenbuch	</div>
        <div class="td">
          <mat-icon>check</mat-icon>
        </div>
      </div>
      <div class="tr">
        <div class="td">Live-Standorte aller Fahrzeuge im Überblick	</div>
        <div class="td">
          <mat-icon>check</mat-icon>
        </div>
      </div>
      <div class="tr">
        <div class="td">Anzeige der Verfügbarkeit von Fahrzeugen	</div>
        <div class="td">
          <mat-icon>check</mat-icon>
        </div>
      </div>
      <div class="tr">
        <div class="td">Anlegen und Verwalten von Fahrzeugen und Fahrern (inkl. Excel-Import)	</div>
        <div class="td">
          <mat-icon>check</mat-icon>
        </div>
      </div>
      <div class="tr">
        <div class="td">Kostenverwaltung und -Statistik	</div>
        <div class="td">
          <mat-icon>check</mat-icon>
        </div>
      </div>
      <div class="tr">
        <div class="td">Führerscheinkontrolle in der App und persönlich	</div>
        <div class="td">
          <mat-icon>check</mat-icon>
        </div>
      </div>

      <div class="tr">
        <div class="td">Erinnerungen für UVV und HU	</div>
        <div class="td">
          <mat-icon>check</mat-icon>
        </div>
      </div>

      <div class="tr">
        <div class="td">Übersicht aller Fahrerkommentare zu Fahrzeugen	</div>
        <div class="td">
          <mat-icon>check</mat-icon>
        </div>
      </div>

      <div class="tr">
        <div class="td">Fahrtenbuch-Export als PDF, Excel und CSV	</div>
        <div class="td">
          <mat-icon>check</mat-icon>
        </div>
      </div>


      <div class="tr">
        <div class="th">App für Fahrer</div>
        <div class="td">
          <mat-icon>check</mat-icon>
        </div>
      </div>
      <div class="tr">
        <div class="td">Live-Standorte aller Fahrzeuge auf einer Karten</div>
        <div class="td">
          <mat-icon>check</mat-icon>
        </div>
      </div>
      <div class="tr">
        <div class="td">Check-In und Check-Out für Fahrzeuge</div>
        <div class="td">
          <mat-icon>check</mat-icon>
        </div>
      </div>
      <div class="tr">
        <div class="td">Automatisches Erfassen von Autofahrten</div>
        <div class="td">
          <mat-icon>check</mat-icon>
        </div>
      </div>
      <div class="tr">
        <div class="td">Übersicht von Autofahrten mit Filterfunktion</div>
        <div class="td">
          <mat-icon>check</mat-icon>
        </div>
      </div>
      <div class="tr">
        <div class="td">Manuelles und automatisches Kategorisieren von Fahrten</div>
        <div class="td">
          <mat-icon>check</mat-icon>
        </div>
      </div>
      <div class="tr">
        <div class="td">Fahrten bei Bedarf manuell anlegen, ändern, löschen</div>
        <div class="td">
          <mat-icon>check</mat-icon>
        </div>
      </div>
      <div class="tr">
        <div class="td">Fahrten zusammenführen</div>
        <div class="td">
          <mat-icon>check</mat-icon>
        </div>
      </div>
      <div class="tr">
        <div class="td">Kosten anlegen und verwalten</div>
        <div class="td">
          <mat-icon>check</mat-icon>
        </div>
      </div>
      <div class="tr">
        <div class="td">Kommentare zu Fahrzeugen hinterlassen</div>
        <div class="td">
          <mat-icon>check</mat-icon>
        </div>
      </div>
    </div>
    <div class="tfoot">
      <div class="th no-bg"></div>
      <div class="th">
        {{ price }} €<br/>
        <span class="subtext">monatliches Abo pro Fahrzeug (zzgl. MwSt.) *</span>
      </div>
    </div>
  </div>
  <button *ngIf="client && (!client?.isPro || isInTrialMonth)" class="cta" [routerLink]="['/buy']">Jetzt kaufen</button>
  <button *ngIf="!client" class="cta" [routerLink]="['/register']">Jetzt kostenlos testen</button>

  <small style="max-width: 50%; margin-top: 6em;">
    * Für die monatliche Rechnungstellung werden alle Fahrzeuge berücksichtigt, mit denen in dem zugehörigen
    Monat mindestens eine Fahrt durchgeführt wurde. Bsp.: Wenn in einem Monat mit 10 Fahrzeugen Fahrten durchgeführt
    wurden, stellen wir 99,90 € (zzgl. Umsatzsteuer) für diesen Monat in Rechnung.<br/>
    Wenn Sie kündigen, stellen wir Ihnen den laufenden Monat noch in Rechnung. Ab dem nachfolgenden Monat ist die Kündigung
    durchgeführt, es entfällt die Zahlung und wir deaktivieren Ihren Zugang.
  </small>
</div>
