<h2 mat-dialog-title>{{'driving_log.manual_trip.title' | translate}}</h2>
<mat-dialog-content>

  <mat-form-field>
    <mat-icon matPrefix>person</mat-icon>
    <mat-select placeholder="{{'driver.title' | translate}}" [(ngModel)]="trip.username">
      <mat-option *ngFor="let driver of drivers" [value]="driver.username">
        {{driver.firstName}} {{driver.lastName }} ({{ driver.username }})
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-icon matPrefix>directions_car</mat-icon>
    <mat-select placeholder="{{'car.title' | translate}}" [(ngModel)]="trip.profile">
      <mat-option *ngFor="let profile of profiles" [value]="profile">{{profile.name !== '' ? profile.name : 'tripTypes.'
      + profile.type | translate}} <span *ngIf="profile.licensePlate">({{profile.licensePlate}})</span>
      </mat-option>
    </mat-select>

  </mat-form-field>
  <span class="error" *ngIf="errors['profile']">{{errors['profile'] | translate}}</span>

  <app-trip-address-picker
    prefixIconSvg="assets/icons/flag_start.svg"
    #startAddress
    placeholder="{{'driving_log.edit.locations.start.placeholder' | translate}}"
    [(ngModel)]="trip.startAddress"
  ></app-trip-address-picker>
  <span class="error" *ngIf="errors['startAddress']">{{errors['startAddress'] | translate}}</span>


  <div class="row50percent">
    <div>
      <mat-form-field>
        <mat-icon matPrefix>calendar_today</mat-icon>
        <input matInput
               [matDatepicker]="startDatePicker"
               placeholder="{{'driving_log.edit.dates.start.placeholder_date' | translate}}"
               [(ngModel)]="startDate"
               readonly/>
        <mat-datepicker-toggle [for]="startDatePicker" matSuffix></mat-datepicker-toggle>
      </mat-form-field>
      <mat-datepicker #startDatePicker></mat-datepicker>
      <span class="error" *ngIf="errors['startDate']">{{errors['startDate'] | translate}}</span>
      <span class="error" *ngIf="errors['date']">{{errors['date'] | translate}}</span>
    </div>
    <div>
      <mat-form-field>
        <mat-icon matPrefix>watch_later</mat-icon>

        <input matInput type="time" placeholder="{{'driving_log.edit.dates.start.placeholder_time' | translate}}"
               [(ngModel)]="startTime"/>
      </mat-form-field>
      <span class="error" *ngIf="errors['startDate']">{{errors['startDate'] | translate}}</span>
      <span class="error" *ngIf="errors['date']">{{errors['date'] | translate}}</span>
    </div>
  </div>
  <app-trip-address-picker
    prefixIconSvg="assets/icons/flag_end.svg"
    #endAddress placeholder="{{'driving_log.edit.locations.end.placeholder' | translate}}"
    [(ngModel)]="trip.endAddress"
  ></app-trip-address-picker>
  <span class="error" *ngIf="errors['endAddress']">{{errors['endAddress'] | translate}}</span>
  <div class="row50percent">
    <div>
      <mat-form-field>
        <mat-icon matPrefix>calendar_today</mat-icon>
        <input matInput
               [matDatepicker]="endDatePicker"
               placeholder="{{'driving_log.edit.dates.end.placeholder_date' | translate}}"
               [(ngModel)]="endDate"
               readonly/>
        <mat-datepicker-toggle [for]="endDatePicker" matSuffix></mat-datepicker-toggle>
      </mat-form-field>
      <mat-datepicker #endDatePicker></mat-datepicker>
      <span class="error" *ngIf="errors['endDate']">{{errors['endDate'] | translate}}</span>
      <span class="error" *ngIf="errors['date']">{{errors['date'] | translate}}</span>
    </div>
    <div>
      <mat-form-field>
        <mat-icon matPrefix>watch_later</mat-icon>
        <input matInput type="time" placeholder="{{'driving_log.edit.dates.end.placeholder_time' | translate}}"
               [(ngModel)]="endTime"/>
      </mat-form-field>
      <span class="error" *ngIf="errors['endDate']">{{errors['endDate'] | translate}}</span>
      <span class="error" *ngIf="errors['date']">{{errors['date'] | translate}}</span>
    </div>
  </div>

  <mat-form-field>
    <mat-icon matPrefix>straighten</mat-icon>
    <input matInput placeholder="{{'trip.distance' | translate}}" type="number" [(ngModel)]="distanceKm"/>
  </mat-form-field>
  <span class="error" *ngIf="errors['distance']">{{errors['distance'] | translate}}</span>


  <h3>{{'trip.purpose.title' | translate}}</h3>
  <ng-container *ngIf="trip">
    <app-purpose-picker [purpose]="trip?.purpose" (changed)="tripPurposeChanged($event)"></app-purpose-picker>
  </ng-container>
  <br/>
  <span class="error" *ngIf="errors['purpose']">{{errors['purpose'] | translate}}</span>
  <br/>
  <button mat-button class="primary" (click)="save()">{{'action.save' | translate}}</button>
</mat-dialog-content>
