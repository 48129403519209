import {Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {Purpose} from 'squaretrip-ts-model/dist/classes/purpose';
import {PurposeType} from 'squaretrip-ts-model';
import {MAT_DIALOG_DATA, MatDialogRef, MatSelectChange} from '@angular/material';
import {PurposeService} from '../../../services/purpose.service';
import {Subject} from 'rxjs/index';
import {takeUntil} from 'rxjs/internal/operators';

@Component({
  selector: 'app-business-purpose-picker-dialog',
  templateUrl: './business-purpose-picker-dialog.component.html',
  styleUrls: ['./business-purpose-picker-dialog.component.css']
})
export class BusinessPurposePickerDialogComponent implements OnInit, OnDestroy {

  purpose = new Purpose();
  note = '';
  private destroyed$ = new Subject();
  existingPurposes: Purpose[] = [];
  showDriver = true;
  showNote = true;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) data: {
                purpose: Purpose,
                driverName: string,
                note: string,
                showNote?: boolean,
                showDriver?: boolean
              },
              private dialogRef: MatDialogRef<BusinessPurposePickerDialogComponent>,
              private purposeService: PurposeService) {
    if (data && data.showNote == null) {
      data.showNote = true;
    }
    if (data && data.showDriver == null) {
      data.showDriver = true;
    }
    this.showNote = data.showNote;
    this.showDriver = data.showDriver;

    if (data.purpose != null) {
      this.purpose = data.purpose;
    }
    if (data.note != null) {
      this.note = data.note;
    }
    if (this.purpose.driver === '' && data.driverName !== '') {
      this.purpose.driver = data.driverName;
    }
    this.purpose.category = PurposeType.PURPOSE_BUSINESS;
  }

  ngOnInit() {
    this.purposeService
      .getPurposes()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(purposes => this.existingPurposes = purposes);
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  save() {
    this.purposeService.savePurpose(this.purpose).subscribe();
    this.dialogRef.close({purpose: this.purpose, note: this.note});
  }

  onExistingPurposeSelected(event: MatSelectChange) {
    const selectedPurpose = event.value as Purpose;
    this.purpose = selectedPurpose;
  }
}
