import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PurposeType} from 'squaretrip-ts-model';
import {IPurpose, MinimumPurpose} from 'squaretrip-ts-model/dist/classes/purpose';
import {BusinessPurposePickerDialogComponent} from '../../purpose/business-purpose-picker-dialog/business-purpose-picker-dialog.component';
import {MatDialog} from '@angular/material';

@Component({
  selector: 'app-purpose-picker',
  templateUrl: './purpose-picker.component.html',
  styleUrls: ['./purpose-picker.component.css']
})
export class PurposePickerComponent implements OnInit {

  @Input() purpose: IPurpose = new MinimumPurpose();
  @Input() showNote = true;
  @Input() showDriver = true;
  @Output() changed = new EventEmitter<IPurpose>();

  Purposes = PurposeType;


  constructor(private dialog: MatDialog) {
  }

  ngOnInit() {
  }

  setPurpose(purposeType: PurposeType) {
    if (this.purpose.category === purposeType && purposeType !== PurposeType.PURPOSE_BUSINESS) {
      return;
    }

    if (this.purpose.category !== purposeType) {
      this.purpose = new MinimumPurpose();
    }

    if (purposeType !== PurposeType.PURPOSE_BUSINESS) {
      this.purpose.category = purposeType;
      this.changed.next(this.purpose);
    } else {
      this.dialog
        .open(BusinessPurposePickerDialogComponent, {
          data: {
            purpose: JSON.parse(JSON.stringify(this.purpose)),
            showNote: this.showNote,
            showDriver: this.showDriver
          }
        })
        .afterClosed()
        .subscribe((result) => {
          if (result == null) {
            return;
          }
          const {purpose} = result;
          if (purpose != null) {
            this.purpose = purpose;
          }

          this.changed.next(this.purpose);
        });
    }

  }

}
