<ng-container *ngFor="let cost of filteredCosts">
  <div class="cost-container" (click)="costSelected.emit(cost)" [ngClass]="{'highlighted': currentlySelectedCost?.id === cost?.id}">
    <div class="icon-container">
      <div>
        <mat-icon>directions_car</mat-icon>
        {{cost?.profile?.licensePlate}}

      </div>
      <div>
        <mat-icon>
          <mat-icon *ngIf="cost.type === CostType.GAS">local_gas_station</mat-icon>
          <mat-icon *ngIf="cost.type === CostType.REPAIR">build</mat-icon>
          <mat-icon *ngIf="cost.type === CostType.PARKING">local_parking</mat-icon>
          <mat-icon *ngIf="cost.type === CostType.WASH">local_car_wash</mat-icon>
          <mat-icon *ngIf="cost.type === CostType.LEASING">vpn_key</mat-icon>
          <mat-icon *ngIf="cost.type === CostType.INSURANCE" svgIcon="insurance"></mat-icon>
        </mat-icon>
        {{'cost.type.' + cost?.type | translate}}
      </div>
    </div>
    <div class="content">
      <div class="time">{{cost.time | date:'shortDate'}}</div>

      <div class="providerInfo">
        <div>
          {{cost.providerName}}
        </div>
        <div>
          {{cost.providerAddress}}
        </div>
      </div>

    </div>
    <div class="cost">
      <div class="sum">{{cost.sumGross | number:'1.2-2'}}</div>
      <div class="currency">EUR</div>
    </div>
  </div>
</ng-container>
