import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import {LoginService, LoginState} from '../services/login.service';



import {Subject} from 'rxjs/Subject';
import {map} from 'rxjs/operators';

@Injectable()
export class LoginGuard implements CanActivate {

  constructor(private loginService: LoginService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.loginService.hasAttemptedLogin) {
      return this.loginService.loginState$.pipe(map(s => {
        return s === LoginState.LOGGED_IN;
      }));
    } else {
      return this.loginService.loginStateNoPersistence$.pipe(map(s => s === LoginState.LOGGED_IN));
    }
  }
}
