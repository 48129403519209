import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Cost, CostType, Profile} from 'squaretrip-ts-model';
import {GasType, RepairType} from 'squaretrip-ts-model/dist/classes/cost';
import {CarService} from '../../../services/car.service';
import {Subject} from 'rxjs/index';
import {takeUntil} from 'rxjs/internal/operators';
import {NgForm} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {CostsService} from '../../../services/costs.service';
import {SnackBarService} from '../../../services/snack-bar.service';

@Component({
  selector: 'app-edit-cost-dialog',
  templateUrl: './edit-cost-dialog.component.html',
  styleUrls: ['./edit-cost-dialog.component.scss']
})
export class EditCostDialogComponent implements OnInit, OnDestroy {

  @ViewChild('form') form: NgForm;

  _cost = new Cost();
  private submitAttempted: boolean;
  private originalCost: Cost;
  private saving = false;
  get cost() {
    return this._cost;
  }
  set cost(value) {
    this._cost = value;
    this.date = new Date(value.time);
  }

  costTypes = Object.keys(CostType);
  repairTypes = Object.keys(RepairType);
  gasTypes = Object.keys(GasType);
  CostType = CostType;
  _date = new Date();
  set date(value) {
    this._date = value;
    this.cost.time = +value;
  }
  get date() {
    return this._date;
  }

  private destroyed$ = new Subject();
  cars: Profile[] = [];

  constructor(private carService: CarService,
              private costService: CostsService,
              private snackbarService: SnackBarService,
              private dialogRef: MatDialogRef<EditCostDialogComponent>,
              @Inject(MAT_DIALOG_DATA) dialogData: Cost) {
    delete this.cost.id;
    if (dialogData != null) {
      this.cost = JSON.parse(JSON.stringify(dialogData));
    }
  }

  ngOnInit() {
    this.carService.getCars().pipe(
      takeUntil(this.destroyed$)
    ).subscribe((cars: Profile[]) => this.cars = cars);

  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  shouldShowErrorForField(formField: NgForm): boolean {
    return (formField.touched || formField.dirty || this.submitAttempted) && formField.invalid;
  }

  compareProfiles(p1: Profile, p2: Profile): boolean {
    if (p1 == null || p2 == null) {
      return false;
    }
    return p1.id === p2.id;
  }

  save() {
    this.submitAttempted = true;
    if (this.form.invalid) {
      return;
    }
    this.saving = true;
    this.costService.saveCost(this.cost)
      .subscribe(() => {
        this.snackbarService.showMessage('messages.successfully_saved');
        this.dialogRef.close(this.cost);
      });
  }
}
