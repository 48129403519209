import {ITrip, PurposeType, Trip, User} from 'squaretrip-ts-model';
import {Injectable} from '@angular/core';
import {DialogService} from '../../services/dialog.service';
import {DriverService} from '../../services/driver.service';

@Injectable()
export class TripHelper {

  constructor(private dialogService: DialogService = null, private driverService: DriverService) {

  }

  public static LOCK_TIME = 7 * 24 * 60 * 60 * 1000;

  public static isLocked(trip: ITrip): boolean {
    return +new Date() - +trip.endTime > TripHelper.LOCK_TIME;
  }

  public static isTripPrivate(trip: Trip, user: User): boolean {
    return (user == null || trip.username !== user.username)
      && (!trip.purpose || !trip.purpose.category || trip.purpose.category === PurposeType.PURPOSE_PRIVATE);
  }

  /**
   * Handles checks whether a trip can be edited. If it can't, it displays a message and returns files. If it can, it simply returns true.
   * @param trip
   */
  public canEditTrip(trip: ITrip): boolean {
    if (TripHelper.isLocked(trip)) {
      this.dialogService.showAlertDialog({
        title: 'driving_log.errors.locked',
        text: 'driving_log.errors.locked_long',
        cta: 'action.ok'
      });
      return false;
    }
    return true;
  }

  public getDriverName(trip: ITrip) {
    if (!trip || !trip.username) {
      return '';
    }
    const driver = this.driverService.getDriver(trip.username);
    return `${driver.firstName} ${driver.lastName}`;
  }
}
