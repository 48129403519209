import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {User} from 'squaretrip-ts-model';

/**
 * Directive that can be used to avoid entering a duplicate email address for a user.
 *
 * Hand over an array of existing users into existingUsers.
 */
@Directive({
  selector: '[appNoDuplicateEmail]',
  providers: [{provide: NG_VALIDATORS, useExisting: NoDuplicateEmailDirective, multi: true}]
})
export class NoDuplicateEmailDirective implements Validator{

  @Input('existingUsers') existingUsers: User[] = [];

  constructor() { }

  registerOnValidatorChange(fn: () => void): void {
  }

  validate(c: AbstractControl): ValidationErrors | null {
    if (this.existingUsers.findIndex(user => user.username === c.value) > -1) {
      const errors: ValidationErrors = {};
      errors.noDuplicateEmail = 'bad';
      return errors;
    } else {
      return null;
    }
  }

}
