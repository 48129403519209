import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Client, User} from 'squaretrip-ts-model';
import {ConfigService} from './config.service';
import {Subject} from 'rxjs/Subject';
import {AuthenticatedHttpService} from './authenticated-http.service';
import {flatMap} from 'rxjs/operators';


@Injectable()
export class RegistrationService {

  constructor(private http: HttpClient,
              private config: ConfigService) {
  }

  public register(user: User, client: Client, couponCode = ''): Observable<any> {
    let url = `${this.config.getEnvironment().server_authentication}?withClient=true`;
    if (couponCode != null && couponCode !== '') {
      url += `&couponCode=${couponCode}`;
    }
    return this.http.post(url, user)
      .pipe(flatMap((response: { tokenString: string }) => {
        const headers = AuthenticatedHttpService.createAuthorizationHeaderFromJwt(response.tokenString);
        return this.http.put(`${this.config.getEnvironment().server_authentication}/client`, client, headers);
      }));
  }

  public resetPassword(username: string, token: string, password: string): Observable<any> {
    return this.http.post(`${this.config.getEnvironment().server_authentication}/reset-password/${username}/${token}`,
      {password: password});
  }

  requestPasswordReset(username: string): Observable<any> {
    return this.http.post(this.config.getEnvironment().server_backend + '/users/request-password-reset/' + username + '?device=web', {});

  }

}
