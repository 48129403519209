import {EventAction, EventStatus, EventType} from 'squaretrip-ts-model';


export class EventLocalizationHelper {
  static eventActionToString(action: EventAction) {
    return 'event.actions.' + action;
  }

  static eventStatusToString(status: EventStatus) {
    return 'event.statuss.' + status;
  }

  static eventTypeToString(type: EventType) {
    return 'event.types.' + type;
  }
}
