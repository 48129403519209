import { Component, OnInit } from '@angular/core';
import {RegistrationService} from '../../../services/registration.service';

@Component({
  selector: 'app-request-password-reset',
  templateUrl: './request-password-reset.component.html',
  styleUrls: ['./request-password-reset.component.scss']
})
export class RequestPasswordResetComponent implements OnInit {
  email: string;
  done = false;
  error = false;
  constructor(private userService: RegistrationService) { }

  ngOnInit() {
  }

  requestPasswordReset() {
    this.error = false;
    this.done = false;
    this.userService.requestPasswordReset(this.email)
      .subscribe(() => {
        this.done = true;
      }, () => {
        this.error = true;
      });
  }

}
