import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Cost, CostType} from 'squaretrip-ts-model';
import {Observable} from 'rxjs/Observable';

@Component({
  selector: 'app-cost-list',
  templateUrl: './cost-list.component.html',
  styleUrls: ['./cost-list.component.scss']
})
export class CostListComponent implements OnInit {

  CostType = CostType;
  _costs: Cost[] = [];
  private _costTypesSelected = [];

  @Input() set costs(value: Cost[]) {
    this._costs = value;
    this.filter();
  }
  get costs() {
    return this._costs;
  }

  @Input() set costTypesSelected(value) {
    this._costTypesSelected = value;
    this.filter();
  }
  get costTypesSelected() {
    return this._costTypesSelected;
  }

  @Input() currentlySelectedCost: Cost = null;
  @Output() costSelected = new EventEmitter<Cost>();
  filteredCosts: Cost[] = [];

  constructor() { }

  ngOnInit() {
  }


  private filter() {
    if (this.costs == null) {
      return;
    }
    this.filteredCosts = this.costs.filter(cost => this.costTypesSelected.indexOf(cost.type) > -1);
    // if there is nothing left to display, un-select any selected costs
    if (this.filteredCosts.length === 0) {
      setTimeout(() => this.costSelected.emit(null), 0);

    } else {
      // is the previously selected cost still visible?
      if (this.currentlySelectedCost == null ||  this.filteredCosts.findIndex(cost => this.currentlySelectedCost.id === cost.id) === -1) {
        setTimeout(() => this.costSelected.emit(this.filteredCosts[0]));
      }
    }
  }

}
