import { Component, OnInit } from '@angular/core';
import {ClientService} from '../../../services/client.service';

import {Client} from 'squaretrip-ts-model';
import {take} from 'rxjs/operators';


@Component({
  selector: 'app-prices-packages',
  templateUrl: './prices-packages.component.html',
  styleUrls: ['./prices-packages.component.scss']
})
export class PricesPackagesComponent implements OnInit {

  client: Client = null;
  isInTrialMonth = false;
  price = '9,99';

  constructor(private clientService: ClientService) { }

  ngOnInit() {
    this.clientService.getClient().pipe(take(1)).subscribe(client => {
      this.client = client;
      if (client && client.whiteLabelProperties && client.whiteLabelProperties.price) {
        this.price = client.whiteLabelProperties.price;
      }
    });
    this.clientService.isInTrialMonth().subscribe(iitm => this.isInTrialMonth = iitm);
  }

}
