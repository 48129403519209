import {Directive, ElementRef, Input} from '@angular/core';
import {PickerDialogComponent} from '../components/picker-dialog/picker-dialog.component';

@Directive({
  selector: '[appPickerHandle]'
})
export class PickerHandleDirective {

  @Input() opensPicker: PickerDialogComponent;

  constructor(el: ElementRef) {
    el.nativeElement.addEventListener('click', () => {
      this.opensPicker.toggle();
    });
  }

}
