import {Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ILocation} from 'squaretrip-ts-model/dist/classes/location';

declare var google: any;

@Component({
  selector: 'app-trip-address-picker',
  templateUrl: './address-picker.component.html',
  styleUrls: ['./address-picker.component.scss'],
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AddressPickerComponent), multi: true}]
})
export class AddressPickerComponent implements OnInit, ControlValueAccessor {
  @ViewChild('input') input;
  address: string;
  @Input() placeholder = '';
  @Input() prefixIcon = null;
  @Input() prefixIconSvg = null;
  private changeFn: any;
  location: ILocation;

  constructor(
              ) {
  }

  ngOnInit() {
    const autocomplete = new google.maps.places.Autocomplete(this.input.nativeElement);
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      const address = place.formatted_address;
      this.location = {
        latitude: +place.geometry.location.lat(),
        longitude: +place.geometry.location.lng(),
        time: 0
      };
      this.address = address;
      if (this.changeFn) {
        this.changeFn(this.address);
      }
    });
  }

  registerOnTouched(fn: any): void {
  }
  writeValue(value: any): void {
    this.address = value;

  }
  registerOnChange(fn): void {
    this.changeFn = fn;
  }

  getLocation(): ILocation {
    return this.location;
  }

}
