import {Component, Injectable, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-date-filter-dialog',
  templateUrl: './date-filter-dialog.component.html',
  styleUrls: ['./date-filter-dialog.component.css']
})
export class DateFilterDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<DateFilterDialogComponent>) {

  }
  ngOnInit() {
  }

}

@Injectable()
export class DateFilterDialogService {

  constructor(private dialogService: MatDialog) {
  }

  showDatePickerDialog() {
    this.dialogService.open(DateFilterDialogComponent);
  }
}
