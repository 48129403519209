<h2 mat-dialog-title>{{ 'cars.pick_dialog.title' | translate }}</h2>
  <mat-dialog-content>
    <mat-select [(ngModel)]="selectedCar" [placeholder]="'cars.pick_dialog.placeholder' | translate">
      <mat-option *ngFor="let profile of cars" [value]="profile">
        {{profile.name !== '' ? profile.name : 'tripTypes.'
        + profile.type | translate}} <span *ngIf="profile.licensePlate">({{profile.licensePlate}})</span>
      </mat-option>
    </mat-select>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'action.cancel' | translate}}</button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button mat-button [mat-dialog-close]="selectedCar">{{ 'action.save' | translate}}</button>
  </mat-dialog-actions>
