<div class="header mat-elevation-z2">
  <app-client-logo></app-client-logo>
  <div class="header-content">
    <div class="title">
      <h1>{{'driving_log.title' | translate}}</h1>
      <p>{{'driving_log.intro' | translate}}</p>
    </div>
    <app-filter-bar [availableDrivers]="availableDrivers"
                    [allAvailableCars]="availableCars"
                    [showDrivers]="productType === 'fleet'"
                    [startDate]="filterStartDate"
                    [endDate]="filterEndDate"
                    (carsPicked)="onCarsPickedForFilter($event)"
                    (driversPicked)="onDriversPickedForFilter($event)"
                    (startDatePicked)="onStartDatePicked($event)"
                    (endDatePicked)="onEndDatePicked($event)">

      <app-picker-dialog #categoryPickerDialog>
        <mat-checkbox
          [checked]="filterPurposes.length === ALL_PURPOSES.length"
          (change)="onAllPurposesChangedForFilter($event)">
          <strong>{{ 'trip.purpose.all' | translate }}</strong>
        </mat-checkbox>
        <mat-checkbox
          [checked]="isPurposeSelectedForFilter(Purposes.UNDEFINED)"
          (change)="onPurposeChangedForFilter(Purposes.UNDEFINED, $event)">
          {{'trip.purpose.undefined' | translate}}
          <span class="badge" *ngIf="getNumberOfUncategorizedTrips() > 0">{{ getNumberOfUncategorizedTrips() }}</span>
        </mat-checkbox>
        <mat-checkbox
          [checked]="isPurposeSelectedForFilter(Purposes.PURPOSE_BUSINESS)"
          (change)="onPurposeChangedForFilter(Purposes.PURPOSE_BUSINESS, $event)">
          {{'trip.purpose.business' | translate}}
        </mat-checkbox>
        <mat-checkbox
          [checked]="isPurposeSelectedForFilter(Purposes.PURPOSE_WAY_TO_WORK)"
          (change)="onPurposeChangedForFilter(Purposes.PURPOSE_WAY_TO_WORK, $event)">
          {{'trip.purpose.way_to_work' | translate}}
        </mat-checkbox>
        <mat-checkbox
          [checked]="isPurposeSelectedForFilter(Purposes.PURPOSE_PRIVATE)"
          (change)="onPurposeChangedForFilter(Purposes.PURPOSE_PRIVATE, $event)">
          {{'trip.purpose.private' | translate}}
        </mat-checkbox>
      </app-picker-dialog>
      <app-filter-bubble [icon]="'category'"
                         [text]="buildPurposeFilterTitle() | async | translate"
                         [badgeText]="getNumberOfUncategorizedTrips() > 0 ? getNumberOfUncategorizedTrips() : null"
                         appPickerHandle
                         [opensPicker]="categoryPickerDialog"
      ></app-filter-bubble>


      <app-filter-bubble [icon]="'add'" [text]="'driving_log.manual_trip.title' | translate"
                         (click)="openManualTripDialog()"></app-filter-bubble>
    </app-filter-bar>
  </div>
</div>


<div class="sq-content driving-log-overview-content">
  <ng-container *ngIf="allTrips.length > 0">
    <div class="list mat-elevation-z2">
      <app-driving-log-list [trips]="filteredTrips" (tripSelected)="tripSelected($event)"
                            [currentTrip]="selectedTrip"></app-driving-log-list>
    </div>
    <div class="detail mat-elevation-z2">
      <app-driving-log-detail *ngIf="!isTripPrivate(selectedTrip)" [trip]="selectedTrip"></app-driving-log-detail>
      <div class="empty-message" *ngIf="isTripPrivate(selectedTrip)">
        <br/><br/>
        <ng-container *ngIf="selectedTrip?.purpose?.category === Purposes.PURPOSE_PRIVATE; else uncategorized">
          {{'driving_log.private_not_displayed' | translate}}
        </ng-container>
        <ng-template #uncategorized>
          {{'driving_log.uncategorized_not_displayed' | translate:{'driverName': getDriverName(selectedTrip)} }}
        </ng-template>
      </div>
    </div>
  </ng-container>
  <div class="empty-message" *ngIf="allTrips.length === 0">
    {{'driving_log.empty-message' | translate}}
  </div>

</div>
