<div class="list-container">
  <ng-container *ngFor="let trip of trips; trackBy:trackByFn">
    <div class="trip" (click)="onTripSelected(trip)"
         [ngClass]="{'highlighted': trip.id === currentTrip?.id && trip.username === currentTrip?.username}">
      <div class="icon-container">
        <div class="top-row">
          <img
            alt="car icon"
            src="/assets/icons/{{ trip?.tripType }}_white.svg"/>
          {{trip.profile?.licensePlate}}
        </div>
        <div class="bottom-row">
          <img
            alt="private icon"
            src="/assets/icons/private_selected_white.svg"
               *ngIf="trip.purpose && trip.purpose.category === Purposes.PURPOSE_PRIVATE"/>
          <img
            alt="way to work icon"
            src="/assets/icons/way_to_work_selected_white.svg"
               *ngIf="trip.purpose && trip.purpose.category === Purposes.PURPOSE_WAY_TO_WORK"/>
          <img
            alt="business icon"
            src="/assets/icons/business_selected_white.svg"
               *ngIf="trip.purpose && trip.purpose.category === Purposes.PURPOSE_BUSINESS"/>
          <img
            alt="undefined purpose icon"
            src="/assets/icons/question_sign_white.svg"
               *ngIf="!trip.purpose || trip.purpose.category === Purposes.UNDEFINED"/>
          {{purposeTypeToString(trip.purpose ? trip.purpose.category : Purposes.UNDEFINED)}}
        </div>
      </div>

      <div class="body-container">
        <div class="headline">
          <strong>{{trip.startTime | date:'shortDate'}}</strong>
          <mat-icon *ngIf="isTripLocked(trip)">lock</mat-icon>
        </div>
        <div class="content-container">
          <div class="time-container">

            <div class="timeline"
                 *ngIf="productType === 'driving-log' || (trip?.purpose && trip?.purpose?.category !== Purposes.PURPOSE_PRIVATE && trip?.purpose?.category !== Purposes.UNDEFINED)">
              <img alt="start icon" src="/assets/icons/flag_start.svg" class="start-flag"/>
              <div class="line"></div>
              <img alt="end icon" src="/assets/icons/flag_end.svg" class="end-flag"/>
            </div>
            <div class="times">
              <div class="start-time">{{trip.startTime | date:'shortTime'}}</div>
              <div class="end-time">{{trip.endTime | date:'shortTime'}}</div>
            </div>
          </div>

          <div class="address-container">
            <div class="start-address">
              <em *ngIf="productType === 'fleet' && trip?.purpose?.category === Purposes.PURPOSE_PRIVATE; else startAddress">
                {{ 'driving_log.private_trip' | translate }}
              </em>
              <ng-template #startAddress>
                {{trip.correctedStartAddress || trip.startAddress}}
              </ng-template>
            </div>
            <div class="end-address">
              <em *ngIf="productType === 'fleet' && trip?.purpose?.category === Purposes.PURPOSE_PRIVATE; else endAddress">
                {{ 'driving_log.private_trip' | translate }}
              </em>
              <ng-template #endAddress>
                {{trip.correctedEndAddress || trip.endAddress}}
              </ng-template>
            </div>
          </div>

          <div class="mileage-container">
            <div class="mileage-start">{{trip.startMileage / 1000 | number:'1.0-0'}} km</div>
            <div class="mileage-end">{{trip.endMileage / 1000 | number:'1.0-0'}} km</div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
