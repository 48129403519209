import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LoginService} from '../../../services/login.service';

@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.css']
})
export class ConfirmAccountComponent implements OnInit {

  success = false;
  error = false;

  constructor(private activeRoute: ActivatedRoute, private loginService: LoginService) {
    this.activeRoute.params.subscribe(params => {
      this.loginService.confirmAccount(params.email, params.token)
        .subscribe(result => {
          this.success = result;
          this.error = !result;
        });
    });
  }

  ngOnInit() {
  }

}
