import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-onboarding-dialog',
  templateUrl: './onboarding-dialog.component.html',
  styleUrls: ['./onboarding-dialog.component.css']
})
export class OnboardingDialogComponent implements OnInit {

  constructor(private matDialogRef: MatDialogRef<OnboardingDialogComponent>) { }

  ngOnInit() {
  }

  cancel() {
    localStorage.setItem('has_seen_onboarding', 'true');
    this.close();
  }

  finish() {
    localStorage.setItem('has_seen_onboarding', 'true');
    this.close();
  }

  close() {
    this.matDialogRef.close();
  }

}
