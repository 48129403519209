import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-filter-bubble',
  templateUrl: './filter-bubble.component.html',
  styleUrls: ['./filter-bubble.component.scss']
})
export class FilterBubbleComponent implements OnInit {

  @Input() text = '';
  @Input() icon = 'date_range';
  @Input() badgeText = null;

  constructor() { }

  ngOnInit() {
  }

}
