<h2 mat-dialog-title>{{ 'purpose.pick_dialog.title' | translate}}</h2>
<mat-dialog-content>
  <mat-form-field *ngIf="existingPurposes.length > 0">
    <mat-select placeholder="{{ 'purpose.pick_dialog.label_existing' | translate}}"
    (selectionChange)="onExistingPurposeSelected($event)">
      <mat-option *ngFor="let existingPurpose of existingPurposes" [value]="existingPurpose">
        {{ existingPurpose.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="{{'purpose.pick_dialog.label_company' | translate}}" [(ngModel)]="purpose.company"/>
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="{{'purpose.pick_dialog.label_contact' | translate}}" [(ngModel)]="purpose.contact"/>
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="{{'purpose.pick_dialog.label_reason' | translate}}" [(ngModel)]="purpose.reason"/>
  </mat-form-field>
  <mat-form-field *ngIf="showDriver">
    <input matInput placeholder="{{'purpose.pick_dialog.label_driver' | translate}}" [(ngModel)]="purpose.driver"/>
  </mat-form-field>
  <mat-form-field *ngIf="showNote">
    <textarea matInput placeholder="{{'purpose.pick_dialog.label_note' | translate}}" [(ngModel)]="note">
    </textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="null">{{ 'action.cancel' | translate}}</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button (click)="save()">{{ 'action.save' | translate}}</button>
</mat-dialog-actions>
