<ng-container *ngFor="let car of cars; trackBy: trackByFn">
  <div class="carContainer"
       [ngClass]="{'highlighted': currentCar?.id === car.id}"
        (click)="onCarSelected(car)">
    <div class="left">
      <img
        alt="car icon"
        src="/assets/icons/car_white.svg"/>
      {{car.name}}
    </div>
    <div class="middle">
      <div class="name">
        {{car.licensePlate}}
      </div>
      <div class="last-comment">
        {{getLastCommentForCar(car)?.title | slice:0:15}}…
      </div>
    </div>
    <div class="right">
      {{getLastCommentForCar(car)?.dueTime | date:'short'}}
    </div>
  </div>
</ng-container>
