<div class="cost-detail-container">
  <div class="cost-detail-header">
    <div class="left">
      <div class="portrait">
        <div class="circle white">
          <img
            alt="driver icon"
            src="/assets/icons/steering_wheel_green.svg"/>
        </div>
        <div class="title">
          {{getDriverNameForCost(cost) | async}}
        </div>
      </div>
      <div class="portrait">
        <div class="circle">
          <img
            alt="car icon"
            src="/assets/icons/car.svg"/>
        </div>
        <div class="title">
          {{cost?.profile?.licensePlate}}<br/>
          ({{cost?.profile?.name}})
        </div>
      </div>
      <div class="portrait cost-type">
        <div class="circle green">
          <mat-icon *ngIf="cost?.type === CostType.GAS">local_gas_station</mat-icon>
          <mat-icon *ngIf="cost?.type === CostType.REPAIR">build</mat-icon>
          <mat-icon *ngIf="cost?.type === CostType.PARKING">local_parking</mat-icon>
          <mat-icon *ngIf="cost?.type === CostType.WASH">local_car_wash</mat-icon>
          <mat-icon *ngIf="cost.type === CostType.LEASING">vpn_key</mat-icon>
          <mat-icon *ngIf="cost.type === CostType.INSURANCE" svgIcon="insurance"></mat-icon>
        </div>
        <div class="title">
          {{'cost.type.' + cost?.type | translate}}

        </div>
      </div>
    </div>

    <div class="right">
      <div class="sum">
        {{cost?.sumGross | number:'1.2-2'}}
      </div>
      <div class="currency">
        EUR
      </div>
    </div>

  </div>
  <div class="content">
    <div class="entry headline">{{'cost.provider' | translate}}</div>
    <div class="entry">{{cost?.providerName}}</div>
    <div class="entry" *ngIf="cost?.providerAddress != ''">{{cost?.providerAddress}}</div>
    <div class="entry headline">{{'cost.invoice_details' | translate}}</div>
    <div class="entry">{{cost?.sumGross}} EUR (brutto)</div>
    <div class="entry">{{cost?.vatRate}} {{'cost.vat_suffix' | translate}}</div>
    <div class="entry" *ngIf="cost?.gasLiters > 0">{{cost?.gasLiters}} {{'cost.liter' | translate}}</div>
    <div class="entry" *ngIf="cost?.gasLiters > 0">{{(cost?.sumGross / cost?.gasLiters) | number:'1.2-2'}}
      {{'cost.currency_per_liter' | translate}}
    </div>
    <div class="entry" *ngIf="cost?.type === CostType.REPAIR && cost?.repairType">{{'cost.repairType.' +
      cost?.repairType | translate}}
    </div>
    <div class="entry" *ngIf="cost?.type === CostType.REPAIR && cost?.mileage > 0">{{'cost.mileage' | translate}}:
      {{cost.mileage / 1000 | number:'1.0-0'}}
    </div>
    <div class="entry" *ngIf="cost?.time">{{cost?.time | date:'longDate'}}, {{cost?.time | date:'shortTime'}}</div>
    <div class="entry" *ngIf="cost?.comment">{{'cost.comment' | translate}}: "{{cost?.comment}}"</div>
  </div>
  <div class="cost-detail-footer">
    <button mat-icon-button (click)="editCost()">
      <mat-icon>
        edit
      </mat-icon>
    </button>
    <button mat-icon-button (click)="deleteCost()">
      <mat-icon>
        delete
      </mat-icon>
    </button>
  </div>
</div>
