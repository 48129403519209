<div class="purposes">

  <div class="portrait" style="cursor: pointer" (click)="setPurpose(Purposes.PURPOSE_PRIVATE)">
    <div class="circle green">
      <img
        alt="private icon active"
        src="/assets/icons/private_selected_white.svg"
        *ngIf="purpose?.category === Purposes.PURPOSE_PRIVATE"/>
      <img
        alt="private icon inactive"
        src="/assets/icons/private_white.svg" *ngIf="purpose?.category !== Purposes.PURPOSE_PRIVATE"/>
    </div>
    <div class="title black" [ngClass]="{'highlighted': purpose?.category === Purposes.PURPOSE_PRIVATE}">
      {{'trip.purpose.private' | translate}}
    </div>
  </div>
  <div class="portrait" style="cursor: pointer" (click)="setPurpose(Purposes.PURPOSE_WAY_TO_WORK)">
    <div class="circle green">
      <img
        alt="way to work icon active"
        src="/assets/icons/way_to_work_selected_white.svg"
        *ngIf="purpose?.category === Purposes.PURPOSE_WAY_TO_WORK"/>
      <img
        alt="way to work icon inactive"
        src="/assets/icons/way_to_work_white.svg"
        *ngIf="purpose?.category !== Purposes.PURPOSE_WAY_TO_WORK"/>
    </div>
    <div class="title black" [ngClass]="{'highlighted': purpose?.category === Purposes.PURPOSE_WAY_TO_WORK}">
      {{'trip.purpose.way_to_work' | translate}}

    </div>
  </div>
  <div class="portrait" style="cursor: pointer" (click)="setPurpose(Purposes.PURPOSE_BUSINESS)">
    <div class="circle green">
      <img
        alt="business icon active"
        src="/assets/icons/business_selected_white.svg"
        *ngIf="purpose?.category === Purposes.PURPOSE_BUSINESS"/>
      <img
        alt="business icon inactive"
        src="/assets/icons/business_white.svg" *ngIf="purpose?.category !== Purposes.PURPOSE_BUSINESS"/>
    </div>
    <div class="title black" [ngClass]="{'highlighted': purpose?.category === Purposes.PURPOSE_BUSINESS}">
      {{'trip.purpose.business' | translate}}
    </div>
  </div>


</div>
