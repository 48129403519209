import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material';

@Injectable()
export class SnackBarService {

  constructor(private translate: TranslateService, private snackBar: MatSnackBar) {

  }

  showMessage(key) {
    this.translate.get(key).subscribe(text => {
      this.snackBar.open(text,
        null,
        {
          duration: 2000
        }
      );
    });
  }

  showTripLockedWarningMessage() {
    this.showMessage('trip_locked_warning');
  }

  showProfileLockedWarningMessage() {
    this.showMessage('profile_locked_warning');
  }


}
