import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatCheckboxChange} from '@angular/material';
import {CostsService} from '../../../services/costs.service';
import {Cost, CostType} from 'squaretrip-ts-model';

class CostTypeWithSum {
  costType: CostType;
  sum: number;
  selected = false;
}

@Component({
  selector: 'app-costs-overview-filter',
  templateUrl: './costs-overview-filter.component.html',
  styleUrls: ['./costs-overview-filter.component.scss']
})
export class CostsOverviewFilterComponent implements OnInit {



  _costs: Cost[];

  @Input() set costs(value) {
    this._costs = value;
    this.extractCostTypes();
  }

  @Input() set initialCostTypesSelected(value: CostType[]) {
    this.extractCostTypes();
    value.forEach(type => {
      const costType = this.costTypes.find(ct => ct.costType === type);
      if (costType != null) {
        costType.selected = true;
      }
    });
  }

  @Output() costTypesSelected = new EventEmitter<CostType[]>();

  costTypes: CostTypeWithSum[] = [];

  constructor(private costService: CostsService) { }

  ngOnInit() {
  }

  costSelectionChanged(costTypeWithSum: CostTypeWithSum, $event) {
    $event.stopPropagation();
    $event.preventDefault();
    costTypeWithSum.selected = !costTypeWithSum.selected;
    this.emitCostTypesSelected();
  }

  /**
   * Extracts the cost types from the given list of overview
   */
  private extractCostTypes() {
    this.costTypes = [];
    this._costs.forEach(cost => {
      if (this.costTypes.findIndex(type => type.costType === cost.type) === -1) {
        const costTypeWithSum = new CostTypeWithSum();
        costTypeWithSum.costType = cost.type;
        costTypeWithSum.sum = 0;
        this.costTypes.push(costTypeWithSum);
      }
      this.costTypes.find(type => type.costType === cost.type).sum += cost.sumGross;
    });
    this.costTypes.sort((a, b) => a.sum > b.sum ? -1 : 1);
  }

  /**
   * Translates a cost type into a human readable string
   * @param {CostType} type
   * @return {string}
   */
  costTypeToString(type: CostType): string {
    return this.costService.costTypeToString(type);
  }

  /**
   * Finds out if all cost types are selected
   * @return {boolean}
   */
  areAllTypesChecked(): boolean {
    return this.costTypes.reduce((pv, cv) => pv + (cv.selected ? 1 : 0), 0) === this.costTypes.length;
  }

  /**
   * Called when the check box above all cost type check boxes is checked
   * @param $event
   */
  toggleAllTypesChecked($event: MatCheckboxChange) {
    if ($event.checked) {
      this.costTypes.forEach(costType => costType.selected = true);
    } else {
      this.costTypes.forEach(costType => costType.selected = false);
    }
    this.emitCostTypesSelected();
  }

  private emitCostTypesSelected() {
    this.costTypesSelected.emit(this.costTypes.filter(type => type.selected).map(type => type.costType));
  }
}
