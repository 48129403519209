import {Component, OnDestroy, OnInit} from '@angular/core';
import {TripService} from '../../../services/trip.service';

import {DriverService} from '../../../services/driver.service';
import {CarService} from '../../../services/car.service';
import {Profile, PurposeType, Trip, User} from 'squaretrip-ts-model';
import {SpinnerService} from '../../../services/spinner.service';
import {TripHelper} from '../../../helpers/trip/trip-helper';
import {LoginService} from '../../../services/login.service';
import {Subject} from 'rxjs/Subject';
import {map, take, takeUntil} from 'rxjs/operators';
import {ManualTripDialogComponent} from '../manual-trip-dialog/manual-trip-dialog.component';
import {MatCheckboxChange, MatDialog} from '@angular/material';
import {ConfigService, SquareTripProductType} from '../../../services/config.service';
import {Observable, of} from '../../../../../node_modules/rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-driving-log-overview',
  templateUrl: './driving-log-overview.component.html',
  styleUrls: ['./driving-log-overview.component.scss']
})
export class DrivingLogOverviewComponent implements OnInit, OnDestroy {
  allTrips: Trip[] = [];
  filteredTrips: Trip[] = [];
  selectedTrip: Trip = null;
  filterCars: Profile[] = [];
  filterDrivers: User[] = [];
  availableCars: Profile[] = [];
  availableDrivers: User[] = [];
  filterStartDate = new Date(+new Date() - 31 * 24 * 60 * 60 * 1000);
  filterEndDate = new Date(+new Date() + 1);
  private currentUser: User;
  ALL_PURPOSES = [PurposeType.PURPOSE_BUSINESS,
    PurposeType.PURPOSE_PRIVATE,
    PurposeType.PURPOSE_WAY_TO_WORK,
    PurposeType.UNDEFINED];
  filterPurposes: PurposeType[] = Object.assign([], this.ALL_PURPOSES);
  Purposes = PurposeType;
  private destroyed$ = new Subject();
  productType: SquareTripProductType;

  constructor(private tripService: TripService,
              private driverService: DriverService,
              private spinnerService: SpinnerService,
              private configService: ConfigService,
              private translate: TranslateService,
              private carService: CarService,
              private tripHelper: TripHelper,
              private dialog: MatDialog,
              private loginService: LoginService) {
    this.productType = this.configService.getProductType();
    this.loginService.loadUser().subscribe(user => this.currentUser = user);
    this.loadTrips();
    this.carService.getCars().subscribe(cars => this.availableCars = cars);
    this.driverService.getDrivers().subscribe(drivers => this.availableDrivers = drivers);
    this.tripService.tripsChanged$.pipe(takeUntil(this.destroyed$)).subscribe(() => this.loadTrips());

  }

  ngOnInit() {
    this.spinnerService.showSpinner();
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  private loadTrips() {
    this.tripService.loadTrips(+this.filterStartDate, (+this.filterEndDate + 24 * 60 * 60 * 1000)).subscribe(trips => {
      this.allTrips = trips;


      this.filter();

      if (trips.length > 0 && this.selectedTrip == null) {
        this.selectedTrip = this.filteredTrips[0];
      }

      this.spinnerService.hideSpinner();
    });
  }

  tripSelected(trip: Trip) {
    this.selectedTrip = trip;
  }

  onCarsPickedForFilter(cars: Profile[]) {
    this.filterCars = cars;
    this.filter();
  }

  onDriversPickedForFilter(drivers: User[]) {
    this.filterDrivers = drivers;
    this.filter();
  }

  onStartDatePicked(startDate: Date) {
    this.filterStartDate = startDate;
    this.loadTrips();
  }

  onEndDatePicked(endDate: Date) {
    this.filterEndDate = endDate;
    this.loadTrips();
  }

  onPurposeChangedForFilter(purposeType: PurposeType, $event: MatCheckboxChange) {
    const currentIndex = this.filterPurposes.indexOf(purposeType);
    if ($event.checked && currentIndex === -1) {
      this.filterPurposes.push(purposeType);
    } else if (!$event.checked && currentIndex > -1) {
      this.filterPurposes.splice(currentIndex, 1);
    }
    this.filter();
  }

  onAllPurposesChangedForFilter($event: MatCheckboxChange) {
    if ($event.checked) {
      this.filterPurposes = Object.assign([], this.ALL_PURPOSES);
    } else {
      this.filterPurposes = [];
    }
    this.filter();
  }

  isPurposeSelectedForFilter(purpose: PurposeType): boolean {
    return this.filterPurposes.indexOf(purpose) > -1;
  }

  getNumberOfUncategorizedTrips() {
    return this.allTrips.filter(trip => !trip.purpose || trip.purpose.category === PurposeType.UNDEFINED).length;
  }

  private filter() {
    this.filteredTrips = this.allTrips.filter(trip => {
      return this.filterDrivers.findIndex(driver => driver.username === trip.username) > -1;
    });
    if (this.filterCars.length < this.availableCars.length) {
      this.filteredTrips = this.filteredTrips.filter(trip => {
        /*if (!trip.profile || trip.profile.id == null) {
          return false;
        }*/
        return this.filterCars.findIndex(car => trip.profile && car.id === trip.profile.id) > -1;
      });
    }
    this.filteredTrips = this.filteredTrips.filter(trip => {
      if (trip.purpose && trip.purpose.category) {
        return this.filterPurposes.indexOf(trip.purpose.category) > -1;
      } else {
        // trip has no purpose object? take it as undefined
        return this.filterPurposes.indexOf(PurposeType.UNDEFINED) > -1;
      }
    });
  }

  isTripPrivate(trip: Trip): boolean {
    return TripHelper.isTripPrivate(trip, this.currentUser);
  }

  openManualTripDialog(): void {
    this.dialog.open(ManualTripDialogComponent);
  }

  getDriverName(trip: Trip): string {
    return this.tripHelper.getDriverName(trip);
  }

  /**
   * Based on selected purposes, builds a filter title
   */
  buildPurposeFilterTitle(): Observable<string> {
    if (this.filterPurposes.length === this.ALL_PURPOSES.length) {
      return of('trip.purpose.all');
    } else if (this.filterPurposes.length === 0) {
      return of('trip.purpose.none');
    } else {
      return this.translate.get('trip.purpose')
        .pipe(
          take(1),
          map(purposes => {
            let text = '';
            this.filterPurposes.forEach(filteredPurpose => {
              if (filteredPurpose === PurposeType.PURPOSE_BUSINESS) {
                text += purposes.business;
              } else if (filteredPurpose === PurposeType.PURPOSE_PRIVATE) {
                text += purposes.private;
              } else if (filteredPurpose === PurposeType.PURPOSE_WAY_TO_WORK) {
                text += purposes.way_to_work;
              } else {
                text += purposes.undefined;
              }
              text += ', ';
            });
            // remove trailing ", "
            text = text.substr(0, text.length - 2);
            return text;
          }));
    }
  }
}
