import {Component, Input, OnInit} from '@angular/core';
import {Profile, FleetProfileStatus} from 'squaretrip-ts-model';

@Component({
  selector: 'app-car-status-icon',
  templateUrl: './car-status-icon.component.html',
  styleUrls: ['./car-status-icon.component.css']
})
export class CarStatusIconComponent implements OnInit {

  FleetProfileStatus = FleetProfileStatus;
  @Input() car: Profile;

  constructor() { }

  ngOnInit() {
  }

}
