import {Component, Input, OnInit} from '@angular/core';
import {EventService} from '../../../services/event.service';
import {Observable} from 'rxjs/Observable';
import {EventLocalizationHelper} from '../../../helpers/event-localization-helper';
import {EventType, EventStatus, EventAction, Profile, Event} from 'squaretrip-ts-model';
import {MatDialog} from '@angular/material';
import {CarEventConversationComponent, ICarEventConversationData} from '../car-event-conversation/car-event-conversation.component';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit {

  EventType = EventType;
  EventStatus = EventStatus;
  EventAction = EventAction;

  @Input() events: Event[] = [];
  @Input() show: 'driver' | 'car' = 'driver';

  constructor(private eventService: EventService,
              private dialog: MatDialog) {}

  ngOnInit() {
  }

  getDriverNameForEvent(event: Event, type: 'from' | 'to'): Observable<string> {
    return this.eventService.getDriverNameForEvent(event, type);
  }

  getTitleForEventAction(action: EventAction): string {
    return EventLocalizationHelper.eventActionToString(action);
  }

  getTitleForEventStatus(status: EventStatus): string {
    return EventLocalizationHelper.eventStatusToString(status);
  }

  getTitleForEventType(type: EventType): string {
    return EventLocalizationHelper.eventTypeToString(type);
  }

  getCarNameForEvent(event: Event): Observable<string> {
    return this.eventService.getCarNameForEvent(event);
  }

  onCloseEventClicked(event: Event) {
    if (confirm('Sind Sie sich sicher, dass Sie das Ereignis schließen möchten?')) {
      event.status = EventStatus.Closed;
      event.closedTime = +new Date();
      this.eventService.saveEvent(event).subscribe();
    }
  }

  openDetailViewForCar(car: Profile, $event) {
    $event.preventDefault();
    this.dialog.open(CarEventConversationComponent, {
      height: '90%',
      width: '50%',
      data: {profileId: car.id} as ICarEventConversationData
    });
  }


}
