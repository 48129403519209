<div class="sq-content">
  <h1 *ngIf="!alreadySubmitted">SquareTrip Fleet-Kunde werden</h1>
  <h1 *ngIf="alreadySubmitted">Zahlungsdaten</h1>
  <div class="inner-content" *ngIf="!success && alreadySubmitted && !overrideAlreadySubmitted">
    Sie haben bereits ein SEPA-Mandat angelegt. Wenn Sie Ihre Zahlungsdaten ändern möchten, können Sie ein ein neues
    Mandat anlegen:<br/><br/>
    <button mat-raised-button (click)="overrideAlreadySubmitted = true">Neues SEPA-Mandat anlegen</button>
  </div>
  <div class="inner-content" *ngIf="!success && !(alreadySubmitted && !overrideAlreadySubmitted)">
    Bitte geben Sie hier Ihre Bankdaten ein. Wir werden monatlich von Ihrem Konto den Betrag von
    {{ price }} EUR (zzgl. MwSt) pro Fahrzeug abbuchen, das zum Ende des Vormonats angelegt war.<br/><br/>
    Nachdem Sie das Formular abgesendet haben, werden wir Ihr Konto innerhalb von einem Werktag
    aktualisieren. Sie erhalten dadurch Zugriff auf alle Features.<br/><br/>
    <form #form="ngForm" (submit)="submit()">
      <mat-form-field>
        <input matInput placeholder="Kontoinhaber"
               required
               name="accountHolder"
               [(ngModel)]="paymentData.accountHolder.name"/>
        <mat-error>{{'buy.errors.generic' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <textarea matInput placeholder="Adresse des Kontoinhabers"
                  required
                  name="accountHolderAddress"
                  [(ngModel)]="paymentData.accountHolder.address"></textarea>
        <mat-error>{{'buy.errors.generic' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="IBAN"
               required
               name="iban"
               [(ngModel)]="paymentData.iban"/>
        <mat-error>{{'buy.errors.generic' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Name der Bank"
               required
               name="bankName"
               [(ngModel)]="paymentData.bankName"/>
        <mat-error>{{'buy.errors.generic' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="BIC"
               required
               name="bic"
               [(ngModel)]="paymentData.bic"/>
        <mat-error>{{'buy.errors.generic' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Datum"
               required
               name="date"
               readonly [value]="paymentData.date | date:'shortDate'"/>
        <mat-error>{{'buy.errors.generic' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Ort"
               required
               name="place"
               [(ngModel)]="paymentData.place"/>
        <mat-error>{{'buy.errors.generic' | translate}}</mat-error>
      </mat-form-field>
      <div class="around-checkbox">
        <mat-checkbox [(ngModel)]="authorized"
                      name="authorized"
                      required></mat-checkbox>
        <div>Ich autorisiere a) HipSquare, Zahlungen von meinem Bankkonto mittels Lastschrift
          einzuziehen und weise b) mein Kreditinstitut an, die von HipSquare gezogenen Lastschriften
          einzulösen. Sie können innerhalb von acht Wochen ab dem Abbuchungsdatum die Erstattung des abgebuchten
          Betrags verlangen. Es gelten dabei die mit Ihrer Bank vereinbarten Bedingungen. Ihre Rechte sind
          auf einem Merkblatt enthalten, das Sie von Ihrer Bank erhalten können.
        </div>
      </div>
      <br/><br/>
      <button
        type="submit"
        [disabled]="form.invalid"
        mat-raised-button
        color="primary">Jetzt bestellen
      </button>
    </form>
  </div>
  <div *ngIf="success">
    Vielen Dank, dass Sie SquareTrip Fleet-Kunde werden. Wir bestätigen Ihren Auftrag innerhalb eines Werktages und
    aktualisieren Ihre Kontoeinstellungen entsprechend.
  </div>
</div>
