<div class="login-page-style wrapper">

  <div class="box mat-elevation-z6">
    <div class="around-logo">
      <div class="squaretrip-logo"></div>
    </div>
    <h1>{{'login.title' | translate}}</h1>
    <h2>{{'login.subtitle' | translate}}</h2>
    <div class="intro" *ngIf="productType === 'fleet'">
      <img
        alt="SquareTrip Fleet"
        src="assets/login/phone.png" />
      <div style="flex-direction: column; margin-left: 40px; justify-content: space-between ">
        <div [innerHTML]="'login.intro' | translate | safeHtml"></div>
        <button class="cta" [routerLink]="['/register']">{{'login.register_cta' | translate}}</button>
      </div>
    </div>
    <hr style="margin-top: 40px"/>
    <h2>{{'login.login_title' | translate}}</h2>
    <form (submit)="login($event)">
      <mat-form-field>
        <input type="email" matInput placeholder="{{'login.email' | translate}}" [(ngModel)]="email" name="email"/>
      </mat-form-field>
      <mat-form-field>
        <input type="password" matInput placeholder="{{'login.password' | translate}}" [(ngModel)]="password"
               name="password"/>
      </mat-form-field>
      <button mat-button type="submit" [disabled]="!enabled">{{'action.login' | translate}}</button>
      <br/>
      <ng-container *ngIf="productType === 'driving-log'">
        <small [innerHTML]="'login.pro_text' | translate | safeHtml"></small>
        <br/>
        <br/>

        <a style="display: inline" onclick="gtag('event', 'click_play_store');" href="http://play.google.com/store/apps/details?id=com.hipsquare.squaretrip.gps" target="_blank" markdown="1"><img alt="Play Store Logo" src="https://squaretrip.net/user/pages/02.fahrtenbuch/01._stage-2/_vertical/_tttttt/02._content/google-play-badge.png"></a>
        &nbsp;
        <a style="display: inline" onclick="gtag('event', 'click_app_store');" href="https://itunes.apple.com/de/app/squaretrip-fahrtenbuch/id1276166692" target="_blank" markdown="1"><img alt="App Store Logo" src="https://squaretrip.net/user/pages/02.fahrtenbuch/01._stage-2/_vertical/_tttttt/02._content/app-store-badge.png"></a>

        <br/><br/>
      </ng-container>
      <mat-error *ngIf="error && !errorLabel" [innerHTML]="'login.error' | translate | safeHtml"></mat-error>
      <mat-error *ngIf="errorLabel" [innerHTML]="errorLabel | translate | safeHtml"></mat-error>

      <br/>
      <a href="#" [routerLink]="['/request-password-reset']">{{'action.request_password_reset' |
        translate}}</a><br/><br/>
      <ng-container *ngIf="productType === 'fleet'">
        <a href="#" [routerLink]="['/register']">{{'action.register' | translate}}</a>
      </ng-container>
    </form>
  </div>
  <div class="footer"></div>
</div>
