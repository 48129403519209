import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Trip, User} from 'squaretrip-ts-model';
import {AddressPickerComponent} from '../address-picker/address-picker.component';
import {TripService} from '../../../services/trip.service';
import {Subject} from 'rxjs/Subject';
import {DriverService} from '../../../services/driver.service';
import {takeUntil} from 'rxjs/operators';
import {ManualTripDialogComponent} from '../manual-trip-dialog/manual-trip-dialog.component';
import * as moment from 'moment';


@Component({
  selector: 'app-edit-locations',
  templateUrl: './edit-trip-dialog.component.html',
  styleUrls: ['./edit-trip-dialog.component.css']
})
export class EditTripDialogComponent implements OnInit, OnDestroy {

  @ViewChild('startAddressPicker') startAddressPicker: AddressPickerComponent;
  @ViewChild('endAddressPicker') endAddressPicker: AddressPickerComponent;

  trip: Trip;
  private destroyed$ = new Subject();
  startAddress: string;
  endAddress: string;
  startTime: string;
  endTime: string;
  distance: number;
  drivers: User[];

  constructor(@Inject(MAT_DIALOG_DATA) dialogData: Trip,
              private dialogRef: MatDialogRef<EditTripDialogComponent>,
              private driverService: DriverService,
              private tripService: TripService) {
    this.trip = dialogData;
    this.startAddress = this.trip.correctedStartAddress || this.trip.startAddress;
    this.endAddress = this.trip.correctedEndAddress || this.trip.endAddress;
    this.startTime = this.generateTimeString(this.trip.startTime);
    this.endTime = this.generateTimeString(this.trip.endTime);
    this.distance = Math.round(this.trip.distance / 1000 * 100) / 100;
  }

  private generateTimeString(timestamp: number): string {
    const date = new Date(timestamp);
    const hours = ManualTripDialogComponent.prependZero(`${date.getHours()}`);
    const minutes = ManualTripDialogComponent.prependZero(`${date.getMinutes()}`);
    return `${hours}:${minutes}`;
  }

  ngOnInit() {
    this.driverService
      .getDrivers()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(drivers => this.drivers = drivers);
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  save() {
    this.trip.correctedStartAddress = this.startAddress;
    this.trip.correctedEndAddress = this.endAddress;
    this.trip.correctedStartLocation = this.startAddressPicker.getLocation();
    this.trip.correctedEndLocation = this.endAddressPicker.getLocation();
    this.trip.distance = this.distance * 1000;
    this.trip.endMileage = this.trip.startMileage + this.trip.distance;
    this.trip.lastManipulated = +new Date();

    const rx = /([0-9]{1,2}):([0-9]{1,2})/;

    if (this.startTime) {
      const startDate = moment(this.trip.startTime).startOf('day');
      const startTimeParts = this.startTime.match(rx) || [];
      startDate.add(startTimeParts[1], 'hours');
      startDate.add(startTimeParts[2], 'minutes');
      const startTimestamp = +startDate;
      this.trip.startLocation.time = startTimestamp;
      this.trip.startTime = startTimestamp;
    }

    if (this.endTime) {
      const endDate = moment(this.trip.endTime).startOf('day');
      const endTimeParts = this.endTime.match(rx) || [];
      endDate.add(endTimeParts[1], 'hours');
      endDate.add(endTimeParts[2], 'minutes');
      const endTimestamp = +endDate;
      this.trip.endLocation.time = endTimestamp;
      this.trip.endTime = endTimestamp;
    }

    this.tripService.saveTrip(this.trip, true).subscribe(() => this.dialogRef.close());
  }



}
