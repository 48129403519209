<div class="table">
  <div class="row50percent">
    <div class="col th">
      <mat-checkbox [checked]="areAllTypesChecked()" (change)="toggleAllTypesChecked($event)"></mat-checkbox>
    </div>
    <div class="col th">
      {{'cost.single_type' | translate}}
    </div>
    <div class="col th sum">
      {{'cost.amount_in' | translate}} EUR
    </div>
  </div>
  <ng-container *ngFor="let type of costTypes">
    <div class="row50percent" [ngClass]="{'selected': type.selected}" (click)="costSelectionChanged(type, $event)">
      <div class="col check">
        <mat-checkbox [checked]="type.selected" (change)="costSelectionChanged(type, $event)"></mat-checkbox>
      </div>
      <div class="col">
        {{costTypeToString(type.costType) | translate}}
      </div>
      <div class="col sum">
        {{type.sum | number:'1.2-2'}}
      </div>
    </div>
  </ng-container>
</div>
