import {Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {EventService} from '../../../services/event.service';
import {CarService} from '../../../services/car.service';
import {LoginService} from '../../../services/login.service';
import {Subscription} from 'rxjs/Subscription';
import {Profile, Event, EventCategory, EventType, EventStatus, EventAction} from 'squaretrip-ts-model';
import {Subject} from 'rxjs/Subject';
import {SnackBarService} from '../../../services/snack-bar.service';
import {flatMap, map, switchMap} from 'rxjs/operators';
import {pipe} from 'rxjs/internal-compatibility';

export interface ICarEventConversationData {
  profileId: number;
}

@Component({
  selector: 'app-car-event-conversation',
  templateUrl: './car-event-conversation.component.html',
  styleUrls: ['./car-event-conversation.component.scss']
})
export class CarEventConversationComponent implements OnInit, OnDestroy {
  @ViewChild('messages') messagesDiv: ElementRef;

  /**
   * Emits when the user clicked "create event" on an existing event
   * @type {EventEmitter<Event>}
   */
  @Output() createEventClicked = new EventEmitter<Event>();

  private _profileId: number;
  @Input() set profileId(value: number) {
    this._profileId = value;

    this.profileIdObservable.next(value);
  }

  get profileId(): number {
    return this._profileId;
  }

  /**
   * Observable to watch whether profile ID has changed
   * @type {Subject<number>}
   */
  private profileIdObservable = new Subject<number>();

  events: Event[] = [];
  car: Profile;
  message = '';
  subscriptions = new Subscription();

  private reloadObservable: Subscription;

  constructor(private eventService: EventService,
              private carService: CarService,
              private loginService: LoginService,
              private snackbarService: SnackBarService) {
    this.subscriptions.add(this.profileIdObservable
      .pipe(flatMap(profileId => this.carService.getCar(profileId)))
      .subscribe(car => {
        this.car = car;
      })
    );
    this.loadEvents();

  }

  ngOnInit() {
  }

  loadEvents() {
    this.reloadObservable = this.profileIdObservable
      .pipe(switchMap(profileId => this.eventService.getEventsForCarWithInterval(profileId)),
        map(events => events.filter((event: Event) => event.type === EventType.Info || event.type === EventType.Warning)),
        map(events => {
          events.sort((a, b) => a.dueTime > b.dueTime ? -1 : 1);
          return events;
        }))
      .subscribe(events => {
        // scroll down the messages list if there is a new message
        const shouldScrollDown = events.length > this.events.length;
        this.events = Object.assign([], events);
        if (shouldScrollDown) {
          setTimeout(() => {
            this.messagesDiv.nativeElement.scrollTo(0, this.messagesDiv.nativeElement.scrollHeight);
          }, 0);
        }
      });
    this.subscriptions.add(this.reloadObservable);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onCreateEventClicked(event: Event) {
    this.createEventClicked.emit(event);
  }

  onDeleteEventClicked(event: Event) {
    this.eventService.deleteEvent(event).subscribe(() => {
      this.snackbarService.showMessage('event_overview.success_deleted');
      this.events.splice(this.events.findIndex(e => e.id === event.id), 1);
    });
  }

  private sendMessage() {
    if (this.message === '') {
      return;
    }
    const event = new Event();
    event.forProfile = this.car;
    event.fromUsername = this.loginService.user$.getValue().username;
    event.category = EventCategory.Car;
    event.type = EventType.Info;
    event.dueTime = +new Date();
    event.status = EventStatus.Open;
    event.title = this.message;
    this.eventService.saveEvent(event).subscribe();
    this.message = '';
  }

  onKeyPress($event) {
    if ($event.keyCode === 13) {
      this.sendMessage();
    }
  }
}
