<div class="header mat-elevation-z2">
  <app-client-logo></app-client-logo>

  <div class="header-content">

    <div class="title">
      <h1>{{'event_overview.title' | translate}}</h1>
      <p>{{'event_overview.intro' | translate}}</p>
    </div>
    <app-filter-bar
      #filterBar
      [availableDrivers]="drivers"
      (driversPicked)="onFilterDriversPicked($event)"
      [allAvailableCars]="cars"
      (carsPicked)="onFilterCarsPicked($event)"
      [showDates]="false"
    >

      <app-filter-bubble [icon]="'add'" [text]="'event_overview.cta_new_event' | translate"
                         (click)="doShowNewEventForm()"></app-filter-bubble>

      <mat-checkbox (change)="onChangeFilterShowClosedEvents($event)" style="color: #fff">
        {{'event_overview.filter.show_closed' | translate}}
      </mat-checkbox>
      &nbsp;&nbsp;&nbsp;


    </app-filter-bar>
  </div>
</div>

<div class="subheader mat-elevation-z1" [@showEditForm]="showNewEventForm">
  <h2>{{'event_overview.form.title' | translate}}</h2>
  <form (submit)="saveEvent()" #form="ngForm">

    <!--start category selection (car/car) -->
    <mat-form-field>
      <mat-select [(ngModel)]="eventToEdit.category"
                  name="category"
                  [placeholder]="'event.categories.label' | translate"
                  matInput>
        <mat-option [value]="EventCategory.Car">
          {{'event.categories.car' | translate}}
        </mat-option>
        <mat-option [value]="EventCategory.Driver">
          {{'event.categories.driver' | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- /end category selection -->

    <!-- start car selection -->
    <mat-form-field *ngIf="eventToEdit.category === EventCategory.Driver">
      <mat-select
        [compareWith]="compareDrivers"
        matInput
        [placeholder]="'driver.title' | translate"
        [(ngModel)]="eventToEdit.toUsername"
        name="to_username">
        <mat-option *ngFor="let driver of drivers" [value]="driver.username">
          {{driver.firstName}} {{driver.lastName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- /end car selection -->

    <!-- start car selection -->
    <mat-form-field *ngIf="eventToEdit.category === EventCategory.Car">
      <mat-select
        [compareWith]="compareCars"
        matInput
        [placeholder]="'car.title' | translate"
        [(ngModel)]="eventToEdit.forProfile"
        name="for_profile">
        <mat-option *ngFor="let car of cars" [value]="car">
          {{car.licensePlate}} ({{car.name}})
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- /end car selection -->


    <!-- start event title -->
    <mat-form-field>
      <input matInput [placeholder]="'event.title.label' | translate"
             [(ngModel)]="eventToEdit.title"
             name="title"
      />
    </mat-form-field>
    <!-- /end event title -->

    <!-- start due date -->
    <mat-form-field *ngIf="eventToEdit.type === EventType.Task">
      <input matInput placeholder="{{'event.due_on' | translate}}"
             name="due"
             [(ngModel)]="eventToEditDueTime" [matDatepicker]="datePickerDue"/>
      <mat-datepicker-toggle matSuffix [for]="datePickerDue"></mat-datepicker-toggle>
      <mat-datepicker matSuffix #datePickerDue></mat-datepicker>
    </mat-form-field>
    <!-- /end due date -->

    <button mat-button [disabled]="form.invalid" type="submit">{{'action.save' | translate}}</button>
  </form>
</div>

<div class="sq-content">
  <div class="left" *ngIf="carEvents.length > 0 && carsWithChatEvents.length > 0">
    <mat-card class="no-whitespace">
      <mat-card-content>
        <app-car-comments-overview
          (createEventClicked)="onCreateEventFromExistingEventClicked($event)"></app-car-comments-overview>
      </mat-card-content>

    </mat-card>
  </div>
  <div class="right" *ngIf="driverEvents.length > 0 || carEvents.length > 0">
    <mat-card *ngIf="driverEvents.length > 0">
      <mat-card-title>{{'driver.title_plural' | translate}}</mat-card-title>
      <mat-card-content>
        <app-event-list [events]="driverEvents"></app-event-list>
      </mat-card-content>
    </mat-card>
    <mat-card *ngIf="carEvents.length > 0">
      <mat-card-title>{{'car.title_plural' | translate}}</mat-card-title>
      <mat-card-content>
        <app-event-list [events]="carEvents" [show]="'car'"></app-event-list>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="empty-message" *ngIf="filteredEvents.length === 0">
    {{'event_overview.empty-message' | translate}}
  </div>
</div>
