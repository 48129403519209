import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-multi-debug',
  templateUrl: './multi-debug.component.html',
  styleUrls: ['./multi-debug.component.css']
})
export class MultiDebugComponent implements OnInit {

  count = [0];

  constructor() { }

  ngOnInit() {
  }

  addView() {
    this.count.push(this.count.length);
  }

}
