import {Injectable} from '@angular/core';
import {LoginService} from './login.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class AuthenticatedHttpService {

  constructor(private loginService: LoginService,
              private http: HttpClient) {
  }

  public static createAuthorizationHeaderFromJwt(token: string) {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', token)
      .append('AsAdmin', 'true');
    return {headers: headers};
  }


  public get(url: string, options?: any): Observable<any> {
    const newOptions = {... options, ... AuthenticatedHttpService.createAuthorizationHeaderFromJwt(this.loginService.token)};
    return this.http.get(url, newOptions);
  }

  public post(url: string, body: any, options?: any): Observable<any> {
    const newOptions = {... options, ... AuthenticatedHttpService.createAuthorizationHeaderFromJwt(this.loginService.token)};
    return this.http.post(url, body, newOptions);
  }

  public put(url: string, body: any, options?: any): Observable<any> {
    const newOptions = {... options, ... AuthenticatedHttpService.createAuthorizationHeaderFromJwt(this.loginService.token)};
    return this.http.put(url, body, newOptions);
  }

  public delete(url: string, options?: any): Observable<any> {
    const newOptions = {... options, ... AuthenticatedHttpService.createAuthorizationHeaderFromJwt(this.loginService.token)};
    return this.http.delete(url, newOptions);
  }



}
