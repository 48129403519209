import {MatIconRegistry} from '@angular/material';
import {DomSanitizer} from '@angular/platform-browser';

export class IconHelper {
  static icons = [
    {
      name: 'insurance',
      url: 'assets/icons/cost_type_insurance.svg'
    }
  ];
  static registerIcons(registry: MatIconRegistry, sanitizer: DomSanitizer) {
    IconHelper.icons.forEach(icon => {
      registry.addSvgIcon(icon.name, sanitizer.bypassSecurityTrustResourceUrl(icon.url));
    });
  }
}
