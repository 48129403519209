import {IImporter, Mapping} from './IImporter';
import * as XLSX from 'xlsx';
import {WorkBook, WorkSheet} from 'xlsx';

/**
 * A class that helps to import Excel files
 */
export class ExcelImporter<ItemType> implements IImporter<ItemType> {
  private workbook: WorkBook;

  generateItems(sheetName: string, mapping: Mapping, createItem: () => ItemType): ItemType[] {
    const range = XLSX.utils.decode_range(this.workbook.Sheets[sheetName]['!ref']);
    const startRow = range.s.r;
    const endRow = range.e.r;
    const items: ItemType[] = [];
    if (endRow > 0) {
      for (let row = startRow + 1; row <= endRow; row++) {
        const car = createItem();
        for (let j = 0; j < Object.keys(mapping).length; j++) {
          const key = Object.keys(mapping)[j];
          const titleCell = mapping[key];
          const cellReference = Object.assign({}, XLSX.utils.decode_cell(titleCell), {r: row});
          const cell = this.workbook.Sheets[sheetName][XLSX.utils.encode_cell(cellReference)];
          let value = '';

          // check if cell exists and therefore has content
          if (cell != null) {
            value = cell.v;
          }
          car[key] = value;
        }
        items.push(car);
      }
    }
    return items;
  }

  getSheetHeadlines(sheetName: string): {reference: string, label: string}[] {
    const range = XLSX.utils.decode_range(this.workbook.Sheets[sheetName]['!ref']);
    const startCol = range.s.c;
    const endCol = range.e.c;
    const headlineCells: {reference: string, label: string}[] = [];
    for (let i = startCol; i <= endCol; i++) {
      const ref = XLSX.utils.encode_cell({r: 0, c: i});
      headlineCells.push({
        reference: ref,
        label: this.workbook.Sheets[sheetName][ref].v
      });
    }
    return headlineCells;
  }

  getSheetNames(): string[] {
    return this.workbook.SheetNames;
  }

  loadFile(file: File, callback: () => void) {
    const fileReader = new FileReader();
    fileReader.onload = (e: ProgressEvent) => {
      const text = (e.target as FileReader).result;
      this.workbook = XLSX.read(text, {type: 'binary'});
      callback();
    };
    fileReader.readAsBinaryString(file);
  }


}
