import {Component, Input, OnInit} from '@angular/core';
import {Cost} from 'squaretrip-ts-model';

@Component({
  selector: 'app-cost-sum-widget',
  templateUrl: './cost-sum-widget.component.html',
  styleUrls: ['./cost-sum-widget.component.css']
})
export class CostSumWidgetComponent implements OnInit {

  sum = 0;
  _costs: Cost[] = [];
  @Input() set costs(value: Cost[]) {
    this._costs = value;
    this.sum = value.reduce((pv, cv) => pv += cv.sumGross, 0);
  }

  constructor() { }

  ngOnInit() {
  }

}
