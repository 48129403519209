<div class="sum-overview">
  <div class="sum-entry">
    <h4>
      {{carsInUse}} {{'cars_in_use.of' | translate}} {{cars}}
    </h4><br/>
    <h3>
      {{'cars_in_use.cars_used' | translate}}
    </h3>
  </div>
</div>

