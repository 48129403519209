import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Observable } from 'rxjs/Observable';
import {ClientService} from '../services/client.service';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Subject} from 'rxjs/Subject';
import {take} from 'rxjs/operators';


@Injectable()
export class TrialMonthGuard implements CanActivate {

  constructor(private clientService: ClientService,
              private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    const canActivate = new Subject<boolean>();
    this.clientService.isAllowedToAccess()
      .pipe(take(1))
      .subscribe(iata => {
      if (iata) {
          setTimeout(() => canActivate.next(true), 0);
      } else {
        this.router.navigate(['/prices-packages']);
        setTimeout(() => canActivate.next(false), 0);
      }
    });
    return canActivate;
  }
}
