<ng-container *ngIf="productType === 'fleet'">
  <div
    class="badge free mat-elevation-z3" *ngIf="_car.status !== FleetProfileStatus.IN_USE">
    {{'car.status.FREE' | translate}}
  </div>
  <div
    class="badge active mat-elevation-z3" *ngIf="_car.status === FleetProfileStatus.IN_USE">
    {{'car.status.IN_USE' | translate}}: {{getDriverNameForCar(_car)}}
  </div>
</ng-container>
<mat-card>
  <mat-card-title>{{_car.licensePlate}}</mat-card-title>
  <mat-card-content>
    <div class="name-mileage">
      <div class="name">
        {{'car.name' | translate}}: <strong>{{_car.name}}</strong>
      </div>
      <div class="mileage">
        {{_car.currentMileage / 1000 | number:'1.0-0'}} km
      </div>
    </div>
    <ng-container *ngIf="productType === 'fleet'">
      <br/><br/>
      <app-map [gestures]="'none'" (ready)="onMapReady()"></app-map>
      <br/>
      <br/>
    </ng-container>
  </mat-card-content>
  <mat-card-actions>
    <button mat-icon-button (click)="delete.next(_car)">
      <mat-icon>delete</mat-icon>
    </button>
    <button mat-icon-button (click)="edit.next(_car)">
      <mat-icon>edit</mat-icon>
    </button>
    <div class="center-block"></div>
    <button mat-button
            *ngIf="productType === 'fleet'"
            [routerLink]="['/events']" [queryParams]="{'filterByCar': _car.id}">
      <mat-icon>event</mat-icon>
      Ereignisse
    </button>
  </mat-card-actions>
</mat-card>
