import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output,
  ViewChild
} from '@angular/core';
import {CarService} from '../../../services/car.service';
import {Subscription} from 'rxjs/Subscription';
import {FleetProfileStatus, Profile} from 'squaretrip-ts-model';
import {DriverService} from '../../../services/driver.service';
import {Observable} from 'rxjs/Observable';
import {ReverseGeocodingHelper} from '../../../helpers/reverse-geocoding-helper';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-car-detailed-list',
  templateUrl: './car-detailed-list.component.html',
  styleUrls: ['./car-detailed-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarDetailedListComponent implements OnInit, OnDestroy {

  @ViewChild('scrollContainer') scrollContainer: ElementRef;

  @Output() carSelected = new EventEmitter<Profile>();
  _selectedCar: Profile | null = null;
  private filterSearch = '';
  @Input() set selectedCar(car: Profile) {
    this._selectedCar = car;
    // manually call change detection as we are coming from a non-angular context (gmaps)
    this.changeDetectorRef.detectChanges();

    this.scrollToCar(car);
  }
  get selectedCar(): Profile {
    return this._selectedCar;
  }


  allCars: Profile[] = [];
  filteredCars: Profile[] = [];
  FleetProfileStatus = FleetProfileStatus;


  constructor(private carService: CarService,
              private changeDetectorRef: ChangeDetectorRef,
              private driverService: DriverService) { }

  private subscriptions: Subscription = new Subscription();

  trackByFunction(index: number, item: Profile) {
    return item.id;
  }

  ngOnInit() {
    this.subscriptions.add(this.carService.getCarsWithInterval(5000).subscribe(cars => {
      this.allCars = cars.filter(car => !car.hidden);
      this.filter();
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  setFilterSearch(value: string) {
    this.filterSearch = value;
    this.filter();
  }

  private filter() {
    if (this.filterSearch === '') {
      this.filteredCars = this.allCars;
    } else {
      this.filteredCars = this.allCars.filter(car => car.name.toLowerCase().indexOf(this.filterSearch.toLowerCase()) > -1 ||
        car.licensePlate.toLowerCase().indexOf(this.filterSearch.toLowerCase()) > -1);
    }
    this.changeDetectorRef.detectChanges();
  }


  getDriverNameForProfile(car: Profile): Observable<string> {
    return this.driverService.getDrivers()
      .pipe(map(drivers => {
        const driver = drivers.find(checkDriver => checkDriver.username === car.checkedOutBy);
        if (!driver) {
          return '';
        }
        return `${driver.firstName} ${driver.lastName}`;
      }));
  }

  getAddressForCar(car: Profile) {
    return ReverseGeocodingHelper.geocode(car.location);
  }

  private scrollToCar(car: Profile) {
    // scroll to selected car:
    // 1. find corresponding car's div
    const carDiv = this.scrollContainer.nativeElement.querySelector(`[data-carId="${car.id}"]`);
    if (carDiv == null) {
      return;
    }
    // 2. scroll to it
    this.scrollContainer.nativeElement.scrollTo(0, carDiv.offsetTop);
  }
}
