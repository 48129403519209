import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {RegistrationService} from '../../../services/registration.service';
import {Client, User} from 'squaretrip-ts-model';
import {LoginService, LoginState} from '../../../services/login.service';
import {Router} from '@angular/router';
import {WhiteLabelProperties} from '../../../classes/WhiteLabelProperties';
import {Subscription} from 'rxjs/Subscription';
import {SnackBarService} from '../../../services/snack-bar.service';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit, OnDestroy {

  _email = "";
  private subscription: Subscription = new Subscription();
  get email() {
    return this._email;
  }
  @ViewChild('email') set email(value) {
    this._email = value;
  };
  user = new User();
  client = new Client();
  success = false;
  enabled = true;
  /*
   * Is there a conflict with an existing account's email address?
   */
  conflictError = false;
  acceptTerms = false;
  acceptDataPrivacy = false;
  couponCode = '';

  constructor(private registrationService: RegistrationService,
              private loginService: LoginService,
              private router: Router,
              private snackbar: SnackBarService) {
    this.client.whiteLabelProperties = new WhiteLabelProperties();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  register($event) {
    this.enabled = false;
    this.conflictError = false;
    $event.preventDefault();
    this.user.username = this.user.email;
    // use company name as whitelabel name
    this.client.whiteLabelProperties.name = this.client.name;
    this.registrationService.register(this.user, this.client, this.couponCode).subscribe(result => {
      this.snackbar.showMessage('register.success_message')
      this.loginService.login(this.user.username, this.user.password);
      this.subscription.add(this.loginService.loginState$.subscribe(state => {
        if (state === LoginState.LOGGED_IN) {
          this.router.navigate(['/dashboard']);
        } else {
          this.enabled = true;
        }
      }));
      this.success = true;
    }, error => {
      this.success = false;
      this.conflictError = true;
      this.enabled = true;
    });
  }

}
