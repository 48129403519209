<div class="table">
  <div class="row50percent">
    <div class="col th" *ngIf="show==='driver'">{{'driver.title' | translate}}</div>
    <div class="col th" *ngIf="show==='car'">{{'car.title' | translate}}</div>
    <div class="col th">{{'event.name' | translate}}</div>
    <div class="col th" *ngIf="show==='car'">{{'event.text' | translate}}</div>
    <div class="col th">{{'event.due_on' | translate}}</div>
    <div class="col th">{{'event.status' | translate}}</div>
  </div>
  <div class="row50percent" *ngFor="let event of events">
    <div class="col" *ngIf="show==='driver'">
      <div class="with-type-icon">
        <mat-icon class="type-icon">person</mat-icon>
        <a href="#" [routerLink]="['/drivers']" [queryParams]="{filterByDriver: event.toUsername}">
          {{getDriverNameForEvent(event, 'to') | async}}
        </a>
      </div>
    </div>
    <div class="col with-icon" *ngIf="show==='car'">
      <div class="with-type-icon">
        <mat-icon>directions_car</mat-icon>
        <a href="#" [routerLink]="['/cars']" [queryParams]="{filterByCar: event.forProfile.licensePlate}">
          <span [innerHTML]="getCarNameForEvent(event) | async | safeHtml"></span>
        </a>

      </div>
    </div>
    <div class="col with-icon">
      {{(event.type === EventType.Task && event.action !== EventAction.None ?
      getTitleForEventAction(event.action) :
      getTitleForEventType(event.type)) | translate}}
    </div>
    <div class="col" *ngIf="show === 'car'">{{event.title}}</div>
    <div class="col">{{event.dueTime | date:'shortDate'}}</div>
    <div class="col">
      <ng-container *ngIf="event.status === EventStatus.Open">
        {{'event.statuss.Open' | translate}}
        <button class="circular-button done" style="margin-left: 10px" (click)="onCloseEventClicked(event)">
          <mat-icon>check</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="event.status === EventStatus.Closed">
        <ng-container *ngIf="event.closedTime">
          {{'event.statuss.Closed_at' | translate}}
          {{event.closedTime | date:'shortDate'}}
        </ng-container>
        <ng-container *ngIf="!event.closedTime">
          {{'event.statuss.Closed' | translate}}
        </ng-container>
      </ng-container>
    </div>
  </div>

</div>
