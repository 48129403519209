<div class="sq-content">
  <h1>Fahrzeuge importieren</h1>

  <mat-vertical-stepper>
    <mat-step label="Datei auswählen">
      Wählen Sie hier eine XLSX-Datei aus, um Fahrzeuge zu importieren. Bitte stellen Sie sicher, dass die erste Zeile
      der Arbeitsmappe die Zeilenüberschriften enthält.<br/><br/>
      <input type="file"
             id="file"
             #file
             (change)="onFilePicked($event)"
      />
      <br/><br/>
      <button mat-button color="primary" matStepperNext>Weiter</button>
    </mat-step>
    <mat-step label="Arbeitsmappe auswählen">
      <mat-select [(ngModel)]="selectedSheetName">
        <mat-option *ngFor="let sheetName of sheetNames" [value]="sheetName">
          {{sheetName}}
        </mat-option>
      </mat-select>
      <br/><br/>
      <button mat-button color="primary" matStepperNext>Weiter</button>
    </mat-step>
    <mat-step label="Zuordnung auswählen">
      <div class="table">
        <div class="row50percent">
          <div class="col th">
            Datenfeld in SquareTrip Fleet
          </div>
          <div class="col th">
            Spalte in Arbeitsmappe
          </div>
        </div>
        <div class="row50percent" *ngFor="let mappingConfig of mappingConfigs">
          <div class="col">{{mappingConfig.label | translate}}</div>
          <div class="col">
            <mat-select [(ngModel)]="mapping[mappingConfig.key]" placeholder="{{mappingConfig.label | translate}}">
              <mat-option *ngFor="let headlineCell of headlineCells" [value]="headlineCell.reference">
                {{headlineCell.label}}
              </mat-option>
            </mat-select>
          </div>
        </div>

      </div>

      <br/><br/>
      <button mat-button color="primary" (click)="generateCars()" matStepperNext>Vorschau</button>
    </mat-step>
    <mat-step label="Vorschau">
      <div class="table">
        <div class="row50percent">
          <div class="col th">
            {{'car.name' | translate}}
          </div>
          <div class="col th">
            {{'car.license_plate' | translate}}
          </div>
          <div class="col th">
            {{'car.mileage' | translate}}
          </div>
          <div class="col th">
            {{'car.id_number' | translate}}
          </div>
        </div>
        <div class="row50percent" *ngFor="let car of cars">
          <div class="col">
            {{car.name}}
          </div>
          <div class="col">
            {{car.licensePlate}}
          </div>
          <div class="col">
            {{car.currentMileage / 1000|number:'1.2-2'}}
          </div>
          <div class="col">
            {{car.vehicleId}}
          </div>
        </div>
      </div>
      <button mat-button color="primary" (click)="saveCars()" matStepperNext>Autos anlegen</button>
    </mat-step>
    <mat-step label="Fertig">
      <mat-spinner *ngIf="saving"></mat-spinner>
      <ng-container *ngIf="saved">
        Die Autos wurden erfolgreich angelegt.
      </ng-container>
    </mat-step>


  </mat-vertical-stepper>



</div>
