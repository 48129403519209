<div class="login-page-style wrapper">
  <div class="box">
    <div *ngIf="!done">
      {{'request_password_reset.intro_text' | translate}}<br/><br/>
      <mat-form-field>
        <input matInput
               #f="ngModel"
               email
               required
               [(ngModel)]="email" placeholder="{{'user.email' | translate}}"/>
        <mat-error *ngIf="f.errors?.email">{{'request_password_reset.errors.email_required' | translate}}</mat-error>
      </mat-form-field>

      <mat-error *ngIf="error">
        {{'request_password_reset.errors.no_such_email' | translate}}
      </mat-error>
      <br/>
      <button mat-button color="secondary" [routerLink]="['/']">{{'request_password_reset.cancel' | translate}}</button>
      <button mat-button color="primary" (click)="requestPasswordReset()">{{'request_password_reset.cta' | translate}}</button>
    </div>
    <div *ngIf="done">
      {{'request_password_reset.password_reset_requested' | translate}}
    </div>
  </div>
</div>
