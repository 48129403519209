<div class="login-page-style wrapper">
  <div class="box">

    <div *ngIf="success">
      {{'confirm_account.success' | translate}}
    </div>
    <div *ngIf="error">
      {{'confirm_account.error' | translate}}
    </div>
    <br/><br/>
    <button
      [routerLink]="['/']"
      mat-raised-button
            color="primary">{{ 'action.next' | translate }}</button>
  </div>
</div>
