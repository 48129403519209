<div class="filters">
  <ng-container *ngIf="showDates">
    <app-filter-bubble [icon]="'event'" [text]="filterDateStart | date"
                       (click)="datePickerStart.open()"></app-filter-bubble>
    <input class="datepickerinput" [matDatepicker]="datePickerStart" [(ngModel)]="filterDateStart"/>
    <mat-datepicker #datePickerStart></mat-datepicker>
    <span class="text"><mat-icon>arrow_forward</mat-icon></span>

    <app-filter-bubble [icon]="'event'" [text]="filterDateEnd | date"
                       (click)="datePickerEnd.open()"></app-filter-bubble>
    <input class="datepickerinput" [matDatepicker]="datePickerEnd" [(ngModel)]="filterDateEnd"/>
    <mat-datepicker #datePickerEnd></mat-datepicker>
  </ng-container>

  <!-- start car filter -->
  <ng-container *ngIf="showCars">
    <app-picker-dialog #carPicker (opened)="focusSearchCars('model')">
      <mat-form-field>
        <input #searchCarsByModel matInput
               placeholder="{{'action.search' | translate}}"
               (keyup)="onCarPreFilterEntered('model', $event)"
        />
      </mat-form-field>
      <mat-checkbox (change)="allCarsSelectionSwitched('model', $event)" [checked]="filterCar['model']?.length === preFilteredCars['model']?.length">
        <strong>{{'car.filter.all_models' | translate}}</strong>
      </mat-checkbox>
      <ng-container *ngFor="let car of preFilteredCars['model']">
        <mat-checkbox (change)="carFilterSelectionChanged('model', car, $event)" [checked]="isCarPartOfFilter('model', car)">
          {{car.name}}
        </mat-checkbox>
      </ng-container>
    </app-picker-dialog>
    <app-filter-bubble [icon]="'directions_car'"
                       text="{{filterCar['model']?.length === 0 ? ('car.filter.none' | translate) :
                       filterCar['model']?.length === availableCars['model']?.length ? ('car.filter.all_models' | translate) :
                        getFilteredCarNames('model')}}"
                       appPickerHandle [opensPicker]="carPicker"
    ></app-filter-bubble>
  </ng-container>

  <ng-container *ngIf="showCars">
    <app-picker-dialog #carPicker (opened)="focusSearchCars('licensePlate')">
      <mat-form-field>
        <input #searchCarsByModel matInput
               placeholder="{{'action.search' | translate}}"
               (keyup)="onCarPreFilterEntered('licensePlate', $event)"
        />
      </mat-form-field>
      <mat-checkbox (change)="allCarsSelectionSwitched('licensePlate', $event)" [checked]="filterCar['licensePlate']?.length === preFilteredCars['licensePlate']?.length">
        <strong>{{'car.filter.all' | translate}}</strong>
      </mat-checkbox>
      <ng-container *ngFor="let car of preFilteredCars['licensePlate']">
        <mat-checkbox (change)="carFilterSelectionChanged('licensePlate', car, $event)" [checked]="isCarPartOfFilter('licensePlate', car)">
          {{car.licensePlate}}
        </mat-checkbox>
      </ng-container>
    </app-picker-dialog>
    <app-filter-bubble [icon]="'directions_car'"
                       text="{{filterCar['licensePlate']?.length === 0 ? ('car.filter.none' | translate) :
                       filterCar['licensePlate']?.length === allAvailableCars?.length ? ('car.filter.all' | translate) :
                        getFilteredCarNames('licensePlate')}}"
                       appPickerHandle [opensPicker]="carPicker"
    ></app-filter-bubble>
  </ng-container>

  <!-- /end car filter -->

  <!-- start cars filter -->
  <ng-container *ngIf="showDrivers">

    <app-picker-dialog #driverPicker  (opened)="focusSearchDrivers()">
      <mat-form-field>
        <input #searchDrivers
               matInput placeholder="{{'action.search' | translate}}" (keyup)="onDriverPreFilterEntered($event)"/>
      </mat-form-field>
      <mat-checkbox (change)="allDriversSelectionSwitched($event)"
                    [checked]="filterDriver?.length === preFilteredDrivers?.length">
        <strong>{{'driver.filter.all' | translate}}</strong>
      </mat-checkbox>
      <ng-container *ngFor="let driver of preFilteredDrivers">
        <mat-checkbox (change)="driverFilterSelectionChanged(driver, $event)" [checked]="isDriverPartOfFilter(driver)">
          {{driver.firstName}} {{driver.lastName}}
        </mat-checkbox>
      </ng-container>
    </app-picker-dialog>
    <app-filter-bubble [icon]="'person'"
                       text="{{filterDriver.length === 0 ? ('driver.filter.none' | translate) :
                       filterDriver.length === availableDrivers.length ? ('driver.filter.all' | translate) :
                       getFilteredDriverNames()}}"
                       appPickerHandle [opensPicker]="driverPicker"
    ></app-filter-bubble>
  </ng-container>
  <!-- /end cars filter -->

  <ng-content></ng-content>
</div>
