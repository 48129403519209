import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable()
export class SpinnerService {

  @Output('showSpinner') showSpinner$ = new EventEmitter<any>();
  @Output('hideSpinner') hideSpinner$ = new EventEmitter<any>();

  constructor() { }

  showSpinner() {
    // take the changes out of the change detection cycle
    setTimeout(() => this.showSpinner$.emit(), 0);
  }

  hideSpinner() {
    // take the changes out of the change detection cycle
    setTimeout(() => this.hideSpinner$.emit(), 0);
  }

}
