<h2 mat-dialog-title>{{ 'cars.pick_dialog.title' | translate }}</h2>
  <mat-dialog-content>
    <mat-select [(ngModel)]="selectedDriver" [placeholder]="'cars.pick_dialog.placeholder' | translate">
      <mat-option *ngFor="let driver of drivers" [value]="driver">
        {{ driver.firstName }} {{ driver.lastName }} ({{driver.email}})
      </mat-option>
    </mat-select>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close>{{ 'action.cancel' | translate}}</button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button mat-button [mat-dialog-close]="selectedDriver">{{ 'action.save' | translate}}</button>
  </mat-dialog-actions>
