<div class="dashboard-content sq-content">
  <ng-container *ngIf="cars.length > 0">
    <mat-form-field class="search-box">
      <input type="text" matInput placeholder="{{'action.search' | translate}}" [(ngModel)]="filterSearch"/>
    </mat-form-field>
    <mat-card class="list">
      <app-car-detailed-list #list (carSelected)="onCarSelected($event)"></app-car-detailed-list>
    </mat-card>
    <mat-card class="map-card">
      <mat-card-content>
        <app-all-cars-map #map (carClicked)="onCarSelected($event)"></app-all-cars-map>
      </mat-card-content>
    </mat-card>
  </ng-container>
  <div class="empty-message" *ngIf="cars.length === 0">
    {{'cars-maps-overview.empty-message' | translate}}
  </div>
</div>
