import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MapComponent} from '../../../cars/map/map.component';

@Component({
  selector: 'app-debug-trip',
  templateUrl: './debug-trip.component.html',
  styleUrls: ['./debug-trip.component.css']
})
export class DebugTripComponent implements OnInit {

  @ViewChild(MapComponent) mapComponent: MapComponent;
  @ViewChild('textarea') textarea: ElementRef<HTMLTextAreaElement>;

  constructor() {
  }

  ngOnInit() {
  }

  jsonChanged($event) {
    const inputText = $event.target.value;
    const inputObject = JSON.parse(inputText);

    let trip;
    if (inputObject.locations) {
      trip = inputObject;
      if (!trip.startLocation && trip.locations.length > 0) {
        trip.startLocation = trip.locations[0];
      }
      if (!trip.endLocation && trip.locations.length > 0) {
        trip.endLocation = trip.locations[trip.locations.length - 1];
      }
    } else {
      trip = {
        locations: inputObject.map(coordinate => {
          return {longitude: coordinate[0], latitude: coordinate[1]};
        })
      };


    }

    this.mapComponent.clearMap();
    this.mapComponent.drawTrip(trip, true);
    this.textarea.nativeElement.innerText = JSON.stringify(trip, null, 2);
  }

}
