import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RegistrationService} from '../../../services/registration.service';

@Component({
  selector: 'app-initial-password',
  templateUrl: './initial-password.component.html',
  styleUrls: ['./initial-password.component.css']
})
export class InitialPasswordComponent implements OnInit {

  progress = false;
  done = false;
  error = false;

  username?: string = null;
  token?: string = null;
  password = '';

  acceptedTermsAndConditions = false;

  /**
   * Indiciates whether this initial password is for an admin or for a user. If it is for a user, no login link is displayed on success,
   * but links to download the fleet apps
   * @type {boolean}
   */
  forAdmin = false;

  constructor(private route: ActivatedRoute,
              private registrationService: RegistrationService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.username = params.get('username');
      this.token = params.get('token');
    });
    this.route.queryParamMap.subscribe(params => {
      this.forAdmin = params.get('admin') !== 'false';
    });
  }

  setPassword() {
    this.progress = true;
    this.error = false;
    this.registrationService.resetPassword(this.username, this.token, this.password)
      .subscribe(() => {
        this.done = true;
        this.progress = false;
      }, () => {
        this.done = false;
        this.progress = false;
        this.error = true;
      });
  }

}
