import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Profile} from 'squaretrip-ts-model';
import {CarService} from '../../../services/car.service';
import {ExcelImporter} from '../../../helpers/import/ExcelImporter';
import {Mapping} from '../../../helpers/import/IImporter';
import {MappingConfig} from '../../../helpers/import/MappingConfig';



@Component({
  selector: 'app-car-import',
  templateUrl: './car-import.component.html',
  styleUrls: ['./car-import.component.scss']
})
export class CarImportComponent implements OnInit {

  @ViewChild('file') file: ElementRef;

  saving = false;
  saved = false;

  cars: Profile[] = [];
  sheetNames: string[] = [];
  headlineCells: { reference: string, label: string }[] = [];
  _selectedSheetName = null;
  private excelImporter: ExcelImporter<Profile>;

  get selectedSheetName() {
    return this._selectedSheetName;
  }

  set selectedSheetName(value) {
    this._selectedSheetName = value;
    this.onSelectedSheetName(value);
  }

  mappingConfigs: MappingConfig[] = [
    {
      key: 'licensePlate',
      label: 'car.license_plate'
    },
    {
      key: 'name',
      label: 'car.name'
    },
    {
      key: 'currentMileage',
      label: 'car.mileage'
    },
    {
      key: 'vehicleId',
      label: 'car.id_number'
    }
  ];
  mapping: Mapping = {};
  constructor(private carService: CarService) {
    this.excelImporter = new ExcelImporter();
  }

  ngOnInit() {
  }

  onFilePicked($event) {
    this.excelImporter.loadFile($event.target.files[0], () => {
      this.sheetNames = this.excelImporter.getSheetNames();
      this.selectedSheetName = this.sheetNames[0];
    });
  }

  onSelectedSheetName(value) {
    this.headlineCells = this.excelImporter.getSheetHeadlines(this.selectedSheetName);
  }

  generateCars() {
    this.cars = this.excelImporter.generateItems(this.selectedSheetName,
      this.mapping,
      () => new Profile()
    );
    // fix mileage, as it needs to be multiplied with 1000
    this.cars.forEach(car => car.currentMileage *= 1000);
  }

  private saveCar(index, doneFunction: (() => void)) {
    if (index === this.cars.length) {
      doneFunction();
    } else {
      const car = this.cars[index];
      delete car.id;
      car.lastChanged = +new Date();
      this.carService.postCar(car).subscribe(() => {
        this.saveCar(index + 1, doneFunction);
      });
    }
  }

  saveCars() {
    this.saving = true;
    if (this.cars.length > 0) {
      this.saveCar(0, () => {
        this.saved = true;
        this.saving = false;
      });
    }
  }

}
