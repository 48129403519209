import { Injectable } from '@angular/core';
import {AuthenticatedHttpService} from './authenticated-http.service';
import {BehaviorSubject, Observable, of} from 'rxjs/index';
import {IPurpose, Purpose} from 'squaretrip-ts-model/dist/classes/purpose';
import {ConfigService} from './config.service';
import {map, mergeMap} from 'rxjs/internal/operators';
@Injectable({
  providedIn: 'root'
})
export class PurposeService {

  loaded = false;
  private purposes$ = new BehaviorSubject<Purpose[]>([]);

  constructor(private http: AuthenticatedHttpService,
              private config: ConfigService) { }

  getPurposes(forceReload = false): Observable<Purpose[]> {
    if (this.loaded && !forceReload) {
      return this.purposes$;
    }
    return this.http
      .get(`${this.config.getEnvironment().server_backend}/purpose`)
      .pipe(map(purposes => {
        this.loaded = true;
        this.purposes$.next(purposes);
        return purposes;
      }));
  }

  savePurpose(_purpose: IPurpose): Observable<IPurpose> {
    const purpose = Purpose.fromPojo(_purpose);
    delete (purpose as any)._id;
    (purpose as IPurpose).id = +new Date();
    purpose.updatePrimaryKeyAndName();
    return this.getPurposes()
      .pipe(
        mergeMap((purposes: Purpose[]) => {
          if (purposes.findIndex(checkPurpose => checkPurpose.primaryKey === purpose.primaryKey) > -1) {
            return of(purpose);
          } else {
            return this.http.post(`${this.config.getEnvironment().server_backend}/purpose`, purpose)
              .pipe(map(resultingPurpose => {
                this.getPurposes(true).subscribe();
                return resultingPurpose as IPurpose;
              }));
          }
        })
      );
  }

}
