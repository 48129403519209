import {Injectable} from '@angular/core';
import {AuthenticatedHttpService} from './authenticated-http.service';
import {Observable} from 'rxjs/Observable';


import {DriverService} from './driver.service';

import {combineLatest} from 'rxjs/observable/combineLatest';
import {Profile, TripTypes} from 'squaretrip-ts-model';
import {ConfigService} from './config.service';
import {flatMap, map} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {timer} from 'rxjs/internal/observable/timer';

@Injectable()
export class CarService {
  private cachedProfiles: Profile[];

  constructor(private http: AuthenticatedHttpService,
              private driverService: DriverService,
              private configService: ConfigService) {
  }

  /**
   * Updates the list of allCars every interval ms
   * @param interval
   * @return {Observable<Profile[]>}
   */
  getCarsWithInterval(interval): Observable<Profile[]> {
    let since = 0;
    return timer(0, interval)
      .pipe(flatMap(() => {
        const result = this.getCars(true, since);
        since = +new Date() - interval;
        return result;
      }));
  }

  getCar(profileId: number): Observable<Profile> {
    return this.getCars()
      .pipe(map(cars => {
        return cars.find(checkCar => checkCar.id === profileId);
      }));
  }

  getCars(forceReload = false, since: number = 0): Observable<Profile[]> {

    if (!forceReload && this.cachedProfiles != null) {
      return of(this.cachedProfiles);
    }

    return this.http.get(`${this.configService.getEnvironment().server_backend}/profile?since=${since}`)
      .pipe(map(_profiles => {
        const profiles = _profiles.filter(profile => !profile.hidden);

        if (this.cachedProfiles == null) {
          this.cachedProfiles = profiles;
        } else {
          // merge new profiles into cached profiles
          profiles.forEach(profile => {
            const index = this.cachedProfiles.findIndex(cp => cp.id === profile.id);
            if (index === -1) {
              this.cachedProfiles.push(profile);
            } else {
              this.cachedProfiles.splice(index, 1, profile);
            }
          });
        }

        return this.cachedProfiles;
      }));
  }

  getAllProfiles(forceReload = false, since: number = 0): Observable<Profile[]> {
    return this.getCars(forceReload, since)
      .pipe(
        map ((_profiles) => {
          const profiles = [... _profiles];
          for (const tripTypeIndex of Object.keys(TripTypes)) {
            const tripType = TripTypes[tripTypeIndex];
            // don't include walking or generic car in trip types. no walking as it's not relevant generally,
            // no car as we want to only show the specifically created cars
            if (tripType === TripTypes.TRIP_TYPE_WALKING || tripType === TripTypes.TRIP_TYPE_CAR) {
              continue;
            }

            let isThereAProfileForThisTripType = false;
            for (const profile of profiles) {
              if (profile == null || profile.type == null) {
                continue;
              }
              if (profile.type === tripType && profile.mDefaultProfile) {
                isThereAProfileForThisTripType = true;
                break;
              }

            }
            if (!isThereAProfileForThisTripType) {
              const profile: any = {};
              profile.type = tripType;
              profile.name = '';
              profile.mDefaultProfile = true;
              profiles.push(profile);
            }
          }
          return profiles;
        })
      );

  }

  postCar(car: Profile): Observable<Profile> {
    if (typeof car._currentMileage !== 'undefined') {
      delete car._currentMileage;
    }
    if (typeof car._new_currentMileage !== 'undefined') {
      delete car._new_currentMileage;
    }
    if (typeof car._checkedOutByUserObj !== 'undefined') {
      delete car._checkedOutByUserObj;
    }
    if (car.id === -1) {
      delete car.id;
    }
    return this.http.post(`${this.configService.getEnvironment().server_backend}/profile`, car);
  }

}
