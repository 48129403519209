import {Location} from 'squaretrip-ts-model';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';

declare var google: any;

class AddressObject {
  address: '';
}

// cache observables to avoid unnecessary server calls
const cache = new Map<string, BehaviorSubject<string>>();

export class ReverseGeocodingHelper {
  static geocode(location: Location): Observable<string> {


    const geocoder = new google.maps.Geocoder;


    const index = `${location.latitude}${location.longitude}`;
    if (cache.get(index) != null) {
      return cache.get(index);
    }
    const result = new BehaviorSubject<string>('');
    cache.set(index, result);

    if (!location || !location.latitude || !location.longitude) {
      return result;
    } else {
      geocoder.geocode({location: {lat: location.latitude, lng: location.longitude}}, (results, status) => {
        if (status === 'OK' && results[0]) {
          result.next(results[0].formatted_address);
        } else {
          setTimeout(() => {
            this.geocode(location);
          }, 500);
          cache.delete(index);
        }
      });
    }
    return result;
  }
}
