import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-picker-dialog',
  templateUrl: './picker-dialog.component.html',
  styleUrls: ['./picker-dialog.component.scss']
})
export class PickerDialogComponent implements OnInit {

  opened = false;
  onDocumentClickedListener = this.onDocumentClicked.bind(this);
  @Output('opened') justOpened = new EventEmitter();

  constructor() { }



  onDocumentClicked($event) {
    // check if event was inside a picker
    let currentElement = $event.target;
    let inPicker = false;
    while (currentElement != null) {
      if (currentElement.className != null && currentElement.className.indexOf('picker') > -1) {
        inPicker = true;
        break;
      }
      currentElement = currentElement.parentNode;
    }
    // close picker dialogs if click was not in a picker
    if (!inPicker) {
      this.close();
    }
  }


  open() {
    this.opened = true;
    window.setTimeout(() => {
        document.addEventListener('click', this.onDocumentClickedListener);
    }, 0);
  }

  close() {
    this.opened = false;
    document.removeEventListener('click', this.onDocumentClickedListener);
  }

  toggle() {
    if (!this.opened) {
      this.open();
      this.justOpened.emit();
    } else {
      this.close();
    }
  }

  ngOnInit() {
  }

}
