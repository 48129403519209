import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {AuthenticatedHttpService} from './authenticated-http.service';
import {ConfigService} from './config.service';
import {Client, IPaymentData} from 'squaretrip-ts-model';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {WhiteLabelProperties} from '../classes/WhiteLabelProperties';
import {catchError, flatMap, map} from 'rxjs/operators';
import {empty} from 'rxjs/internal/Observer';
import {of} from 'rxjs/internal/observable/of';


@Injectable()
export class ClientService {

  private TRIAL_SKU = 'fleet_trial';
  private RUN_TIME_TRIAL = 30 * 24 * 60 * 60 * 1000;
  private client$: BehaviorSubject<Client> = new BehaviorSubject<Client>(null);

  constructor(private http: AuthenticatedHttpService, private config: ConfigService) { }

  getClient(forceReload = false): Observable<Client | null> {
    if (this.config.getProductType() !== 'fleet') {
      return of(null);
    }
    if (this.client$.getValue() == null || forceReload) {
      return this.http.get(this.config.getEnvironment().server_authentication + '/client')
        .pipe(map((client: Client) => {
          if (client == null) {
            return null;
          }
          if (!client.whiteLabelProperties) {
            client.whiteLabelProperties = new WhiteLabelProperties();
          }
          const whiteLabelProperties = client.whiteLabelProperties as WhiteLabelProperties;
          if (whiteLabelProperties.enableUvv == null) {
            whiteLabelProperties.enableUvv = true;
          }
          if (whiteLabelProperties.enableHu == null) {
            whiteLabelProperties.enableHu = true;
          }
          if (whiteLabelProperties.enableConfirmDrivingLicense == null) {
            whiteLabelProperties.enableConfirmDrivingLicense = true;
          }
          if (whiteLabelProperties.enableShowDrivingLicense == null) {
            whiteLabelProperties.enableShowDrivingLicense = true;
          }
          this.client$.next(client)
          return client;
        }, catchError(() => of(null))));
    }
    return this.client$;
  }

  isInTrialMonth(): Observable<boolean> {
    return this.getClient()
      .pipe(map((client: Client) => {
        if (client == null) {
          return false;
        }
        return client.sku === this.TRIAL_SKU;
      }));
  }

  /**
   * Returns an observable that emits the milliseconds left before the trial month expires
   * @return {Observable<number>}
   */
  getRemainingTimeInTrialMonth(): Observable<number> {
    return this.getClient().pipe(map((client: Client) => {
      if (client == null) {
        return 0;
      }

      return (+new Date() - (client.orderDate + this.RUN_TIME_TRIAL)) * -1;
    }));
  }

  isAllowedToAccess(): Observable<boolean> {
    return this.getClient()
      .pipe(map((client: Client) => {
        return client == null || client.isPro;
      }));
  }

  saveClient(client: Client): Observable<any> {
    return this.http.put(this.config.getEnvironment().server_authentication + '/client', client)
      .pipe(map(result => this.getClient()));
  }

  submitPaymentData(paymentData: IPaymentData) {
    return this.http
      .post(this.config.getEnvironment().server_authentication + '/client/payment-data', paymentData)
      .pipe(flatMap(() => this.getClient(true)));
  }
}
