import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';

import {User} from 'squaretrip-ts-model';
import {AuthenticatedHttpService} from './authenticated-http.service';
import {Observable} from 'rxjs/Observable';
import {ConfigService} from './config.service';
import {Subject} from 'rxjs/Subject';
import {environment} from '../../environments/environment';

export enum LoginState {
  LOGGED_IN = 'LOGGED_IN',
  NOT_LOGGED_IN = 'NOT_LOGGED_IN',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED'
}

@Injectable()
export class LoginService {

  public loginState$ = new BehaviorSubject<LoginState>(LoginState.NOT_LOGGED_IN);
  public loginStateNoPersistence$ = new Subject<LoginState>();
  /**
   * Has an attempt been made to login? If so, loginState$ is filled up-to-date. If not,
   * loginStateNoPersistence$ can be used to only get a valid login state (and not a false-negative from loginState$ = NOT_LOGGED_IN)
   * @type {boolean}
   */
  public hasAttemptedLogin = false;
  public user$ = new BehaviorSubject<User>(null);
  public token = null;

  constructor(private http: HttpClient,
              private config: ConfigService) {
    this.loginFromToken();
    this.loginStateNoPersistence$.subscribe(this.loginState$);
  }

  public loadUser(forceReload = false): Observable<User> {
    if (this.user$.getValue() != null && !forceReload) {
      return this.user$;
    }
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', this.token);
    this.http.get(this.config.getEnvironment().server_backend + '/users', {headers: headers}).subscribe((user: User) => {
      this.hasAttemptedLogin = true;
      if (
        (this.config.getProductType() === 'fleet'
          && (typeof user.clientId === 'undefined' || user.clientId === null || user.clientRole !== 'admin'))
        || (this.config.getProductType() === 'driving-log' && !user.isPro)
      ) {
        this.loginStateNoPersistence$.next(LoginState.NOT_AUTHORIZED);
        this.user$.next(null);
        localStorage.clear();
      } else {
        this.user$.next(user);
        this.loginStateNoPersistence$.next(LoginState.LOGGED_IN);
      }
    }, (err) => {
      this.loginStateNoPersistence$.next(LoginState.NOT_AUTHORIZED);
      this.user$.next(null);
      localStorage.clear();
    });
    return this.user$;
  }

  private loginFromToken() {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      this.loadUser();
    } else {
      this.hasAttemptedLogin = true;
    }
  }

  public login(username: string, password: string) {
    const loginResult$ = new Subject<LoginState>();
    this.http.post(this.config.getEnvironment().server_backend + '/users/login', {username: username, password: password})
      .subscribe((result: any) => {
        this.token = result.tokenString;
        localStorage.setItem('token', this.token);
        this.loginStateNoPersistence$.subscribe(state => {
          loginResult$.next(state);
          loginResult$.complete();
        });
        this.loadUser();

      }, error => {
        loginResult$.next(LoginState.NOT_LOGGED_IN);
        this.loginStateNoPersistence$.next(LoginState.NOT_LOGGED_IN);
        loginResult$.complete();
      });
    return loginResult$;
  }

  public logout() {
    const o = new BehaviorSubject(null);
    localStorage.removeItem('token');
    this.loginStateNoPersistence$.next(LoginState.NOT_LOGGED_IN);
    location.href = '/';
    return o;
  }

  confirmAccount(username: string, token: string): Observable<boolean> {
    const result$ = new Subject<boolean>();
    this.http
      .post(this.config.getEnvironment().server_backend + '/users/confirm-account/' + username + '/' + token, {})
      .subscribe(response => {
        result$.next(true);
      }, () => result$.next(false));
    return result$;
  }


}
