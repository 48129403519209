<mat-toolbar class="mat-elevation-z3" >
  <mat-icon *ngIf="loginState === 'LOGGED_IN'" (click)="toggleSidenav()">menu</mat-icon>
  <div [routerLink]="loginState === 'LOGGED_IN' ? (productType === 'driving-log' ? ['/driving-log'] : ['/dashboard']) : ['/']" class="product-name">
    {{'product_name' | translate}}
    <ng-container *ngIf="client?.whiteLabelProperties?.name && client?.whiteLabelProperties?.name != ''">
      {{'product_name_for' | translate}}
      {{client.whiteLabelProperties.name}}
    </ng-container>
  </div>

  <span class="toolbar-spacer"></span>

  <div class="navRight" *ngIf="loginState === 'LOGGED_IN'">
    <button mat-icon-button [matMenuTriggerFor]="supportMenu">
      <mat-icon>help</mat-icon>
    </button>
    <mat-menu #supportMenu="matMenu">
      <button mat-menu-item (click)="showOnboarding()"  *ngIf="productType === 'fleet'">
        <mat-icon>slideshow</mat-icon>
        {{'main_menu.onboarding' | translate}}
      </button>
      <a mat-menu-item href="https://squaretrip.net/support" target="_blank">
        <mat-icon>help</mat-icon>
        {{'main_menu.faq' | translate}}
      </a>

      <button mat-menu-item [routerLink]="['/handbook']"  *ngIf="productType === 'fleet'">
        <mat-icon>book</mat-icon>
        {{'main_menu.handbook' | translate}}
      </button>
      <button mat-menu-item [routerLink]="['/info']">
        <mat-icon>info</mat-icon>
        {{'main_menu.info' | translate}}
      </button>
    </mat-menu>

    <button mat-icon-button [routerLink]="['/client/properties']">
      <mat-icon>settings</mat-icon>
    </button>

    <button mat-button [matMenuTriggerFor]="settingsMenu">{{ user?.firstName }} {{ user?.lastName }}</button>
    <mat-menu #settingsMenu="matMenu">
      <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        {{'main_menu.logout' | translate}}
      </button>
    </mat-menu>


  </div>
</mat-toolbar>


<mat-sidenav-container fullscreen>
  <mat-sidenav #sidenav mode="side" class="mat-elevation-z3">
    <mat-nav-list *ngIf="loginState === 'LOGGED_IN' && productType === 'fleet'">
      <mat-list-item [routerLink]="['/dashboard']">
        <mat-icon>dashboard</mat-icon>{{'main_menu.dashboard' | translate}}
      </mat-list-item>
      <mat-list-item [routerLink]="['/drivers']">
        <mat-icon>person</mat-icon>{{'main_menu.users' | translate}}
      </mat-list-item>
      <mat-list-item [routerLink]="['/cars']">
        <mat-icon>directions_car</mat-icon>{{'main_menu.cars' | translate}}
      </mat-list-item>
      <mat-list-item [routerLink]="['/cars/map']">
        <mat-icon>map</mat-icon>{{'main_menu.map' | translate}}
      </mat-list-item>
      <mat-list-item [routerLink]="['/costs']">
        <mat-icon>account_balance</mat-icon>{{'main_menu.costs' | translate}}
      </mat-list-item>
      <mat-list-item [routerLink]="['/driving-log']">
        <mat-icon>view_list</mat-icon>{{'main_menu.logbook' | translate}}
      </mat-list-item>
      <mat-list-item [routerLink]="['/events']">
        <mat-icon>event</mat-icon>{{'main_menu.events' | translate}}
      </mat-list-item>
      <mat-list-item [routerLink]="['/reports']">
        <mat-icon>print</mat-icon>{{'main_menu.reports' | translate}}
      </mat-list-item>
      <mat-list-item [routerLink]="['/prices-packages']">
        <mat-icon>card_membership</mat-icon>{{'main_menu.prices_packages' | translate}}
      </mat-list-item>
    </mat-nav-list>
    <mat-nav-list *ngIf="loginState === 'LOGGED_IN' && productType === 'driving-log'">

      <mat-list-item [routerLink]="['/driving-log']">
        <mat-icon>view_list</mat-icon>{{'main_menu.logbook' | translate}}
      </mat-list-item>
      <mat-list-item [routerLink]="['/cars']">
        <mat-icon>directions_car</mat-icon>{{'main_menu.cars' | translate}}
      </mat-list-item>

      <mat-list-item [routerLink]="['/costs']">
        <mat-icon>account_balance</mat-icon>{{'main_menu.costs' | translate}}
      </mat-list-item>

      <mat-list-item [routerLink]="['/reports']">
        <mat-icon>print</mat-icon>{{'main_menu.reports' | translate}}
      </mat-list-item>

    </mat-nav-list>
  </mat-sidenav>

  <div class="app-content" #content>
    <div style="display: none;" id="stylediv">
      mat-toolbar {{'{'}}
        background-color: {{clientProperties?.highlightColor}} !important;
      {{'}'}}
    </div>
    <ng-container *ngIf="client?.isPro && isInTrialMonth && remainingTimeInTrialMonth > 0">
      <div class="reminder-trial-month">
        {{'trial-month.reminder.text' | translate:'{"remainingDays": "' + (remainingTimeInTrialMonth / 1000 / 60 / 60 / 24 | number:'1.0-0') + '"}'}}
        <a [routerLink]="['/prices-packages']">{{'trial-month.reminder.cta' | translate}}</a>

      </div>
    </ng-container>
    <router-outlet></router-outlet>
  </div>
</mat-sidenav-container>
<div class="spinner" *ngIf="showSpinner">
  <mat-spinner></mat-spinner>
</div>
