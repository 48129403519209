import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MapComponent} from '../../cars/map/map.component';
import {DriverService} from '../../../services/driver.service';
import {Profile, PurposeType, Trip} from 'squaretrip-ts-model';
import {TripService} from '../../../services/trip.service';
import {TripHelper} from '../../../helpers/trip/trip-helper';
import {MatDialog} from '@angular/material';
import {PickCarDialogComponent} from '../../cars/pick-car-dialog/pick-car-dialog.component';
import {DialogService} from '../../../services/dialog.service';
import {Purpose} from 'squaretrip-ts-model/dist/classes/purpose';
import {BusinessPurposePickerDialogComponent} from '../../purpose/business-purpose-picker-dialog/business-purpose-picker-dialog.component';
import {EditTripDialogComponent} from '../edit-trip-dialog/edit-trip-dialog.component';
import {SnackBarService} from '../../../services/snack-bar.service';

@Component({
  selector: 'app-driving-log-detail',
  templateUrl: './driving-log-detail.component.html',
  styleUrls: ['./driving-log-detail.component.scss']
})
export class DrivingLogDetailComponent implements OnInit {

  @ViewChild('map') map: MapComponent;
  Purposes = PurposeType;

  _trip: Trip = null;
  @Input() set trip(value) {
    this.loadTrip(value);
  }

  get trip() {
    return this._trip;
  }


  constructor(private driverService: DriverService,
              private tripService: TripService,
              private dialogService: DialogService,
              private tripHelper: TripHelper,
              private snackbarService: SnackBarService,
              private dialog: MatDialog) {
  }

  ngOnInit() {
  }

  private loadTrip(trip: Trip) {
    if (trip == null) {

      return;
    }
    this.tripService.loadTripWithLocations(trip)
      .subscribe(tripWithLocations => {
        this._trip = tripWithLocations;
        if (this.map) {
          this.map.clearMap();
          this.map.drawTrip(this._trip, true);
        }

      });

  }

  getDriverName(trip): string {
    return this.tripHelper.getDriverName(trip);
  }


  pickCar() {
    if (!this.tripHelper.canEditTrip(this.trip)) {
      return;
    }
    this.dialog.open(PickCarDialogComponent)
      .afterClosed().subscribe((result: Profile | null) => {
      if (result) {
        this.trip.profile = result;
        this.trip.tripType = result.type;
        this.tripService.saveTrip(this.trip).subscribe();
      }
    });
  }

  setBusinessPurpose() {
    if (!this.tripHelper.canEditTrip(this.trip)) {
      return;
    }
    this.dialog
      .open(BusinessPurposePickerDialogComponent, {
        data: {
          purpose: this.trip.purpose ? JSON.parse(JSON.stringify(this.trip.purpose)) : new Purpose(),
          driverName: this.getDriverName(this.trip),
          note: this.trip.note
        }
      })
      .afterClosed()
      .subscribe((result) => {
        if (result == null) {
          return;
        }
        const {purpose, note} = result;
        if (purpose != null) {
          this.trip.purpose = purpose;
          this.trip.lastManipulated = +new Date();
          this.tripService.saveTrip(this.trip).subscribe(() => {
            this.snackbarService.showMessage('driving_log.messages.categorized.business');
          });
        }
        if (note != null) {
          this.trip.note = note;
        }
      });
  }

  setPurpose(purposeCategory: PurposeType) {
    if (!this.tripHelper.canEditTrip(this.trip)) {
      return;
    }
    this.trip.purpose = new Purpose();
    this.trip.purpose.category = purposeCategory;
    this.trip.lastManipulated = +new Date();
    this.tripService.saveTrip(this.trip).subscribe(() => {
      this.snackbarService.showMessage('driving_log.messages.categorized.' + purposeCategory.toLowerCase());
    });
  }

  deleteTrip() {
    if (!this.tripHelper.canEditTrip(this.trip)) {
      return;
    }
    this.dialogService.showConfirmDialog({
      labelCancel: 'action.no',
      labelConfirm: 'action.yes',
      text: 'driving_log.delete_dialog.text',
      title: 'driving_log.delete_dialog.title'
    }).subscribe(shouldDelete => {
      if (shouldDelete) {
        this.tripService.deleteTrip(this.trip);
      }
    });
  }

  editStartAndEndLocations() {
    if (!this.tripHelper.canEditTrip(this.trip)) {
      return;
    }
    this.dialog
      .open(EditTripDialogComponent, {data: this.trip})
      .afterClosed()
      .subscribe(() => this.loadTrip(this.trip));
  }
}
