<div class="driving-log-detail-container">
  <div class="driving-log-detail-header">

    <div class="left">
      <div class="portrait">
        <div class="circle">
          <img
            alt="driver icon"
            src="/assets/icons/steering_wheel_green.svg"/>
        </div>
        <div class="title">
          {{getDriverName(trip)}}
        </div>

      </div>

      <div class="portrait" style="cursor: pointer" (click)="pickCar()">
        <div class="circle">
          <img
            alt="car icon"
            src="/assets/icons/{{ trip?.tripType }}.svg"/>
        </div>
        <div class="title">
          <ng-container *ngIf="trip?.profile?.licensePlate">{{trip?.profile?.licensePlate}}<br/></ng-container>
          {{trip?.profile?.name || ('tripTypes.' + trip?.tripType | translate)}}
        </div>
      </div>
    </div>
    <div class="right">
      <div class="entry">
        <strong>{{(trip?.endLocation?.time - trip?.startLocation?.time + 23 * 60 * 60 * 1000) | date:'H:mm'}}</strong>
        <span>{{((trip?.endLocation?.time - trip?.startLocation?.time) < 60 * 60 * 1000 ? 'trip.unit_minutes' : 'trip.unit_hours') | translate}}</span>
      </div>
      <div class="entry">
        <strong>{{trip?.distance / 1000 | number:'1.1-2'}}</strong>
        <span>{{'trip.unit_distance' | translate}}</span>
      </div>
      <div class="entry">
        <strong>{{trip?.averageSpeed * 60 * 60 / 1000 | number:'1.1-2'}}</strong>
        <span>{{'trip.unit_speed' | translate}}</span>
      </div>
    </div>
  </div>


  <div class="driving-log-detail-content">
    <app-map #map></app-map>
  </div>

  <div class="driving-log-detail-footer">
    <div class="left">
      <div class="portrait" style="cursor: pointer" (click)="setPurpose(Purposes.PURPOSE_PRIVATE)">
        <div class="circle green">
          <img
            alt="private icon activ"
            src="/assets/icons/private_selected_white.svg"
               *ngIf="trip?.purpose?.category === Purposes.PURPOSE_PRIVATE"/>
          <img
            alt="private icon inactive"
            src="/assets/icons/private_white.svg" *ngIf="trip?.purpose?.category !== Purposes.PURPOSE_PRIVATE"/>
        </div>
        <div class="title black" [ngClass]="{'highlighted': trip?.purpose?.category === Purposes.PURPOSE_PRIVATE}">
          {{'trip.purpose.private' | translate}}
        </div>
      </div>
      <div class="portrait" style="cursor: pointer" (click)="setPurpose(Purposes.PURPOSE_WAY_TO_WORK)">
        <div class="circle green">
          <img
            alt="way to work icon active"
            src="/assets/icons/way_to_work_selected_white.svg"
               *ngIf="trip?.purpose?.category === Purposes.PURPOSE_WAY_TO_WORK"/>
          <img
            alt="way to work icon inactive"
            src="/assets/icons/way_to_work_white.svg"
               *ngIf="trip?.purpose?.category !== Purposes.PURPOSE_WAY_TO_WORK"/>
        </div>
        <div class="title black" [ngClass]="{'highlighted': trip?.purpose?.category === Purposes.PURPOSE_WAY_TO_WORK}">
          {{'trip.purpose.way_to_work' | translate}}

        </div>
      </div>
      <div class="portrait" style="cursor: pointer" (click)="setBusinessPurpose()">
        <div class="circle green">
          <img
            alt="business icon active"
            src="/assets/icons/business_selected_white.svg"
               *ngIf="trip?.purpose?.category === Purposes.PURPOSE_BUSINESS"/>
          <img
            alt="business icon inactive"
            src="/assets/icons/business_white.svg" *ngIf="trip?.purpose?.category !== Purposes.PURPOSE_BUSINESS"/>
        </div>
        <div class="title black" [ngClass]="{'highlighted': trip?.purpose?.category === Purposes.PURPOSE_BUSINESS}">
          {{'trip.purpose.business' | translate}}
        </div>
      </div>
    </div>
    <div class="right">
      <button mat-icon-button (click)="editStartAndEndLocations()">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button (click)="deleteTrip()">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</div>
