import {Component, OnDestroy, OnInit} from '@angular/core';
import {ClientService} from '../../../services/client.service';
import {Subscription} from 'rxjs/Subscription';
import {Client, PurposeType, User} from 'squaretrip-ts-model';
import {LoginService} from '../../../services/login.service';
import {DriverService} from '../../../services/driver.service';
import {SnackBarService} from '../../../services/snack-bar.service';
import {WhiteLabelProperties} from '../../../classes/WhiteLabelProperties';
import {ConfigService, SquareTripProductType} from '../../../services/config.service';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-edit-client-properties',
  templateUrl: './edit-client-properties.component.html',
  styleUrls: ['./edit-client-properties.component.scss']
})
export class EditClientPropertiesComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();
  client: Client;
  whiteLabelProperties: WhiteLabelProperties;
  user: User;
  productType: SquareTripProductType;

  constructor(private clientService: ClientService,
              private configService: ConfigService,
              private userService: DriverService,
              private loginService: LoginService,
              private snackbar: SnackBarService) {
    this.productType = this.configService.getProductType();
  }

  ngOnInit() {
    this.subscriptions.add(this.clientService.getClient().subscribe(client => {
      this.client = client;
      if (client == null) {
        return;
      }
      this.whiteLabelProperties = this.client.whiteLabelProperties;
    }));
    this.subscriptions.add(this.loginService.loadUser().subscribe(user => this.user = user));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  saveClient() {
    this.clientService.saveClient(this.client).subscribe(() => this.snackbar.showMessage('messages.successfully_saved'));
  }

  saveUser() {
    this.userService.putDriver(this.user).subscribe(() => {
      this.snackbar.showMessage('messages.successfully_saved');
    }, (err: HttpErrorResponse) => {
      // reset user to server version in case of error
      this.loginService.loadUser(true);
      if (err.status === 409) {
        this.snackbar.showMessage('settings.user.errors.email_conflict');
      } else {
        this.snackbar.showMessage('settings.user.errors.other');
      }
    });
  }

  isDefaultPurposeEnabled() {
    return this.client.defaultPurpose != null;
  }

  toggleDefaultPurpose() {
    if (this.isDefaultPurposeEnabled()) {
      this.client.defaultPurpose = null;
    } else {
      this.client.defaultPurpose = {
        category: PurposeType.PURPOSE_PRIVATE
      };
    }
  }
}
