import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PurposeType, Trip} from 'squaretrip-ts-model';
import {TripHelper} from '../../../helpers/trip/trip-helper';
import {ConfigService, SquareTripProductType} from '../../../services/config.service';

@Component({
  selector: 'app-driving-log-list',
  templateUrl: './driving-log-list.component.html',
  styleUrls: ['./driving-log-list.component.scss']
})
export class DrivingLogListComponent implements OnInit {

  @Input() trips: Trip[] = [];
  @Output() tripSelected = new EventEmitter<Trip>();

  @Input() currentTrip?: Trip = null;

  Purposes = PurposeType;
  productType: SquareTripProductType;


  constructor(private configService: ConfigService) {
    this.productType = this.configService.getProductType();
  }

  ngOnInit() {
  }

  purposeTypeToString(purpose: PurposeType) {

    switch (purpose) {
      case (PurposeType.PURPOSE_BUSINESS):
        return 'Geschäftsfahrt';
      case (PurposeType.PURPOSE_PRIVATE):
        return 'Privat';
      case (PurposeType.PURPOSE_WAY_TO_WORK):
        return 'Arbeitsweg';
      case (PurposeType.UNDEFINED):
        return '';
    }
    return '';
  }

  isTripLocked(trip: Trip): boolean {
    return TripHelper.isLocked(trip);
  }

  trackByFn(index: number, item: Trip) {
    return item.id;
  }

  onTripSelected(trip: Trip) {
    this.currentTrip = trip;
    this.tripSelected.emit(trip);
  }


}
