import {Component, Input, OnInit} from '@angular/core';
import {Cost, CostType} from 'squaretrip-ts-model';
import {Observable} from 'rxjs/Observable';
import {DriverService} from '../../../services/driver.service';
import {RepairType} from 'squaretrip-ts-model/dist/classes/cost';
import {map} from 'rxjs/operators';
import {of} from 'rxjs';
import {MatDialog} from '@angular/material';
import {EditCostDialogComponent} from '../edit-cost-dialog/edit-cost-dialog.component';
import {DialogService} from '../../../services/dialog.service';
import {CostsService} from '../../../services/costs.service';
import {SnackBarService} from '../../../services/snack-bar.service';

@Component({
  selector: 'app-cost-detail',
  templateUrl: './cost-detail.component.html',
  styleUrls: ['./cost-detail.component.scss']
})
export class CostDetailComponent implements OnInit {

  @Input() cost: Cost = null;

  CostType = CostType;
  RepairType = RepairType;

  constructor(private driverService: DriverService,
              private costService: CostsService,
              private dialogService: DialogService,
              private snackbarService: SnackBarService,
              private dialog: MatDialog) { }

  ngOnInit() {
  }


  getDriverNameForCost(cost: Cost): Observable<string> {
    if (!cost) {
      return of('');
    }
    return this.driverService.getDrivers()
      .pipe(map(drivers => {
        const driver = drivers.filter(checkDriver => checkDriver != null).find(checkDriver => checkDriver.username === cost.username);
        return driver ? `${driver.firstName} ${driver.lastName}` : '';
      }));
  }

  editCost() {
    this.dialog.open(EditCostDialogComponent, {data: this.cost}).afterClosed().subscribe((cost) => {
      if (cost != null) {
        this.cost = cost;
      }
    });
  }

  deleteCost() {
    this.dialogService.showConfirmDialog({
      labelCancel: 'action.no',
      labelConfirm: 'action.yes',
      text: 'cost.delete_dialog.text',
      title: 'cost.delete_dialog.title'
    }).subscribe(shouldDelete => {
      if (shouldDelete) {
        this.costService.deleteCost(this.cost).subscribe(() => {
          this.snackbarService.showMessage('messages.successfully_deleted');
        });
      }
    });

  }
}
