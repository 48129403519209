<div class="header mat-elevation-z2">
  <app-client-logo></app-client-logo>
  <div class="header-content">

    <div class="title">
      <h1>{{'cost.title' | translate}}</h1>
      <p>{{'cost.intro' | translate}}</p>
    </div>

    <app-filter-bar [allAvailableCars]="availableCars"
                    [availableDrivers]="availableDrivers"
                    [startDate]="filterDateStart"
                    [endDate]="filterDateEnd"
                    [showDrivers]="productType === 'fleet'"
                    (carsPicked)="onCarsPickedForFilter($event)"
                    (driversPicked)="onDriversPickedForFilter($event)"
                    (startDatePicked)="onStartDatePickedForFilter($event)"
                    (endDatePicked)="onEndDatePickedForFilter($event)"
    >
      <app-filter-bubble [icon]="'add'" [text]="'cost.add.title' | translate"
                         (click)="openAddCostDialog()"></app-filter-bubble>

      <app-filter-bubble [icon]="'cloud_download'" [text]="'cost.report.cta' | translate"
      (click)="openReport()"></app-filter-bubble>
    </app-filter-bar>
  </div>
</div>

<div class="dashboard-content sq-content" style="flex-direction: row; align-items: stretch;">

  <ng-container *ngIf="costs?.length > 0">
    <mat-card style="flex: 1 1 20%">
      <mat-card-title>
        {{'cost.types' | translate}}
      </mat-card-title>
      <mat-card-content>
        <app-costs-overview-filter [costs]="costs"
                                   (costTypesSelected)="onCostsTypesSelected($event)"
                                   [initialCostTypesSelected]="costTypesSelected"></app-costs-overview-filter>
      </mat-card-content>
    </mat-card>


    <mat-card style="flex: 1 1 45%;">
      <mat-card-content
        style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; position: relative">
        <app-costs-pie-chart-by-type
          *ngIf="activeChart==='pie'"
          [costTypesSelected]="costTypesSelected"
          [costs]="costs"></app-costs-pie-chart-by-type>
        <app-costs-line-chart-by-type
          *ngIf="activeChart==='line'"
          [costTypesSelected]="costTypesSelected"
          [costs]="costs"></app-costs-line-chart-by-type>
        <div class="chart-switch">
          <mat-icon [ngClass]="{'active': activeChart == 'pie'}" (click)="switchChart('pie')">pie_chart</mat-icon>
          <mat-icon [ngClass]="{'active': activeChart == 'line'}" (click)="switchChart('line')">show_chart</mat-icon>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card style="flex: 1 1 15%">

      <mat-card-content class="sum-overview">
        <div class="sum-entry">
          <h4>{{sum | number:'1.2-2'}}</h4><br/>
          <h3>{{'cost.sum_in' | translate}}EUR</h3>
        </div>

        <div class="sum-entry">
          <h4>{{costPerLiter > -1 ? (costPerLiter | number:'1.2-2') : ('not_available' | translate)}}</h4><br/>
          <h3>{{'cost.currency_per_liter' | translate}}</h3>
        </div>

        <div class="sum-entry">
          <h4>{{costPer100Km > -1 ? (costPer100Km | number:'1.2-2') : ('not_available' | translate)}}</h4><br/>
          <h3>{{'cost.currency_per_100_km' | translate}}</h3>
        </div>


      </mat-card-content>
    </mat-card>

    <mat-card style="flex: 1 1 100%" class="no-padding">
      <mat-card-content class="cost-detail-view">
        <div class="cost-detail-list-container">
          <app-cost-list [costs]="costs"
                         [currentlySelectedCost]="selectedCostForDetailView"
                         [costTypesSelected]="costTypesSelected"
                         (costSelected)="onCostSelectedForDetailView($event)"></app-cost-list>
        </div>
        <div class="cost-detail-detail-container" *ngIf="selectedCostForDetailView != null">
          <app-cost-detail [cost]="selectedCostForDetailView"></app-cost-detail>
        </div>

      </mat-card-content>
    </mat-card>
  </ng-container>
  <div class="empty-message" *ngIf="costs?.length === 0">
    {{'cost.empty-message' | translate}}
  </div>

</div>

