import {MatDialog, MatDialogRef} from '@angular/material';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AddressPickerComponent} from '../address-picker/address-picker.component';
import {ITrip, PurposeType, Trip, User} from 'squaretrip-ts-model';
import {CarService} from '../../../services/car.service';
import {TripService} from '../../../services/trip.service';
import {Purpose, IPurpose, MinimumPurpose} from 'squaretrip-ts-model/dist/classes/purpose';
import {BusinessPurposePickerDialogComponent} from '../../purpose/business-purpose-picker-dialog/business-purpose-picker-dialog.component';
import {TripHelper} from '../../../helpers/trip/trip-helper';
import {LoginService} from '../../../services/login.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/Subject';
import {DriverService} from '../../../services/driver.service';

@Component({
  selector: 'app-manual-trip-dialog',
  templateUrl: './manual-trip-dialog.component.html',
  styleUrls: ['./manual-trip-dialog.component.scss']
})
export class ManualTripDialogComponent implements OnInit, OnDestroy {
  @ViewChild('startAddress') startAddressPicker: AddressPickerComponent;
  @ViewChild('endAddress') endAddressPicker: AddressPickerComponent;
  profiles: any[];
  trip: ITrip = new Trip();
  Purposes = PurposeType;
  startDate = new Date(+new Date() - 60 * 60 * 1000);
  startTime = ManualTripDialogComponent.prependZero(`${new Date(+new Date() - 60 * 60 * 1000).getHours()}`)
    + ':'
    + ManualTripDialogComponent.prependZero(`${new Date(+new Date() - 60 * 60 * 1000).getMinutes()}`);
  endDate = new Date();
  endTime = ManualTripDialogComponent.prependZero(`${new Date().getHours()}`)
    + ':'
    + ManualTripDialogComponent.prependZero(`${new Date().getMinutes()}`);
  distanceKm: number;
  errors = {};
  private destroyed$ = new Subject();
  drivers: User[] = [];
  currentUser: User = null;

  static prependZero(str: string): string {
    return str.length === 1 ? `0${str}` : str;
  }

  constructor(private profileService: CarService,
              private driverService: DriverService,
              private dialog: MatDialog,
              private tripService: TripService,
              private dialogRef: MatDialogRef<ManualTripDialogComponent>,
              private translate: TranslateService,
              private loginService: LoginService
              ) {}

  ngOnInit() {
    this.profileService
      .getAllProfiles()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(profiles => {
        this.profiles = profiles;
      });

    this.driverService
      .getDrivers()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(drivers => this.drivers = drivers);

    this.loginService
      .loadUser()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(user => {
        // set default user to current user
        this.currentUser = user;
        if (this.trip.username === '') {
          this.trip.username = user.username;
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  save() {
    this.errors = {};

    const rx = /([0-9]{1,2}):([0-9]{1,2})/;

    if (this.startTime) {
      const startDate = moment(this.startDate).startOf('day');
      const startTimeParts = this.startTime.match(rx) || [];
      startDate.add(startTimeParts[1], 'hours');
      startDate.add(startTimeParts[2], 'minutes');
      const startTimestamp = +startDate;
      this.trip.startLocation.time = startTimestamp;
      this.trip.startTime = startTimestamp;
    }

    if (this.endTime) {
      const endDate = moment(this.endDate).startOf('day');
      const endTimeParts = this.endTime.match(rx) || [];
      endDate.add(endTimeParts[1], 'hours');
      endDate.add(endTimeParts[2], 'minutes');
      const endTimestamp = +endDate;
      this.trip.endLocation.time = endTimestamp;
      this.trip.endTime = endTimestamp;
    }



    if (this.startAddressPicker.location) {
      this.trip.startLocation.latitude = this.startAddressPicker.location.latitude;
      this.trip.startLocation.longitude = this.startAddressPicker.location.longitude;
    }

    if (this.endAddressPicker.location) {
      this.trip.endLocation.latitude = this.endAddressPicker.location.latitude || null;
      this.trip.endLocation.longitude = this.endAddressPicker.location.longitude || null;
    }


    this.trip.locations = [this.trip.startLocation, this.trip.endLocation];

    this.trip.distance = this.distanceKm * 1000;

    this.trip.id = +new Date();

    if (!this.trip.profile) {
      this.errors['profile'] = 'driving_log.manual_trip.errors.profile_not_set';
    }

    if (!this.trip.startAddress) {
      this.errors['startAddress'] = 'driving_log.manual_trip.errors.start_address_not_set';
    }

    if (!this.trip.endAddress) {
      this.errors['endAddress'] = 'driving_log.manual_trip.errors.end_address_not_set';
    }

    if (!this.startDate) {
      this.errors['startDate'] = 'driving_log.manual_trip.errors.start_date_not_set';
    }

    if (!this.endDate) {
      this.errors['endDate'] = 'driving_log.manual_trip.errors.end_date_not_set';
    }

    if (!this.endTime) {
      this.errors['endTime'] = 'driving_log.manual_trip.errors.end_time_not_set';
    }

    if (!this.startTime) {
      this.errors['startTime'] = 'driving_log.manual_trip.errors.start_time_not_set';
    }

    if (!this.trip.distance || this.trip.distance <= 0) {
      this.errors['distance'] = 'driving_log.manual_trip.errors.distance_not_set';
    }
    if (this.startTime && this.endTime && TripHelper.isLocked(this.trip)) {
      this.errors['date'] = 'driving_log.manual_trip.errors.locked';
    }

    if (this.trip.startTime > +new Date() || this.trip.endTime > +new Date()) {
      this.errors['date'] = 'driving_log.manual_trip.errors.future';
    }

    if (this.trip.startTime >= this.trip.endTime) {
      this.errors['date'] = 'driving_log.manual_trip.errors.start_time_after_end_time';
    }

    if (!this.trip.purpose || this.trip.purpose.category === PurposeType.UNDEFINED) {
      this.errors['purpose'] = 'driving_log.manual_trip.errors.purpose_not_set';
    }

    if (Object.keys(this.errors).length > 0) {
      this.translate.get('driving_log.manual_trip.errors.error').subscribe(text => {
        alert(text);
      });
      return;
    }
    this.trip.tripType = this.trip.profile.type;
    this.trip.manualTrip = true;
    this.tripService.saveTrip(this.trip, true).subscribe();

    this.dialogRef.close();
  }

  tripPurposeChanged(purpose: IPurpose) {
    if (this.trip) {
      this.trip.purpose = purpose;
    }
  }
}
