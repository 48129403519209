import { Injectable } from '@angular/core';
import {MatDialog} from '@angular/material';
import {GenericDialogComponent, GenericDialogData} from '../components/dialog/generic-dialog/generic-dialog.component';
import {Observable} from 'rxjs/Observable';
import {ConfirmDialogComponent, ConfirmDialogData} from '../components/dialog/confirm-dialog/confirm-dialog.component';

@Injectable()
export class DialogService {

  constructor(private dialog: MatDialog) { }

  public showAlertDialog(dialogData: GenericDialogData): Observable<void> {
    return this.dialog.open(GenericDialogComponent, {data: dialogData}).afterClosed();
  }


  showConfirmDialog(dialogData: ConfirmDialogData): Observable<boolean> {
    return this.dialog.open(ConfirmDialogComponent, {data: dialogData}).afterClosed();
  }
}
