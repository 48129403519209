import {Component, OnDestroy, OnInit} from '@angular/core';
import {CarService} from '../../../services/car.service';
import {Subscription} from 'rxjs/Subscription';
import {FleetProfileStatus} from 'squaretrip-ts-model';

@Component({
  selector: 'app-number-of-cars-in-use',
  templateUrl: './number-of-cars-in-use.component.html',
  styleUrls: ['./number-of-cars-in-use.component.css']
})
export class NumberOfCarsInUseComponent implements OnInit, OnDestroy {

  carsInUse = 0;
  cars = 0;

  private subscriptions = new Subscription();

  constructor(private carService: CarService) { }

  ngOnInit() {
    this.subscriptions.add(this.carService.getCarsWithInterval(30000).subscribe(cars => {
      this.cars = cars.length;
      this.carsInUse = cars.filter(car => car.status === FleetProfileStatus.IN_USE).length;
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
