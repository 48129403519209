<div class="around-debug">
  <div class="map">
    <app-map></app-map>
  </div>
  <div class="json">

      <textarea
        #textarea
        (change)="jsonChanged($event)"
                matInput></textarea>

  </div>
</div>
