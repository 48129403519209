import {Injectable} from '@angular/core';
import {AuthenticatedHttpService} from './authenticated-http.service';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';
import {DriverService} from './driver.service';
import {CarService} from './car.service';


import {Event, EventAction, Profile, User} from 'squaretrip-ts-model';
import {ConfigService} from './config.service';
import {of, from, combineLatest} from 'rxjs';
import {
  combineAll,
  concatAll,
  delay,
  first,
  flatMap,
  map,
  mergeMap,
  reduce,
  startWith,
  switchMap,
  take,
  toArray,
  zip
} from 'rxjs/operators';
import {timer} from 'rxjs/internal/observable/timer';
import {interval} from 'rxjs/internal/observable/interval';


@Injectable()
export class EventService {

  constructor(private http: AuthenticatedHttpService,
              private driverService: DriverService,
              private carService: CarService,
              private configService: ConfigService) {
  }

  private events$: BehaviorSubject<Event[]> = new BehaviorSubject([]);
  private cachedEvents = new Map<number, Event[]>();

  /**
   * Reduces an array of events and returns an array that contains each car that is included in one of the events that is a chat message.
   * Makes sure that each car is only returned one single time
   * @param {Event[]} events
   * @return profile list
   */
  public static getCarsWithChatEventsFromEvents(events: Event[]): Profile[] {
    return events.reduce((pv: Profile[], cv) => {
      if (!cv.forProfile) {
        return pv;
      }
      if (cv.action === EventAction.None && pv.findIndex(check => check.id === cv.forProfile.id) === -1) {
        pv.push(cv.forProfile as Profile);
      }
      return pv;
    }, []);
  }

  /**
   * Fills a list of events with the full car profiles
   * @param {Event[]} events
   * @return {Observable<Event[]>}
   */
  private fillEventsWithFullCarProfiles(events: Event[]): Observable<Event[]> {
    // get cars first one to make sure they were loaded (to avoid multiple parallel requests, one per event)...

    // this.carService.getCars().flatMap(() =>
    //   // ...then map them to the events
    //   Observable.from(events)
    //     .flatMap((event: Event) => {
    //       // for events without cars, just continue
    //       if (event.forProfile == null) {
    //         return of(event);
    //       }
    //       return this.carService.getCar(event.forProfile.id).map(car => {
    //         event.forProfile = car;
    //         return event;
    //       }).first();
    //     })
    //     .toArray());

    return this.carService.getCars()
      .pipe(
        take(1),
        switchMap(() => from(events)),
        flatMap(event => {
          if (event.forProfile == null) {
            return of(event).pipe(first());
          }

          return this.carService
            .getCar(event.forProfile.id)
            .pipe(map(car => {
              event.forProfile = car;
              return event;
            }), first());
        }),
        toArray()
      );
  }

  public getEvents(forceReload = false): BehaviorSubject<Event[]> {
    if (this.events$.getValue().length === 0 || forceReload) {
      this.http.get(`${this.configService.getEnvironment().server_events}/all-for-client`)
        .pipe(
          flatMap(events => {
            return this.fillEventsWithFullCarProfiles(events);
          }),
          map(events => {
            events.sort((a, b) => a.dueTime > b.dueTime ? 1 : -1);
            return events;
          })
        )
        .subscribe(events => this.events$.next(events));
    }
    return this.events$;
  }

  public getEventsForCarWithInterval(profileId: number, interval: number = 10000): Observable<Event[]> {
    let since = 0;

    return timer(0, interval)
      .pipe(
        switchMap(() => {
          return this.http
            .get(`${this.configService.getEnvironment().server_events}/all-for-client?since=${since}&forProfileId=${profileId}`);
        }),
        flatMap(events => this.fillEventsWithFullCarProfiles(events)),
        flatMap(updatedEvents => {
          since = +new Date();
          updatedEvents.forEach((updatedEvent: Event) => {
            if (!updatedEvent.forProfile) {
              return;
            }
            if (!this.cachedEvents.has(updatedEvent.forProfile.id)) {
              this.cachedEvents.set(updatedEvent.forProfile.id, []);
            }
            const index = this.cachedEvents.get(updatedEvent.forProfile.id)
              .findIndex(checkEvent => checkEvent.id === updatedEvent.id);
            if (!updatedEvent._deleted) {
              // update
              if (index === -1) {
                this.cachedEvents.get(updatedEvent.forProfile.id).push(updatedEvent);
              } else {
                this.cachedEvents.get(updatedEvent.forProfile.id)[index] = updatedEvent;
              }
            } else {
              this.cachedEvents.get(updatedEvent.forProfile.id).splice(index, 1);
            }

            this.cachedEvents.get(updatedEvent.forProfile.id).sort((e1, e2) => e1.dueTime > e2.dueTime ? 1 : -1);
          });
          return of(this.cachedEvents.get(profileId));
        })
      );

  }

  getDriverNameForEvent(event: Event, type: 'from' | 'to' | 'owner'): Observable<string> {
    if (!event) {
      return of('');
    }
    return this.driverService.getDrivers()
      .pipe(
        map(drivers => {
          const driver = drivers.filter(checkDriver => checkDriver != null)
            .find(checkDriver => {
              if (type === 'from') {
                return checkDriver.username === event.fromUsername;
              } else if (type === 'to') {
                return checkDriver.username === event.toUsername;
              } else if (type === 'owner') {
                return checkDriver.username === event.username;
              }
            });
          return driver ? `${driver.firstName} ${driver.lastName}` : '';
        })
      );
  }

  getCarNameForEvent(event: Event): Observable<string> {
    if (!event) {
      return of('');
    }
    return this.carService.getCars()
      .pipe(
        map(cars => {
          const car = cars.filter(checkcar => checkcar != null)
            .find((checkCar: Profile) => {
              return checkCar.id === event.forProfile.id;
            });
          return car ? `${car.licensePlate}<br/>(${car.name})` : '';
        })
      );
  }

  getEventsForProfileId(profileId: number): Observable<Event[]> {
    return this.getEvents()
      .pipe(map(events => events.filter(event => event.forProfile && event.forProfile.id === profileId)));
  }

  getEventsForProfileWithAction(profile: Profile, action: EventAction): Observable<Event[]> {
    return this.getEventsForProfileId(profile.id)
      .pipe(map(events => events.filter(event => event.action === action)));
  }

  getEventsAssignedToUser(user: User): Observable<Event[]> {
    return this.getEvents()
      .pipe(map(events => events.filter(event => event.toUsername === user.username)));
  }

  getEventsAssignedToUserWithAction(user: User, action: EventAction): Observable<Event[]> {
    return this.getEventsAssignedToUser(user)
      .pipe(map(events => events.filter(event => event.action === action)));
  }

  saveEvent(event: Event): Observable<any> {
    if (!event) {
      return;
    }

    event.lastChanged = +new Date();

    let result = null;

    return this.http.put(`${this.configService.getEnvironment().server_events}`, event)
      .pipe(flatMap(res => {
          result = res;
          return this.getEvents(true);
        }),
        map(() => result));
  }

  deleteEvent(event: Event): Observable<any> {
    this.cachedEvents.get(event.forProfile.id).splice(this.cachedEvents.get(event.forProfile.id).findIndex(e => e.id === event.id), 1);
    return this.http.delete(`${this.configService.getEnvironment().server_events}/${event.id}`)
      .pipe(map(result => {
        this.getEvents(true);
        return result;
      }));
  }


}
