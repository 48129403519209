<div class="header mat-elevation-z2">
  <app-client-logo></app-client-logo>
  <div class="header-content">
    <div class="title">
      <h1>{{'cars.title' | translate}}</h1>
      <p>{{'cars.intro' | translate}}</p>
    </div>
    <div class="filters">
      <app-filter-bubble [icon]="'add'" [text]="'cars.cta_new_car' | translate"
                         (click)="doShowNewCarForm()"></app-filter-bubble>
      <app-filter-bubble [icon]="'cloud_upload'" [text]="'cars.cta_import' | translate"
                         *ngIf="productType === 'fleet'"
                         [routerLink]="['/cars/import']"></app-filter-bubble>
      <app-filter-bubble
        *ngIf="productType === 'fleet'"
        [icon]="'map'"
        [text]="'cars.cta_map' | translate" [routerLink]="['/cars/map']"></app-filter-bubble>
    </div>
  </div>
</div>
<div class="subheader mat-elevation-z1" [@showEditForm]="showNewCarForm ||showEditCarForm">
  <h2>{{(showNewCarForm ? 'cars.form.title_new' : '&nbsp;') | translate}}{{(showEditCarForm ? 'cars.form.title_edit' :
    '') | translate}}</h2>
  <form (submit)="saveCar()" #form="ngForm">
    <mat-form-field>
      <input
        #lp="ngModel"
        matInput
        placeholder="{{'car.license_plate' | translate}}"
        name="license_plate"
        [(ngModel)]="car.licensePlate"
        required
      />
      <mat-error *ngIf="lp.errors?.required">{{'cars.form.errors.license_plate' | translate}}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input
        #n="ngModel"
        matInput
        placeholder="{{'car.name' | translate}}"
        name="name"
        required
        [(ngModel)]="car.name"/>
      <mat-error *ngIf="n.errors?.required">{{'cars.form.errors.name' | translate}}</mat-error>

    </mat-form-field>

    <mat-form-field [matTooltip]="car.isLocked ? ('car.locked.explain' | translate) : ''">
      <input matInput
             placeholder="{{'car.mileage' | translate}}"
             name="mileage"
             [disabled]="car.isLocked"
             [(ngModel)]="_currentMileage"
             OnlyNumber
      />
    </mat-form-field>
    <mat-form-field *ngIf="productType === 'fleet'">
      <input matInput placeholder="{{'car.id_number' | translate}}" name="vehicleId" [(ngModel)]="car.vehicleId"/>
    </mat-form-field>
    <mat-form-field *ngIf="whiteLabelProperties?.enableHu">
      <input matInput placeholder="{{'car.next_hu' | translate}}" name="hu" [(ngModel)]="nextHu"
             [matDatepicker]="datePickerHu"/>
      <mat-datepicker-toggle matSuffix [for]="datePickerHu"></mat-datepicker-toggle>
      <mat-datepicker matSuffix #datePickerHu></mat-datepicker>
    </mat-form-field>
    <mat-form-field *ngIf="whiteLabelProperties?.enableUvv">
      <input matInput placeholder="{{'car.next_uvv' | translate}}" name="uvv" [(ngModel)]="nextUvv"
             [matDatepicker]="datePickerUvv"/>
      <mat-datepicker-toggle matSuffix [for]="datePickerUvv"></mat-datepicker-toggle>
      <mat-datepicker matSuffix #datePickerUvv></mat-datepicker>
    </mat-form-field>
    <mat-checkbox [checked]="car.standard"
                  (change)="car.standard = $event.checked"
                  *ngIf="productType === 'driving-log'">{{ 'car.default' | translate }}
    </mat-checkbox>
    <button mat-button [disabled]="form.invalid" type="submit">{{'action.save' | translate}}</button>
  </form>
</div>
<div class="dashboard-content sq-content">
  <mat-form-field class="search-box" *ngIf="cars.length > 0">
    <input type="text" matInput placeholder="{{'action.search' | translate}}" [(ngModel)]="filterSearch"/>
  </mat-form-field>
  <app-car-card *ngFor="let car of filteredCars; trackBy: trackBy"
                [car]="car"
                [@justEdited]="car?.id===carJustEdited?.id"
                (edit)="editCar(car)"
                (delete)="carDeleteClicked(car)"
  ></app-car-card>
  <div class="empty-message" *ngIf="cars.length === 0">
    {{'cars.empty-message' | translate}}
  </div>
</div>
