import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';

export interface ConfirmDialogData {
  text: string;
  title: string;
  labelConfirm: string;
  labelCancel: string;
}


@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
  dialogData: ConfirmDialogData;

  constructor(@Inject(MAT_DIALOG_DATA) dialogData: ConfirmDialogData) {
    this.dialogData = dialogData;
  }

  ngOnInit() {
  }

}
