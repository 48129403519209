import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RegistrationService} from '../../../services/registration.service';
import {SnackBarService} from '../../../services/snack-bar.service';
import {ConfigService} from '../../../services/config.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  done = false;
  private username;
  private token;
  productType = 'fleet';
  password: string;
  private forAdmin = false;
  constructor(private route: ActivatedRoute,
              private userService: RegistrationService,
              private snackbarService: SnackBarService,
              private configService: ConfigService
              ) {
    this.productType = this.configService.getProductType();
    this.route.params.subscribe(params => {
      this.username = params.email;
      this.token = params.token;
    });
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      this.forAdmin = params.get('admin') !== 'false';
    });
     }

  resetPassword() {
    this.userService.resetPassword(this.username, this.token, this.password).subscribe(() => {
      this.done = true;
    }, () => {
      this.snackbarService.showMessage('reset_password.error');
    });
  }

}
