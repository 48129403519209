<h2 mat-dialog-title>{{ 'driving_log.edit.locations.title' | translate }}</h2>
<mat-dialog-content>

  <mat-form-field>
    <mat-icon matPrefix>person</mat-icon>
    <mat-select placeholder="{{'driver.title' | translate}}" [(ngModel)]="trip.username">
      <mat-option *ngFor="let driver of drivers" [value]="driver.username">
        {{driver.firstName}} {{driver.lastName }} ({{ driver.username }})
      </mat-option>
    </mat-select>
  </mat-form-field>


  <app-trip-address-picker
    #startAddressPicker
    [placeholder]="'driving_log.edit.locations.start.placeholder' | translate"
    prefixIconSvg="assets/icons/flag_start.svg"
    [(ngModel)]="startAddress">
  </app-trip-address-picker>

  <mat-form-field>
    <mat-icon matPrefix>watch_later</mat-icon>

    <input matInput type="time" placeholder="{{'driving_log.edit.dates.start.placeholder_time' | translate}}"
           [(ngModel)]="startTime"/>
  </mat-form-field>

  <app-trip-address-picker
    #endAddressPicker
    prefixIconSvg="assets/icons/flag_end.svg"
    [placeholder]="'driving_log.edit.locations.end.placeholder' | translate"
    [(ngModel)]="endAddress">
  </app-trip-address-picker>

  <mat-form-field>
    <mat-icon matPrefix>watch_later</mat-icon>
    <input matInput type="time" placeholder="{{'driving_log.edit.dates.end.placeholder_time' | translate}}"
           [(ngModel)]="endTime"/>
  </mat-form-field>

  <mat-form-field>
    <mat-icon matPrefix>straighten</mat-icon>
    <input matInput placeholder="{{'trip.distance' | translate}}" type="number" [(ngModel)]="distance"/>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button [matDialogClose]>Abbrechen</button>
  <button mat-button (click)="save()">Speichern</button>
</mat-dialog-actions>
