<div class="sq-content small" *ngIf="!success">
  <h1>{{'register.title' | translate}}</h1>
  <span [innerHtml]="'register.intro' | translate | safeHtml"></span>

  <form (submit)="register($event)" #registrationForm="ngForm">
    <mat-vertical-stepper [linear]="true">
      <mat-step label="{{'register.login_data.label' | translate}}" [completed]="!email.errors && !password.errors">
        <div class="intro">
          {{'register.login_data.intro' | translate}}
        </div>
        <mat-form-field>
          <input type="email"
                 #email="ngModel"
                 required
                 email
                 matInput
                 placeholder="{{'register.email' | translate}}"
                 [(ngModel)]="user.email"
                 name="email"/>
          <mat-error *ngIf="email.errors?.email">{{'register.errors.email_wrong_format' | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            #password="ngModel"
            type="password"
                 required
                 minlength="6"
                 matInput
                 placeholder="{{'register.password' | translate}}"
                 [(ngModel)]="user.password"
                 name="password"/>
          <mat-error *ngIf="password.errors?.minlength ">{{'register.errors.password_required' | translate}}</mat-error>

        </mat-form-field>

        <button mat-button color="primary" type="button" matStepperNext>{{'register.next_step' | translate}}</button>
      </mat-step>
      <mat-step label="{{'register.personal_data.label' | translate}}" [completed]="!firstName.errors && !lastName.errors">
        <mat-form-field>
          <input
            #firstName="ngModel"
            type="text"
                 required
                 matInput
                 placeholder="{{'user.firstName' | translate}}"
                 [(ngModel)]="user.firstName"
                 name="first_name"/>
          <mat-error *ngIf="firstName.errors?.required">{{'register.errors.first_name_required' | translate}}</mat-error>

        </mat-form-field>
        <mat-form-field>
          <input
            #lastName="ngModel"
            type="text"
                 required
                 matInput
                 placeholder="{{'user.lastName' | translate}}"
                 [(ngModel)]="user.lastName"
                 name="last_name"/>
          <mat-error *ngIf="lastName.errors?.required">{{'register.errors.last_name_required' | translate}}</mat-error>

        </mat-form-field>
        <button mat-button color="primary" type="button" matStepperNext>{{'register.next_step' | translate}}</button>


      </mat-step>
      <mat-step label="{{'register.company_data.label' | translate}}" [completed]="!clientName.errors && !clientAddress.errors">
        <div class="intro">
          {{'register.company_data.intro' | translate}}
        </div>
        <mat-form-field>
          <input
            #clientName="ngModel"
            type="text"
                 required
                 matInput
                 placeholder="{{'client.name' | translate}}"
                 [(ngModel)]="client.name"
                 name="client_name"/>
          <mat-error *ngIf="clientName.errors?.required">{{'register.errors.client_name_required' | translate}}</mat-error>

        </mat-form-field>
        <mat-form-field>
          <textarea
            #clientAddress="ngModel"
            required
            matInput
                    placeholder="{{'client.address' | translate}}"
                    [(ngModel)]="client.address"
                    name="client_address"></textarea>
          <mat-error *ngIf="clientAddress.errors?.required">{{'register.errors.client_address_required' | translate}}</mat-error>

        </mat-form-field>

        <mat-form-field>
          <input matInput type="text"
                 placeholder="{{'client.couponCode_intro' | translate}}"
                 [(ngModel)]="couponCode"
                 name="coupon_code" />
        </mat-form-field>

        <button mat-button color="primary" type="button" matStepperNext>{{'register.next_step' | translate}}</button>

      </mat-step>

      <mat-step label="{{'settings.customization.title' | translate}}">
        <div class="intro">
          {{'settings.customization.intro' | translate}}
        </div>
        <br/><br/>
        <mat-checkbox [(ngModel)]="client.whiteLabelProperties.enableUvv" name="enable_uvv">
          {{'settings.customization.uvv' | translate}}
        </mat-checkbox>
        <mat-checkbox [(ngModel)]="client.whiteLabelProperties.enableHu" name="enable_hu">
          {{'settings.customization.hu' | translate}}
        </mat-checkbox>
        <mat-checkbox [(ngModel)]="client.whiteLabelProperties.enableConfirmDrivingLicense" name="confirm_license">
          {{'settings.customization.confirmDrivingLicense' | translate}}
        </mat-checkbox>
        <mat-checkbox [(ngModel)]="client.whiteLabelProperties.enableShowDrivingLicense" name="show_license">
          {{'settings.customization.showDrivingLicense' | translate}}
        </mat-checkbox>

        <br/>
        <br/>

        <button mat-button color="primary" type="button" matStepperNext>{{'register.next_step' | translate}}</button>

      </mat-step>

      <mat-step label="{{'register.terms_conditions.label' | translate}}">
        <mat-checkbox
          #tc="ngModel"
          required
          [(ngModel)]="acceptTerms"
          name="accept_terms">
          {{'register.terms_conditions.accept_terms' | translate}}
        </mat-checkbox>&nbsp;
        <mat-checkbox
          required
          #dp="ngModel"
          [(ngModel)]="acceptDataPrivacy"
          name="accept_data_privacy">
          {{'register.terms_conditions.accept_data_privacy' | translate}}

        </mat-checkbox><br/><br/>
        <a href="https://squaretrip.net/agb-fuhrpark/" class="small" target="_blank">{{'register.terms_conditions.terms' | translate}}</a>&nbsp;
        <a href="https://squaretrip.net/datenschutz-fuhrpark/" class="small" target="_blank">{{'register.terms_conditions.data_privacy' | translate}}</a><br/><br/>
        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="!enabled || !registrationForm?.form?.valid">{{'register.cta' | translate}}
        </button><br/><br/>
        <ng-container *ngIf="!registrationForm?.form?.valid">
          <span class="error">{{'register.has_errors' | translate}}</span>
          <span class="error" *ngIf="tc?.errors?.required">
            <ul>
              <li>{{'register.errors.accept_terms' | translate}}</li>
            </ul>
          </span>
          <span class="error" *ngIf="dp?.errors?.required">
            <ul>
              <li>{{'register.errors.accept_data_privacy' | translate}}</li>
            </ul>
          </span>
        </ng-container>
        <span class="error" *ngIf="conflictError">
          {{'register.errors.conflict' | translate}}
        </span>
      </mat-step>
    </mat-vertical-stepper>
  </form>


</div>
