import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {ClientService} from '../../services/client.service';
import {WhiteLabelProperties} from '../../classes/WhiteLabelProperties';

@Component({
  selector: 'app-client-logo',
  templateUrl: './client-logo.component.html',
  styleUrls: ['./client-logo.component.css']
})
export class ClientLogoComponent implements OnInit, OnDestroy {

  subscriptions = new Subscription();
  clientLogo = 'assets/squaretrip_logo.svg';

  constructor(private clientService: ClientService) { }

  ngOnInit() {
    this.subscriptions.add(this.clientService.getClient().subscribe(client => {
      if (client && client.whiteLabelProperties) {
        const clientLogo = (client.whiteLabelProperties as WhiteLabelProperties).logoUrl;
        if (clientLogo != null && clientLogo !== '') {
          this.clientLogo = clientLogo;
        }
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
