<div class="table">
  <div class="row50percent">
    <div class="col th"></div>
    <div class="col th">{{'car.title' | translate}}</div>
    <div class="col th">{{'driver.title' | translate}}</div>
    <div class="col th"></div>
  </div>
  <ng-container *ngFor="let car of carsToDisplay">
    <div class="row50percent">
      <div class="col"><mat-icon>directions_car</mat-icon></div>
      <div class="col">{{car.licensePlate}}</div>
      <div class="col">{{getDriverNameForProfile(car) | async}}</div>
      <div class="col">
        <app-car-status-icon [car]="car"></app-car-status-icon>
      </div>
    </div>
  </ng-container>
</div>
