import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CarService} from '../../../services/car.service';
import {Subscription} from 'rxjs/Subscription';
import {FleetProfileStatus, Profile} from 'squaretrip-ts-model';
import {Observable} from 'rxjs/Observable';
import {DriverService} from '../../../services/driver.service';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-car-list-widget',
  templateUrl: './car-list-widget.component.html',
  styleUrls: ['./car-list-widget.component.css']
})
export class CarListWidgetComponent implements OnInit, OnDestroy {

  @Input() display: 'all' | 'free' | 'in_use' = 'all';

  cars: Profile[] = [];
  carsInUse: Profile[] = [];
  carsFree: Profile[] = [];
  carsToDisplay: Profile[] = [];

  private subscriptions = new Subscription();

  constructor(private carService: CarService,
              private driverService: DriverService) { }

  ngOnInit() {
    this.subscriptions.add(this.carService.getCarsWithInterval(30000).subscribe(cars => {
      this.cars = cars;
      this.carsInUse = cars.filter(car => car.status === FleetProfileStatus.IN_USE);
      this.carsFree = cars.filter(car => car.status === FleetProfileStatus.FREE);
      switch (this.display) {
        case 'all':
          this.carsToDisplay = this.cars;
          break;
        case 'free':
          this.carsToDisplay = this.carsFree;
          break;
        case 'in_use':
          this.carsToDisplay = this.carsInUse;
          break;
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getDriverNameForProfile(car: Profile): Observable<string> {
    return this.driverService.getDrivers()
      .pipe(map(drivers => {
        const driver = drivers.find(checkDriver => checkDriver.username === car.checkedOutBy);
        if (!driver) {
          return '';
        }
        return `${driver.firstName} ${driver.lastName}`;
      }));
  }

}
