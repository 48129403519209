import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Event, Profile} from 'squaretrip-ts-model';

@Component({
  selector: 'app-car-comments-overview',
  templateUrl: './car-comments-overview.component.html',
  styleUrls: ['./car-comments-overview.component.scss']
})
export class CarCommentsOverviewComponent implements OnInit {

  /**
   * Emitted when a new event should be created based on the given event
   * @type {EventEmitter<Event>}
   */
  @Output() createEventClicked = new EventEmitter<Event>();

  selectedCar: Profile = null;

  constructor() { }

  ngOnInit() {
  }

  onCreateEventClicked(event: Event) {
    this.createEventClicked.emit(event);
  }

}
