import {Component, Input, OnInit} from '@angular/core';
import {Trip} from 'squaretrip-ts-model';

@Component({
  selector: 'app-trips-in-time-widget',
  templateUrl: './trips-in-time-widget.component.html',
  styleUrls: ['./trips-in-time-widget.component.css']
})
export class TripsInTimeWidgetComponent implements OnInit {

  tripCount = 0;

  _trips: Trip[] = [];
  @Input() set trips(value) {
    this._trips = value;
    this.tripCount = this._trips.length;
  }

  constructor() { }

  ngOnInit() {
  }

}
