<div class="chat">
  <div class="messages" #messages>
    <ng-container *ngFor="let event of events">
      <div class="message">
        <div class="top">
          <div class="sender">
            {{event.fromUsername}}:
          </div>
          <div class="tools">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="onCreateEventClicked(event)">
                <mat-icon>note_add</mat-icon>
                <span>{{'event_conversation.cta_create' | translate}}</span>
              </button>
              <button mat-menu-item (click)="onDeleteEventClicked(event)">
                <mat-icon>delete</mat-icon>
                <span>{{'event_conversation.cta_delete' | translate}}</span>
              </button>
            </mat-menu>
          </div>
        </div>
        <div class="bottom">
          <div class="text">
            {{event.title}}
          </div>
          <div class="time">
            {{event.dueTime | date:'shortDate'}},
            {{event.dueTime | date:'shortTime'}}
          </div>
        </div>
      </div>
    </ng-container>
  </div>

</div>
