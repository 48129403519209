<mat-horizontal-stepper [linear]="false" (selectionChange)="onSelectionChange($event)">
  <ng-template matStepperIcon="done">
    <mat-icon>done</mat-icon>
  </ng-template>
  <ng-template matStepperIcon="create">
    <mat-icon>done</mat-icon>
  </ng-template>
  <ng-template matStepperIcon="edit">
    <mat-icon>done</mat-icon>
  </ng-template>
  <ng-container *ngFor="let step of steps">
    <mat-step [label]="'onboarding.steps.step_' + step + '.title' | translate">
      <div class="step-wrapper">
        <div class="onboarding-content"
             [innerHTML]="'onboarding.steps.step_' + step + '.text' | translate | safeHtml"></div>
        <img
          alt="image step {{step}}"
          *ngIf="('onboarding.steps.step_' + step + '.image' | translate) != ''"
             [src]="'onboarding.steps.step_' + step + '.image' | translate"
        />
        <div class="buttons">
          <button mat-button type="button" (click)="cancel()">
            {{'onboarding.cancel' | translate}}
          </button>
          <button mat-button matStepperNext color="primary" *ngIf="currentStep < steps.length - 1">
            {{'onboarding.next_step' | translate}}
          </button>
          <button mat-button color="primary" *ngIf="currentStep >= steps.length - 1" (click)="finish()">
            {{'onboarding.finished' | translate}}
          </button>
        </div>
      </div>
    </mat-step>
  </ng-container>
</mat-horizontal-stepper>
