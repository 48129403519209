import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {User} from 'squaretrip-ts-model';
import {DriverService} from '../../../services/driver.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/Subject';

@Component({
  selector: 'app-pick-driver-dialog',
  templateUrl: './pick-driver-dialog.component.html',
  styleUrls: ['./pick-driver-dialog.component.css']
})
export class PickDriverDialogComponent implements OnInit, OnDestroy {

  private destroyed$ = new Subject();
  drivers: User[];
  selectedDriver: User | null = null;

  constructor(
    private driverService: DriverService,
    private dialogRef: MatDialogRef<PickDriverDialogComponent>) { }

  ngOnInit() {
    this.driverService.getDrivers()
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(drivers => this.drivers = drivers);
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

}
