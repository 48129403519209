import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Trip} from 'squaretrip-ts-model';


declare let google: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  @ViewChild('mapContainer') mapContainer = null;
  map = null;
  markers: any[] = [];
  paths: any[] = [];


  @Input() gestures: 'greedy' | 'cooperative' | 'none' | 'auto' = 'auto';
  @Output() ready = new EventEmitter();
  @Output() markerClicked = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    this.initMap();
  }

  initMap() {
    if (typeof google === 'undefined') {
      return;
    }
    if (this.map === null || typeof this.map === 'undefined') {
      this.map = new google.maps.Map(this.mapContainer.nativeElement, {
        center: new google.maps.LatLng(51.508742, -0.120850),
        zoom: 5,
        gestureHandling: this.gestures,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });
      this.ready.next();
    }
  }

  clearMap() {
    this.markers.forEach(marker => marker.setMap(null));
    this.paths.forEach(path => path.setMap(null));
    this.markers = [];
    this.paths = [];
  }

  drawTrips(trips: Trip[]) {
    for (const trip of trips) {
      this.drawTrip(trip);
    }
  }

  drawTrip(trip: Trip, zoomToTrip: boolean = false) {
    this.drawTripPath(trip, zoomToTrip);
    this.drawTripMarkers(trip);
  }

  drawTripPath(trip: Trip, zoomToTrip: boolean = false) {
    if ((trip.locations == null || trip.locations.length === 0) && (trip.startLocation != null && trip.endLocation != null)) {
      trip.locations = [trip.startLocation, trip.endLocation];
    }
    if (trip !== null && this.map !== null && trip.locations) {

      const flightPlanCoordinates = [];
      if (trip.correctedStartLocation) {
        flightPlanCoordinates.push({lat: +trip.correctedStartLocation.latitude, lng: +trip.correctedStartLocation.longitude});
      }
      trip.locations.forEach(location => {
        flightPlanCoordinates.push({lat: +location.latitude, lng: +location.longitude});
      });
      if (trip.correctedEndLocation) {
        flightPlanCoordinates.push({lat: +trip.correctedEndLocation.latitude, lng: +trip.correctedEndLocation.longitude});
      }
      const flightPath = new google.maps.Polyline({
        path: flightPlanCoordinates,
        geodesic: true,
        strokeColor: '#00BFA5',
        strokeOpacity: 1.0,
        strokeWeight: 5
      });
      this.paths.push(flightPath);

      flightPath.setMap(this.map);

      const bounds = new google.maps.LatLngBounds();
      for (let i = 0; i < flightPlanCoordinates.length; i++) {
        bounds.extend(flightPlanCoordinates[i]);
      }
      if (zoomToTrip) {
        this.map.fitBounds(bounds);
      }


    }
  }

  drawTripMarker(location, imageUrl, zoom = false, label = ''): any {
    if (this.map !== null && location && location.latitude && location.longitude) {
      const marker = new google.maps.Marker({
        position: {lat: +location.latitude, lng: +location.longitude},
        map: this.map,
        icon: imageUrl,
        label: label
      });
      this.markers.push(marker);
      if (zoom) {
        this.map.setZoom(15);
        this.map.setCenter({lat: +location.latitude, lng: +location.longitude});
      }

      return marker;
    }
  }

  drawComplexTripMarker(location, imageUrl, zoom = false, labelText = '', payload = null, clickHandler = null): any {
    if (this.map !== null && location && location.latitude && location.longitude) {

      if (labelText.length > 10) {
        labelText = labelText.substr(0, 10) + '…';
      }

      const image = {
        url: imageUrl,
        size: new google.maps.Size(206, 206),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(103, 103),
        labelOrigin: new google.maps.Point(68, 35)
      };

      const label = {
        color: '#fff',
        text: labelText,
        fontSize: '10px'
      };

      const marker = new google.maps.Marker({
        position: {lat: +location.latitude, lng: +location.longitude},
        map: this.map,
        icon: image,
        label: label
      });
      marker.payload = payload;
      const parent = this;
      marker.addListener('click', function() {
        parent.onMarkerClicked(this.payload);
      });
      this.markers.push(marker);
      if (zoom) {
        this.map.setZoom(15);
        this.map.setCenter({lat: +location.latitude, lng: +location.longitude});
      }

      return marker;
    }
  }

  private onMarkerClicked(payload: any) {
    this.markerClicked.emit(payload);
  }

  drawTripMarkers(trip) {
    if (trip != null) {
      const startImageUrl = 'assets/icons/flag_start.png';
      const endImageUrl = 'assets/icons/flag_end.png';

      this.drawTripMarker(trip.correctedStartLocation || trip.startLocation, startImageUrl);
      this.drawTripMarker(trip.correctedEndLocation || trip.endLocation, endImageUrl);
    }
  }

  removeTripMarker(marker) {
    marker.setMap(null);
    this.markers.splice(this.markers.indexOf(marker), 1);
  }



  zoomToMarkers() {
    const bounds = new google.maps.LatLngBounds();
    this.markers.forEach(marker => bounds.extend(marker.position));
    this.map.fitBounds(bounds);
  }

  zoomToMarkerWithPayload(payload: any) {
    const marker = this.markers.find(checkMarker => checkMarker.payload.id === payload.id);
    if (!marker) {
      return;
    }
    this.map.setZoom(16);
    this.map.panTo(marker.position);

  }
}
