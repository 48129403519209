import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {StepperSelectionEvent} from '@angular/cdk/stepper';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {

  @Output() cancelled = new EventEmitter();
  @Output() finished = new EventEmitter();

  steps = [];
  currentStep = 0;

  constructor(private translate: TranslateService) {
    this.translate.get('onboarding').subscribe(ob => {
      // create a step for every available step in translation
      const count = + ob.count;
      for (let i = 1; i <= count; i++) {
        this.steps.push(i);
      }
    });
  }

  ngOnInit() {
  }

  cancel() {
    this.cancelled.emit();
  }

  finish() {
    this.finished.emit();
  }

  onSelectionChange(event: StepperSelectionEvent) {
    this.currentStep = event.selectedIndex;
  }

}
