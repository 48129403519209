import {Component, Input, OnInit} from '@angular/core';
import {Cost, CostType} from 'squaretrip-ts-model';

@Component({
  selector: 'app-cost-price-per-liter-widget',
  templateUrl: './cost-price-per-liter-widget.component.html',
  styleUrls: ['./cost-price-per-liter-widget.component.css']
})
export class CostPricePerLiterWidgetComponent implements OnInit {

  _costs: Cost[] = [];
  sumPerLiter: number = 0;neu
  @Input() set costs(value: Cost[]) {

    this._costs = value.filter(cost => cost.type === CostType.GAS);
    const sum = this._costs.reduce((pv, cv) => pv += cv.sumGross, 0);
    const liters = this._costs.reduce((pv, cv) => pv += cv.gasLiters, 0);
    this.sumPerLiter = sum / liters;
  }

  constructor() { }

  ngOnInit() {
  }

}
