import {LoginService, LoginState} from './login.service';
import {AuthenticatedHttpService} from './authenticated-http.service';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {User} from 'squaretrip-ts-model';
import {Observable} from 'rxjs/Observable';
import {ConfigService} from './config.service';
import {of} from 'rxjs/internal/observable/of';
import {flatMap, map} from 'rxjs/operators';

@Injectable()
export class DriverService {

  constructor(private http: AuthenticatedHttpService,
              private loginService: LoginService,
              private configService: ConfigService) {
    this.getDrivers(true);
  }

  private drivers$ = new BehaviorSubject<User[]>([]);

  public getDrivers(forceReload = false): Observable<User[]> {
    if (this.configService.getProductType() === 'driving-log') {
      // for driving log, load curent user as only driver
      return this.loginService.loadUser().pipe(map(user => {
          this.drivers$.next([user]);
        }),
        flatMap(() => this.drivers$));
    }
    if (this.drivers$.getValue().length === 0 || forceReload) {

      this.http.get(`${this.configService.getEnvironment().server_backend}/users/by-client`).subscribe(d => this.drivers$.next(d));
    }
    return this.drivers$;
  }

  public addDriver(driver: User): Observable<any> {
    if (this.configService.getProductType() === 'driving-log') {
      return of(null);
    }
    driver.clientId = this.loginService.user$.getValue().clientId;
    delete driver.avatar;
    if (driver.password == null) {
      driver.password = 'temp';
    }

    return this.http.post(`${this.configService.getEnvironment().server_backend}/users`, driver);
  }

  public putDriver(driver: User): Observable<any> {
    delete driver.avatar;
    driver.lastChanged = +new Date();
    return this.http.put(`${this.configService.getEnvironment().server_backend}/users`, driver);
  }

  public deleteDriver(driver: User): Observable<any> {
    if (this.configService.getProductType() === 'driving-log') {
      return of(null);
    }
    return this.http.delete(`${this.configService.getEnvironment().server_backend}/users/${driver.username}`);
  }

  public getDriver(username: string): User {
    return this.drivers$.getValue().find(u => u.username === username);
  }

}
