import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CostsService} from '../../../services/costs.service';
import {Cost, CostType} from 'squaretrip-ts-model';
import {TranslateService} from '@ngx-translate/core';

declare const Chart: any;
declare const moment: any;

@Component({
  selector: 'app-costs-line-chart-by-type',
  templateUrl: './costs-line-chart-by-type.component.html',
  styleUrls: ['./costs-line-chart-by-type.component.css']
})
export class CostsLineChartByTypeComponent implements OnInit {

  @ViewChild('canvas') canvas;

  @Input() displayLegend = true;

  chart: any = null;

  _costs: Cost[] = [];
  @Input() set costs(value) {
    this._costs = value;
    this.renderCosts();
  }

  get costs() {
    return this._costs;
  }

  _costTypesSelected: CostType[] = [];
  @Input() set costTypesSelected(value: CostType[]) {
    this._costTypesSelected = value;
    this.renderCosts();
  }

  get costTypesSelected() {
    return this._costTypesSelected;
  }

  constructor(private costService: CostsService, private translateService: TranslateService) {
  }

  ngOnInit() {
    this.initChart();
  }

  resetChart() {
    if (this.chart != null) {
      this.chart.data.labels = [];
      this.chart.data.datasets = [];
      this.chart.update();
    }
  }

  renderCosts() {
    this.resetChart();
    const colors = [
      'rgba(66, 165, 245, 0.9)',
      'rgba(38, 198, 218, 0.9)',
      'rgba(92, 107, 192, 0.9)',
      'rgba(38, 166, 154, 0.9)',
      'rgba(126, 87, 194, 0.9)',
      'rgba(171, 70, 188, 0.9)',
      'rgba(236, 64, 122, 0.9)',
      'rgba(239, 83, 80, 0.9)',
    ];
    this.initChart();
    const days = {};
    const datasetForType = {};
    this.costs
      .filter(cost => this.costTypesSelected.indexOf(cost.type) > -1)
      .sort((a, b) => a.time > b.time ? 1 : -1)
      .forEach(cost => {
        const day = moment(cost.time).format('YYYY-MM-DD');
        if (days[day] == null) {
          days[day] = {};
        }
        if (days[day][cost.type] == null) {
          days[day][cost.type] = 0;
        }
        days[day][cost.type] += cost.sumGross;

        if (datasetForType[cost.type] == null) {
          datasetForType[cost.type] = Object.keys(datasetForType).length;
        }
      });
    this.translateService.get('cost.type').subscribe(costTypeStrings => {
      Object.keys(days).forEach(day => {
        this.chart.data.labels.push(day);
        Object.keys(datasetForType).forEach(type => {
          if (this.chart.data.datasets[datasetForType[type]] == null) {
            this.chart.data.datasets[datasetForType[type]] = {
              data: [],
              label: costTypeStrings[type],
              backgroundColor: colors[datasetForType[type]],
              borderColor: colors[datasetForType[type]]
            };
          }
          this.chart.data.datasets[datasetForType[type]].data.push(days[day][type] || 0);
        });
      });
      this.chart.update();
    });

  }

  initChart() {
    if (this.chart == null) {
      this.chart = new Chart(this.canvas.nativeElement, {
        type: 'line',
        data: {
          labels: [],
          datasets: []
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          elements: {
            line: {
              fill: false
            }
          },
          legend: {
            display: this.displayLegend
          }
        }
      });
    }

  }

}
