<div class="header mat-elevation-z2">
  <app-client-logo></app-client-logo>
  <div class="header-content">

    <div class="title">
      <h1>{{'dashboard.title' | translate}}</h1>
      <p>{{'dashboard.intro' | translate}}</p>
    </div>
    <app-filter-bar
      [startDate]="startDate"
      [endDate]="endDate"
      (startDatePicked)="onStartDatePicker($event)"
      (endDatePicked)="onEndDatePicker($event)"
      [showCars]="false"
      [showDrivers]="false"></app-filter-bar>
  </div>
</div>
<div class="dashboard-content sq-content">
  <!-- Belegte Fahrzeuge -->
  <mat-card [routerLink]="['/cars']" *ngIf="cars.length > 0">
    <mat-card-title>
      {{'dashboard.cars_checked_in' | translate}}
    </mat-card-title>
    <mat-card-content class="scrollable">
      <app-car-list-widget [display]="'in_use'"></app-car-list-widget>
    </mat-card-content>
  </mat-card>

  <!-- Standorte Fahrzeuge -->

  <mat-card class="map" [routerLink]="['/cars/map']" *ngIf="cars.length > 0 && hasLocations()">
    <mat-card-title>
      {{'dashboard.map' | translate}}
    </mat-card-title>
    <mat-card-content>
      <app-all-cars-map [gestures]="'none'"></app-all-cars-map>
    </mat-card-content>
  </mat-card>


  <div class="combination-card" *ngIf="cars.length > 0 || trips.length > 0">
    <!-- Fahrzeuge belegt (Anzahl) -->
    <mat-card [routerLink]="['/cars']" *ngIf="cars.length > 0">
      <mat-card-content>
        <app-number-of-cars-in-use></app-number-of-cars-in-use>
      </mat-card-content>
    </mat-card>
    <mat-card [routerLink]="['/driving-log']" *ngIf="trips.length > 0">
      <mat-card-content>
        <app-trips-in-time-widget [trips]="trips"></app-trips-in-time-widget>
      </mat-card-content>
    </mat-card>


  </div>

  <!-- Kostenverteilung -->
  <mat-card [routerLink]="['/costs']" class="chart" style="flex: 1 1 522px" *ngIf="costs.length > 0">
    <mat-card-title>
      {{'dashboard.cost_distribution' | translate}}
    </mat-card-title>
    <mat-card-content class="chart">
      <app-costs-pie-chart-by-type [costs]="costs" [costTypesSelected]="allCostTypes"></app-costs-pie-chart-by-type>
    </mat-card-content>
  </mat-card>

  <!-- Kostenentwicklung -->

  <mat-card [routerLink]="['/costs']" class="chart" style="flex: 1 1 522px" *ngIf="costs.length > 0">
    <mat-card-title>
      {{'dashboard.cost' | translate}}
    </mat-card-title>
    <mat-card-content class="chart">
      <app-costs-line-chart-by-type [costs]="costs" [costTypesSelected]="allCostTypes"
                                    [displayLegend]="true"></app-costs-line-chart-by-type>
    </mat-card-content>
  </mat-card>

  <div class="combination-card" *ngIf="costs.length > 0">
    <!-- Kosten Summe-->

    <mat-card [routerLink]="['/costs']">
      <mat-card-content style="position: relative; max-height: 300px; overflow-y:  scroll">
        <app-cost-sum-widget [costs]="costs"></app-cost-sum-widget>
      </mat-card-content>
    </mat-card>

    <!-- EUR pro Liter-->

    <mat-card [routerLink]="['/costs']">
      <mat-card-content style="position: relative; max-height: 300px; overflow-y:  scroll">
        <app-cost-price-per-liter-widget [costs]="costs"></app-cost-price-per-liter-widget>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="empty-message" *ngIf="costs.length === 0 && cars.length === 0 && trips.length === 0">
    {{'dashboard.empty-message' | translate}}
  </div>
</div>
