import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {justEditedAnimation, showEditFormAnimation} from '../../../animations/just-edited';
import {CarService} from '../../../services/car.service';
import {EventAction, EventCategory, EventStatus, EventType, Profile, Event} from 'squaretrip-ts-model';
import {EventService} from '../../../services/event.service';
import {WhiteLabelProperties} from '../../../classes/WhiteLabelProperties';
import {ClientService} from '../../../services/client.service';
import {SpinnerService} from '../../../services/spinner.service';
import {ScrollService} from '../../../services/scroll.service';
import {NgForm} from '@angular/forms';
import {SnackBarService} from '../../../services/snack-bar.service';
import {ActivatedRoute} from '@angular/router';
import {ConfigService, SquareTripProductType} from '../../../services/config.service';


@Component({
  selector: 'app-cars',
  templateUrl: './cars.component.html',
  styleUrls: ['./cars.component.scss'],
  animations: [
    justEditedAnimation,
    showEditFormAnimation
  ]
})
export class CarsComponent implements OnInit, OnDestroy {
  @ViewChild('form') form: NgForm;
  cars: Profile[] = [];
  filteredCars: Profile[] = [];
  car = new Profile();
  showNewCarForm = false;
  showEditCarForm = false;
  carJustEdited = null;
  subscriptions: Subscription = new Subscription();
  nextHu = new Date();
  nextUvv = new Date();
  whiteLabelProperties: WhiteLabelProperties = null;
  productType: SquareTripProductType = 'fleet';

  _filterSearch = '';
  _currentMileage: number;
  set filterSearch(value) {
    this._filterSearch = value;
    this.filterCars();
  }

  get filterSearch() {
    return this._filterSearch;
  }

  constructor(private carService: CarService,
              private eventService: EventService,
              private clientService: ClientService,
              private scrollService: ScrollService,
              private configService: ConfigService,
              private snackbarService: SnackBarService,
              private route: ActivatedRoute,
              private spinnerService: SpinnerService) {
    this.productType = this.configService.getProductType();
  }

  ngOnInit() {
    this.spinnerService.showSpinner();
    this.loadCars();
    this.subscriptions.add(this.clientService
      .getClient()
      .subscribe(client => {
        if (!client) {
          return;
        }
        this.whiteLabelProperties = client.whiteLabelProperties as WhiteLabelProperties;
      })
    );
    this.subscriptions.add(this.route.queryParamMap.subscribe(params => {
      if (params.has('filterByCar')) {
        this.filterSearch = params.get('filterByCar');
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  doShowNewCarForm() {
    if (this.showNewCarForm || this.showEditCarForm) {
      this.showNewCarForm = false;
      this.showEditCarForm = false;
      this.car = new Profile();
      this._currentMileage = 0;
    } else {
      this.showNewCarForm = true;
      // reset validation when new form is opened so error messages from previous form are not displayed
      this.form.form.markAsPristine();
      this.form.form.markAsUntouched();
      this.form.form.updateValueAndValidity();
    }
  }

  private saveNewUvvEvent(car: Profile) {
    const uvvEvent = new Event();
    uvvEvent.dueTime = +new Date(this.nextUvv);
    uvvEvent.forProfile = car;
    uvvEvent.action = EventAction.PerformUVV;
    uvvEvent.category = EventCategory.Car;
    uvvEvent.title = 'UVV';
    uvvEvent.status = EventStatus.Open;
    uvvEvent.type = EventType.Task;
    return this.eventService.saveEvent(uvvEvent);
  }

  private saveNewHuEvent(car: Profile) {
    const huEvent = new Event();
    huEvent.dueTime = +new Date(this.nextHu);
    huEvent.forProfile = car;
    huEvent.action = EventAction.PerformHauptuntersuchung;
    huEvent.category = EventCategory.Car;
    huEvent.title = 'Hauptuntersuchung';
    huEvent.status = EventStatus.Open;
    huEvent.type = EventType.Task;
    return this.eventService.saveEvent(huEvent);
  }

  saveCar() {
    const isNew = this.showNewCarForm;
    this.car.lastChanged = +new Date();
    this.car.currentMileage = this._currentMileage * 1000;
    if (isNew) {
      this.car.startMileage = this.car.currentMileage;
    }
    this.carService.postCar(this.car).subscribe((postedCar: Profile) => {
      if (isNew) {
        // we use a promise here to make sure that the saveEvent calls are sent one after the other
        new Promise((resolve, reject) => {
          if (this.whiteLabelProperties.enableHu) {
            this.saveNewHuEvent(postedCar).subscribe(() => resolve());
          } else {
            resolve();
          }
        }).then(() => {
          if (this.whiteLabelProperties.enableUvv) {
            this.saveNewUvvEvent(postedCar).subscribe();
          }
        });
      } else {
        // editing an existing car
        new Promise((resolve, reject) => {
          if (this.whiteLabelProperties.enableUvv) {

            this.eventService.getEventsForProfileWithAction(postedCar, EventAction.PerformUVV).subscribe(events => {
              if (events.length > 0) {
                // there is an existing event that we can change
                const event = events[0];
                event.dueTime = +new Date(this.nextUvv);
                event.lastChanged = +new Date();
                this.eventService.saveEvent(event).subscribe(() => resolve());
              } else {
                this.saveNewUvvEvent(postedCar).subscribe(() => resolve());
              }
            });
          } else {
            resolve();
          }
        }).then(() => {
          if (this.whiteLabelProperties.enableHu) {
            this.eventService.getEventsForProfileWithAction(postedCar, EventAction.PerformHauptuntersuchung).subscribe(events => {
              if (events.length > 0) {
                // there is an existing event that we can change
                const event = events[0];
                event.dueTime = +new Date(this.nextHu);
                event.lastChanged = +new Date();
                this.eventService.saveEvent(event).subscribe();
              } else {
                this.saveNewHuEvent(postedCar).subscribe();
              }
            });
          }
        });
      }
      this.carJustEdited = this.car;
      setTimeout(() => this.carJustEdited = null, 500);
      this.car = new Profile();
      this.showNewCarForm = this.showEditCarForm = false;
      this.loadCars();
      this.snackbarService.showMessage('cars.saved');
    });
  }

  /**
   * First, immediately loads available allCars and then starts secondly update process
   */
  loadCars() {
    this.subscriptions.add(this.carService.getCarsWithInterval(5000).subscribe(cars => {
      this.cars = cars;
      this.filterCars();
      this.spinnerService.hideSpinner();

    }));
  }

  editCar(car: Profile) {
    this.car = car;
    this.showEditCarForm = true;
    this.scrollService.scrollToTop();
    this._currentMileage = Math.round(car.currentMileage / 1000);
    if (this.whiteLabelProperties && this.whiteLabelProperties.enableUvv) {
      this.eventService.getEventsForProfileWithAction(car, EventAction.PerformUVV).subscribe(events => {
        if (events.length > 0) {
          this.nextUvv = new Date(events[0].dueTime);
        }
      });
    }
    if (this.whiteLabelProperties && this.whiteLabelProperties.enableHu) {
      this.eventService.getEventsForProfileWithAction(car, EventAction.PerformHauptuntersuchung).subscribe(events => {
        if (events.length > 0) {
          this.nextHu = new Date(events[0].dueTime);
        }
      });
    }
  }

  carDeleteClicked(car: Profile) {
    if (confirm('Möchten Sie das Fahrzeug ' + car.name + ' wirklich löschen?')) {
      car.hidden = true;
      car.lastChanged = +new Date();
      this.cars.splice(this.cars.findIndex(checkCar => checkCar.id === car.id), 1);
      this.carService.postCar(car).subscribe(() => this.loadCars());
    }
  }

  trackBy(index: number, car: Profile) {
    return car.id;
  }

  filterCars() {
    if (this.filterSearch == null || this.filterSearch === '') {
      this.filteredCars = this.cars;
      return;
    }
    this.filteredCars = this.cars.filter(car => {
      return car.name.toLowerCase().indexOf(this.filterSearch.toLowerCase()) > -1 ||
        car.licensePlate.toLowerCase().indexOf(this.filterSearch.toLowerCase()) > -1;
    });
  }

}
