import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {EventService} from '../../../services/event.service';
import {Event, EventAction, Profile} from 'squaretrip-ts-model';
import {Subscription} from 'rxjs/Subscription';
import {CarService} from '../../../services/car.service';

@Component({
  selector: 'app-cars-with-events-list',
  templateUrl: './cars-with-events-list.component.html',
  styleUrls: ['./cars-with-events-list.component.scss']
})
export class CarsWithEventsListComponent implements OnInit, OnDestroy {

  @Output() carSelected = new EventEmitter<Profile>();

  private subscriptions = new Subscription();

  currentCar: Profile = null;
  events: Event[] = [];

  /**
   * Cars for which there are events
   * @type {any[]}
   */
  cars: Profile[] = [];

  constructor(private eventService: EventService,
              private carService: CarService) { }

  ngOnInit() {
    this.subscriptions.add(this.eventService.getEvents().subscribe(events => {
      this.events = Object.assign([], events);
      this.events.sort((a, b) => a.dueTime > b.dueTime ? -1 : 1);
      this.cars = EventService.getCarsWithChatEventsFromEvents(this.events);

      // if no cars is selected, set the first car as the currently selected one
      if (this.currentCar == null && this.cars.length > 0) {
        this.onCarSelected(this.cars[0]);
      }
    }));
  }

  getLastCommentForCar(car: Profile): Event {

    return this.events.find(event => event.forProfile
      && event.forProfile.id === car.id
      && event.action === EventAction.None
    );
  }

  onCarSelected(car: Profile) {
    this.currentCar = car;
    this.carSelected.emit(car);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  trackByFn(index: number, car: Profile) {
    return car.id;
  }

}
