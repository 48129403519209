import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {CarService} from '../../../services/car.service';
import {MapComponent} from '../map/map.component';
import {FleetProfileStatus, Profile} from 'squaretrip-ts-model';
import {Subscription} from 'rxjs/Subscription';


@Component({
  selector: 'app-all-cars-map',
  templateUrl: './all-cars-map.component.html',
  styleUrls: ['./all-cars-map.component.scss']
})
export class AllCarsMapComponent implements OnInit, OnDestroy {
  allCars: Profile[] = [];
  referenceCars: Profile[] = [];
  filteredCars: Profile[] = [];
  markers: any = {};
  @Input() gestures: 'greedy' | 'cooperative' | 'none' | 'auto' = 'auto';
  @ViewChild(MapComponent) map: MapComponent;
  @Output() carClicked = new EventEmitter<Profile>();
  private filterSearch = '';
  subscriptions = new Subscription();

  constructor(private carService: CarService) {
  }

  ngOnInit() {
    let firstLoad = true;
    this.subscriptions.add(this.carService.getCarsWithInterval(5000).subscribe(cars => {
      this.referenceCars = Object.assign([], this.allCars);
      this.allCars = Object.assign([], cars);

      this.filter();
      this.drawCarMarkers();
      if (firstLoad && this.filteredCars.length > 0) {
        this.map.zoomToMarkers();
        firstLoad = false;
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private drawCarMarkers() {
    this.allCars.forEach(car => {
      // is car part of filtered cars? if not, remove it from the map
      if (this.filteredCars.findIndex(checkCar => checkCar.id === car.id) === -1) {
        if (this.markers[car.id] != null) {
          this.map.removeTripMarker(this.markers[car.id]);
          delete this.markers[car.id];
        }
        return;
      }

      const referenceCar = this.referenceCars.find(c => c.id === car.id);
      const positionChanged = !referenceCar || !referenceCar.location || (referenceCar.location.latitude !== car.location.latitude && referenceCar.location.longitude !== car.location.longitude);
      const checkinChanged = referenceCar && referenceCar.status !== car.status;
      if (this.markers[car.id] && (positionChanged || checkinChanged)) {
        this.map.removeTripMarker(this.markers[car.id]);
      }

      if (!this.markers[car.id] || positionChanged || checkinChanged) {
        let markerUrl = 'assets/truck.png';
        if (car.status === FleetProfileStatus.IN_USE) {
          markerUrl = 'assets/truck_active.png';
        }
        this.markers[car.id] = this.map.drawComplexTripMarker(car.location, markerUrl, false, `${car.licensePlate}`, car);
      }
    });
  }

  onMarkerClicked(car: Profile) {
    this.carClicked.emit(car);
  }

  zoomToCar(car: Profile) {
    this.map.zoomToMarkerWithPayload(car);
  }

  setFilterSearch(value: string) {
    this.filterSearch = value;
    this.filter();
  }

  private filter() {
    if (this.filterSearch === '') {
      this.filteredCars = Object.assign([], this.allCars);
    } else {
      this.filteredCars = this.allCars.filter(car => car.name.toLowerCase().indexOf(this.filterSearch.toLowerCase()) > -1 ||
        car.licensePlate.toLowerCase().indexOf(this.filterSearch.toLowerCase()) > -1);
    }
    this.drawCarMarkers();
  }
}
