import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {Profile, User} from 'squaretrip-ts-model';
import {DriverService} from '../../../services/driver.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/Subject';
import {CarService} from '../../../services/car.service';

@Component({
  selector: 'app-pick-driver-dialog',
  templateUrl: './pick-car-dialog.component.html',
  styleUrls: ['./pick-car-dialog.component.css']
})
export class PickCarDialogComponent implements OnInit, OnDestroy {

  private destroyed$ = new Subject();
  cars: Profile[];
  selectedCar: Profile | null = null;

  constructor(
    private carService: CarService,
    private dialogRef: MatDialogRef<PickCarDialogComponent>) { }

  ngOnInit() {
    this.carService.getAllProfiles()
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(cars => this.cars = cars);
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

}
