<div class="sq-content">
  <h1>{{'handbook.title' | translate}}</h1>
  <h2>{{'handbook.sub_title_manager' | translate}}</h2>
  <span [innerHTML]="'handbook.manager_text' | translate | safeHtml"></span>
  <br>
  <br>
  <h2>{{'handbook.sub_title_driver' | translate}}</h2>
  <span [innerHTML]="'handbook.driver_text' | translate | safeHtml"></span>
  <br/><br/>
  <h2>{{'avv.title' | translate}}</h2>
  <span [innerHTML]="'avv.text' | translate | safeHtml"></span>
</div>
