<div class="header mat-elevation-z2">
  <app-client-logo></app-client-logo>
  <div class="header-content">

    <div class="title">
      <h1>{{'report.title' | translate}}</h1>
      <p>{{'report.intro' | translate}}</p>
    </div>
    <div class="filters">
    </div>
  </div>
</div>
<div class="sq-content dashboard-content">
  <ng-container *ngIf="cars.length > 0">
    <mat-card>
      <mat-card-title>
        {{'report.profile_label' | translate}}
      </mat-card-title>
      <mat-card-content>

        <div class="table">
          <div class="row50percent">
            <div class="col">
              <mat-checkbox (click)="toggleAll()" [value]="areAllSelected()"></mat-checkbox>
            </div>
            <div class="col th">
              {{'car.license_plate' | translate}}
            </div>
            <div class="col th">
              {{'car.name' | translate}}
            </div>

          </div>
          <ng-container *ngFor="let car of cars">
            <div class="row50percent">
              <div class="col">
                <mat-checkbox (change)="onSelectedCarChanged(car, $event)" [checked]="isCarSelected(car)">
                </mat-checkbox>
              </div>
              <div class="col">
                {{car.licensePlate}}
              </div>
              <div class="col">
                {{car.name}}
              </div>

            </div>
          </ng-container>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-title>
        {{'report.summary_title' | translate}} {{selectedCars.length}} {{'car.title_plural' | translate}}
      </mat-card-title>
      <mat-card-content>
        <mat-form-field>
          <mat-select [(ngModel)]="year" placeholder="{{'report.year_label' | translate}}">
            <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-select [(ngModel)]="month" placeholder="{{'report.month_label' | translate}}">
            <mat-option [value]="'null'">{{'report.full_year' | translate}}</mat-option>
            <mat-option *ngFor="let month of availableMonths" [value]="month">{{'month.' + month | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-select [(ngModel)]="format" placeholder="{{'report.format_label' | translate}}">
            <mat-option value="pdf">{{'report.format_pdf' | translate}}</mat-option>
            <mat-option value="csv">{{'report.format_csv' | translate}}</mat-option>
            <mat-option value="xlsx">{{'report.format_xlsx' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            {{'report.further_settings' | translate}}
          </mat-expansion-panel-header>

          <mat-checkbox [(ngModel)]="excludeRevisions" [ngClass]="{'no-margin': excludeRevisions}">
            {{'report.no_revisions.title' | translate}}
          </mat-checkbox>
          <small *ngIf="excludeRevisions" class="full-width">
            <span [innerHtml]="'report.no_revisions.text' | translate | safeHtml"></span>
            <br/><br/>
          </small>

          <mat-checkbox [(ngModel)]="showPrivateAddresses" class="no-margin">
            {{'report.show_private_addresses' | translate}}
          </mat-checkbox>
          <small class="full-width">
            <span [innerHtml]="'report.show_private_addresses_explanation' | translate | safeHtml"></span>
          </small>
        </mat-expansion-panel>

        <br/>
        <br/>

        <button mat-raised-button color="primary" (click)="generateReport()">{{'report.cta' | translate}}</button>
      </mat-card-content>
    </mat-card>
  </ng-container>
  <div class="empty-message" *ngIf="cars.length === 0">
    {{'report.empty-message' | translate}}
  </div>
</div>
